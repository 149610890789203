import { createStore } from "redux";

const initialState = {
  sidebarShow: false,
};
/* eslint-disable */ // since we are using redux and it needs the state listed as the first parameter instead of the last parameter
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
/* eslint-enable */
export default store;
