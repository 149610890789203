import {
  apiURL,
  deleteConfig,
  getConfig,
  postConfig,
  putConfig,
} from "./ConfigService.js";

// the route is a get request with the paremeters
// limit=25&offset=0&searchText=&searchStatuses=&paymentFrequency=&sortColumn=createdAt&sortType=des&testing=&billable=true&businessBounced=

export function getOrganizationList(
  limit = 25,
  offset = 0,
  searchText = "",
  sortColumn = "",
  sortType = "des",
  testing = null,
  billable = null,
  toCsv = false
) {
  return fetch(
    `${apiURL}organization/get-organization-list-admin?limit=${limit}&offset=${offset}&searchText=${searchText}&sortColumn=${sortColumn}&sortType=${sortType}&testing=${testing}&billable=${billable}&toCsv=${toCsv}`,
    getConfig
  )
    .then((response) => {
      if (toCsv) {
        return response.text();
      }
      return response.json();
    })
    .then((res) => {
      if (toCsv) {
        return res;
      }
      return res.data;
    });
}

// https://api.pitch59.com/api/organization/9/changes?limit=10&offset=0&sortType=des
export function getOrganizationChanges(
  organizationId,
  limit = 1000,
  offset = 0,
  sortType = "des"
) {
  return fetch(
    `${apiURL}organization/${organizationId}/changes?limit=${limit}&offset=${offset}&sortType=${sortType}`,
    postConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function updateOrganizationTester(organizationId, value) {
  return fetch(
    `${apiURL}organization/${organizationId}/tester?value=${value}`,
    putConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function updateOrganizationBillable(organizationId, value) {
  return fetch(
    `${apiURL}organization/${organizationId}/billable?value=${value}`,
    putConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

// DELETE
// 	https://staging-api.pitch59.com/api/organization/109/delete-organization
export function deleteOrganization(organizationId) {
  return fetch(
    `${apiURL}organization/${organizationId}/delete-organization`,
    deleteConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}
