import stampToDate from "../../../../../services/TimeService.jsx";

function DeviceTable({
  data: { applicationVersion, createdAt, deviceName, platformVersion },
}) {
  return (
    <table>
      <thead>
        <tr>
          <td style={{ fontWeight: "bold" }}>{deviceName}</td>
        </tr>
      </thead>
      <tbody>
        <tr className="noHighlight">
          <td>Platform Version</td>
          <td>{platformVersion}</td>
        </tr>
        <tr className="noHighlight">
          <td>Application Version</td>
          <td>{applicationVersion}</td>
        </tr>
        <tr className="noHighlight">
          <td>Downloaded At</td>
          <td>{stampToDate(createdAt)}</td>
        </tr>
      </tbody>
    </table>
  );
}

export default DeviceTable;
