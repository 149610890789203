import { CTooltip } from "@coreui/react";

import "../../../../Style.scss";

export function LoginAsUserCell({ compactView, item, onClick }) {
  return (
    <td className={compactView ? "oneLine" : "multiLine"}>
      <CTooltip
        content="Login as User"
        placement="top"
        trigger="hover"
        offset={[0, 5]}
      >
        <button type="button" className="buttonText" onClick={onClick}>
          {item.userName}
        </button>
      </CTooltip>
    </td>
  );
}
