import { CCol, CFormLabel, CRow } from "@coreui/react";

function PaymentCyclesCount({ paymentCyclesCount, setPaymentCyclesCount }) {
  // add 8 px margin below the label
  return (
    <CRow className="discount-row">
      <CCol>
        <CFormLabel className="col-form-label col-left">
          Payment Cycles Count
        </CFormLabel>
      </CCol>
      <CCol>
        <input
          className="form-control"
          type="number"
          placeholder="Cycles"
          value={paymentCyclesCount || ""}
          onInput={(e) => {
            // only allow whole numbers and no less than 0
            if (e.target.value < 0 && e.target.value !== "") {
              e.target.value = 0;
            }

            setPaymentCyclesCount(e.target.value);
          }}
        />
      </CCol>
    </CRow>
  );
}

export default PaymentCyclesCount;
