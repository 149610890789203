import { CSmartTable } from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilCheck, cilX } from "@coreui/icons";

import "../../../Style.scss";

import SmartProductsTablePagination from "../pagination/SmartProductsTablePagination.jsx";
import businessTypeBadge from "../../pitchcardtable/smarttable/badges/BusinessTypeBadge.jsx";
import businessPaymentFrequencyBadge from "../../pitchcardtable/smarttable/badges/BusinessPaymentFrequencyBadge.jsx";

function SmartProductsTable({
  columns,
  currentPage,
  data,
  itemsPerPage,
  resultCount,
  setCurrentPage,
  setQueryLimit,
  updateQuery,
}) {
  SmartProductsTable.defaultProps = {
    columns: { Loading: {} },
    data: { Loading: {} },
    itemsPerPage: 25,
  };

  function getTypeStatus(item) {
    return (
      <td>
        <CIcon icon={item.type === 1 ? cilCheck : cilX} />
      </td>
    );
  }
  function getActions(item) {
    const handleCheckboxChange = (e) => {
      if (e.target.checked) {
        //DisableActiveProduct(item.id, item.isActive);
      } else {
        // Code to execute when the checkbox is unchecked
      }
    };

    return (
      <td>
        <input
          type="checkbox"
          checked={item.isActive}
          onChange={handleCheckboxChange}
        />
      </td>
    );
  }

  function getSmartTable() {
    return (
      <>
        <CSmartTable
          style={{
            lineHeight: "1.5",
            paddingTop: "0.25rem",
            paddingBottom: "0.25rem",
          }}
          footer
          className="sTable"
          items={data}
          columns={columns}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={(item) => {
            setQueryLimit(item);
            updateQuery(true);
          }}
          tableProps={{
            hover: true,
            responsive: true,
            small: true,
          }}
          scopedColumns={{
            businessType: (item) => businessTypeBadge(item.businessType),
            paymentFrequency: (item) => businessPaymentFrequencyBadge(item),
            isActive: (item) => getActions(item),
            type: (item) => getTypeStatus(item),
          }}
        />
        <SmartProductsTablePagination
          pageCount={Math.ceil(resultCount / itemsPerPage)}
          updateQuery={updateQuery}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
        />
      </>
    );
  }

  return getSmartTable();
}

export default SmartProductsTable;
