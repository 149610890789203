// Request URL: https://api.pitch59.com/api/transaction/getall?limit=25&offset=0&sortColumn=dueAmount&sortType=des&searchText=&businessId=&userId=&transactionStatus=0&isTestPlan=
// limit: 25
// offset: 0
// sortColumn: dueAmount
// sortType: des
// searchText:
// businessId:
// userId:
// transactionStatus: 0
// isTestPlan:

import { apiURL, getConfig } from "./ConfigService.js";

export function getAll(
  limit = 25,
  offset = 0,
  sortColumn = "createdAt",
  sortType = "des",
  searchText = "",
  businessId = "",
  userId = "",
  transactionStatus = 0,
  isTestPlan = ""
) {
  return fetch(
    `${apiURL}transaction/getall?limit=${limit}&offset=${offset}&sortColumn=${sortColumn}&sortType=${sortType}&searchText=${searchText}&businessId=${businessId}&userId=${userId}&transactionStatus=${transactionStatus}&isTestPlan=${isTestPlan}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}
