const keysUsed = [
  "userName",
  "referralEmail",
  "fullName",
  "emailId",
  "referralPhone",
  "contactNumber",
  "createdAt",
  "updatedAt",
  "invitationStatus",
  "Actions",
  "id",
  "referralId",
];
export default keysUsed;
