import keysUsed from "../exportedVariables/QueriedFilterTerms.js";

import stampToDate from "../../../services/TimeService.jsx";
import { userReferralList } from "../../../services/ReferralService.js";

export async function queriedReferralInformation(
  queryString,
  getOffset,
  setResultCount,
  setData,
  businessStartedDirection,
  queryLimit,
  queryStatus = ""
) {
  // searchText = "",
  //   searchTextReferral = "",
  //   searchTextReferrers = "",
  //   status = "",
  //   limit = 20,
  //   offset = 0,
  //   sortColumn = "",
  //   sortType = ""
  const res = await userReferralList(
    queryString,
    "",
    "",
    queryStatus,
    queryLimit,
    getOffset,
    "",
    ""
  );

  setResultCount(res.count);
  const dataSend = res.records.map((items) => {
    const neededData = {};

    keysUsed.forEach((item) => {
      switch (item) {
        //   "userName",
        //   "referralEmail",
        //   "fullName",
        //   "emailId",
        //   "referralPhone",
        //   "contactNumber",
        //   "createdAt",
        //   "updatedAt",
        //   "invitationStatus",
        //   "Actions",
        case "userName":
          try {
            if (items.userName == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items.userName;
            }
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "referralEmail":
          try {
            if (items.referralEmail == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items.referralEmail;
            }
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "fullName":
          try {
            if (items.user.fullName == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items.user.fullName;
            }
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "emailId":
          try {
            if (items.user.emailId == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items.user.emailId;
            }
          } catch (e) {
            neededData[item] = "";
          }
          break;

        case "referralPhone":
          try {
            if (items.referralPhone == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items.referralPhone;
            }
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "contactNumber":
          try {
            if (items.user.contactNumber == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items.user.contactNumber;
            }
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "createdAt":
          try {
            if (items.createdAt == null) {
              neededData[item] = "";
            } else {
              neededData[item] = stampToDate(items.createdAt);
            }
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "updatedAt":
          try {
            if (items.updatedAt == null) {
              neededData[item] = "";
            } else {
              neededData[item] = stampToDate(items.updatedAt);
            }
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "invitationStatus":
          try {
            if (items.invitationStatus == null) {
              neededData[item] = "";
            } else {
              switch (items.invitationStatus) {
                case 0:
                  neededData[item] = "Invited";
                  break;
                case 1:
                  neededData[item] = "Joined";

                  break;
                case 2:
                  neededData[item] = "Processed";
                  break;
                default:
                  neededData[item] = "Unknown";
                  break;
              }
            }
          } catch (e) {
            neededData[item] = "";
          }
          break;
        default:
          try {
            if (items[item] == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items[item];
            }
          } catch (e) {
            neededData[item] = "";
          }

          break;
      }
    });
    return neededData;
  });

  setData(dataSend);
}
