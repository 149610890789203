import React from "react";
import { CBadge } from "@coreui/react";
import "../../../../Style.scss";

const IndustryBadge = (text, compact = true) => {
  try {
    const items = text.split(":");

    return (
      <td
        className={`badge-list ${compact ? "oneLine" : "multiLine"}`}
        style={
          compact
            ? {
                maxWidth: `${250}px`,
              }
            : {
                maxWidth: `${250}px`,
              }
        }
      >
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <CBadge color="primary" shape="rounded-pill">
              {item.trim()}
            </CBadge>
            {index !== items.length - 1 && (
              <span className="visually-hidden mx-1">:</span>
            )}
          </React.Fragment>
        ))}
      </td>
    );
  } catch (error) {
    return <td>{text}</td>;
  }
};

export default IndustryBadge;
