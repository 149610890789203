export const keysUsed = [
  "businessName",
  "user", // User is an object so a switch statement is used below to get the children that are needed
  "businessStatus",
  "businessType",
  "paymentFrequency",
  "email",
  "started",
  "approved",
  "categoryNames",
  "billable",
  "tester",
  "businessId",
  "accountStatus",
  "videoFileUrl",
  "videoFileId",
  "userId",
];
export const changelogkeysUsed = [
  "field",
  "oldValue",
  "newValue",
  "createdAt",
  "userName",
];
export const paymentFrequency = ["None", "Monthly", "Annual", "Lifetime"]; // This may be moved to another file and passed in

export const businessStatusString = [
  "None",
  "Pending",
  "Active",
  "Rejected",
  "Paused",
  "None",
  "Suspended",
  "Draft",
  "Pending Video",
  "Payment Failed",
];
