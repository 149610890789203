import React from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";

function ModalForm({ modalData, setModalData }) {
  const resetModal = () => {
    setModalData({
      isVisible: false,
      type: "Position",
      paymentFrequency: "None",
      productType: "SINGLE",
      price: "",
      referralDiscount: "",
    });
  };

  const handleModalChange = (e) => {
    setModalData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    setModalData((prev) => ({ ...prev, isVisible: false }));
  };

  const getBusinessPlaceholder = (businessType) => {
    switch (businessType) {
      case 0:
        return "Service";
      case 1:
        return "Resume";
      case 2:
        return "Employee";
      case 3:
        return "Basic";
      case 4:
        return "Premium";
      case 5:
        return "Job";
      case 6:
        return "VirtualVideo";
      case 7:
        return "EmployerPortal";
      default:
        return "Select a Type"; // Default placeholder
    }
  };

  const createProduct = () => {
    // Create the Product
  };

  return (
    <Modal show={modalData.isVisible} onHide={resetModal}>
      <Modal.Body>
        <Card>
          <Card.Header>Add New Tag</Card.Header>
          <Card.Body>
            <Form onSubmit={handleModalSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  name="type"
                  value={modalData.type}
                  onChange={handleModalChange}
                >
                  <option value="0">{getBusinessPlaceholder(0)}</option>
                  <option value="1">{getBusinessPlaceholder(1)}</option>
                  <option value="2">{getBusinessPlaceholder(2)}</option>
                  <option value="3">{getBusinessPlaceholder(3)}</option>
                  <option value="4">{getBusinessPlaceholder(4)}</option>
                  <option value="5">{getBusinessPlaceholder(5)}</option>
                  <option value="6">{getBusinessPlaceholder(6)}</option>
                  <option value="7">{getBusinessPlaceholder(7)}</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Payment Frequency</Form.Label>
                <Form.Select name="secondDropdown" onChange={handleModalChange}>
                  <option value="0">None</option>
                  <option value="1">Monthly</option>
                  <option value="2">Annual</option>
                  <option value="3">Lifetime</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Product Type</Form.Label>
                <Form.Select name="ProductType" onChange={handleModalChange}>
                  <option value="SINGLE">SINGLE</option>
                  <option value="PORTAL">PORTAL</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  value={modalData.price}
                  onChange={handleModalChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Referral Discount</Form.Label>
                <Form.Control
                  type="number"
                  name="referralDiscount"
                  value={modalData.referralDiscount}
                  onChange={handleModalChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" onClick={createProduct}>
                Create Product
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}

export default ModalForm;
