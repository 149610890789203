import { apiURL, postConfig } from "./ConfigService.js";

// request url  https://staging-api.pitch59.com/api/account/login
// payload has emailId and password
// post request

export function login(emailId, password) {
  return fetch(`${apiURL}account/login`, {
    ...postConfig,
    body: JSON.stringify({
      emailId,
      password,
    }),
  })
    .then((response) => response.json())
    .then((res) => res.data);
}

// https://staging-api.pitch59.com/api/account/send-otpcode-for-anonymous post request with ContactNumber emailId and id as parameters

export function sendOtpCodeForAnonymous(ContactNumber, emailId, id) {
  return fetch(`${apiURL}account/send-otpcode-for-anonymous`, {
    ...postConfig,
    body: JSON.stringify({
      ContactNumber,
      emailId,
      id,
    }),
  })
    .then((response) => response.json())
    .then((res) => res.data);
}

// https://staging-api.pitch59.com/api/account/reset-password post request with password, Confirm Password, emailId, id, and smsOptCode as parameters

export function resetPassword(
  password,
  confirmPassword,
  emailId,
  smsOtpCode,
  id = "0"
) {
  return fetch(`${apiURL}account/reset-password`, {
    ...postConfig,
    body: JSON.stringify({
      password,
      confirmPassword,
      emailId,
      smsOtpCode,
      id,
    }),
  })
    .then((response) => response.json())
    .then((res) => res.data);
}

export function resetPasswordEmail(
  password,
  confirmPassword,
  emailId,
  smsOtpCode,
  id = "0"
) {
  return fetch(`${apiURL}account/reset-password-email`, {
    ...postConfig,
    body: JSON.stringify({
      password,
      confirmPassword,
      emailId,
      smsOtpCode,
      id,
    }),
  }).then((response) => response.json());
}
