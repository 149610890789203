import CIcon from "@coreui/icons-react";
import {
  cibFacebook,
  cibInstagram,
  cibLinkedin,
  cibPinterest,
  cibTwitter,
  cilCheck,
  cilVideo,
  cilXCircle,
} from "@coreui/icons";

export function getServicePitchcardDetails(neededData) {
  const getServicePitchcardData = () => (
    <div>
      <h5 className="font-weight-bold">
        Completion
        {neededData.progress}
      </h5>
      <h6
        style={neededData["Full Name"] === "Full Name" ? { color: "red" } : {}}
      >
        {neededData["Full Name"]}
      </h6>
      <h6 style={neededData.Email === "Email" ? { color: "red" } : {}}>
        {neededData.Email}
      </h6>
      <h6 style={neededData.Phone === "Phone" ? { color: "red" } : {}}>
        {neededData.Phone}
      </h6>
      <h6 style={neededData.Address === "Address" ? { color: "red" } : {}}>
        {neededData.Address}
      </h6>

      <h6>
        {neededData.categoryNames == null
          ? "Employment Information"
          : neededData.categoryNames}
      </h6>

      <CIcon
        style={
          neededData.videoFileUrl === ":Video"
            ? { color: "red" }
            : { color: "grey" }
        }
        size="xxl"
        icon={cilVideo}
      />
      <CIcon
        style={
          neededData.videoFileUrl === ":Video"
            ? { color: "red" }
            : { color: "grey" }
        }
        size="xxl"
        icon={neededData.videoFileUrl === ":Video" ? cilXCircle : cilCheck}
      />
      {neededData.videoCoverImageFileId !== "none" ? (
        <img
          alt="Invalid"
          src={`https://pitch59-prod.s3.amazonaws.com/${neededData.videoCoverImageFileId}`}
          style={{ width: "100%" }}
        />
      ) : (
        <h6 style={{ color: "red" }}>No Photo</h6>
      )}
      <table className="table table-borderless mx-auto">
        <tbody>
          <tr
            style={{
              // center the td items close to the center of the table
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {neededData.facebookLink === ":Facebook Link" ? (
              <td />
            ) : (
              <td>
                <CIcon
                  onClick={() => {
                    window.open(neededData.facebookLink);
                  }}
                  icon={cibFacebook}
                  style={{ color: "blue" }}
                  size="xxl"
                />
              </td>
            )}
            {neededData.instagramLink === ":Instagram Link" ? (
              <td />
            ) : (
              <td>
                <CIcon
                  onClick={() => {
                    window.open(neededData.instagramLink);
                  }}
                  icon={cibInstagram}
                  size="xxl"
                />
              </td>
            )}
            {neededData.twitterLink === ":Twitter Link" ? (
              <td />
            ) : (
              <td>
                <CIcon
                  onClick={() => {
                    window.open(neededData.twitterLink);
                  }}
                  icon={cibTwitter}
                  style={{ color: "blue" }}
                  size="xxl"
                />
              </td>
            )}
            {neededData.linkedinLink === ":Linkedin Link" ? (
              <td />
            ) : (
              <td>
                <CIcon
                  onClick={() => {
                    window.open(neededData.linkedinLink);
                  }}
                  icon={cibLinkedin}
                  size="xxl"
                />
              </td>
            )}
            {neededData.pinterestLink === ":Pinterest Link" ? (
              <td />
            ) : (
              <td>
                <CIcon
                  onClick={() => {
                    window.open(neededData.pinterestLink);
                  }}
                  icon={cibPinterest}
                  size="xxl"
                />
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
  return getServicePitchcardData;
}
