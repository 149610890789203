import { CDropdownMenu } from "@coreui/react";
import React from "react";
import ApproveBusinessItem from "./individualItems/ApproveBusinessItem.jsx";
import RejectBusinessItem from "./individualItems/RejectBusinessItem.jsx";
import ChangeToDraftItem from "./individualItems/ChangeToDraftItem.jsx";
import HideItem from "./individualItems/HideItem.jsx";
import CopyBusinessId from "./individualItems/CopyBusinessId.jsx";
import PlayVideoItem from "./individualItems/PlayVideoItem.jsx";
import DownloadVideoItem from "./individualItems/DownloadVideoItem.jsx";
import FakeChargeItem from "./individualItems/FakeChargeItem.jsx";
import DeleteBusinessItem from "./individualItems/DeleteBusinessItem.jsx";
import "../../../Style.scss";

const PitchcardsDropDownListItems = (props) => {
  function getVideo() {
    return props.data.videoFileUrl !== "" ? (
      <PlayVideoItem data={props.data} updateQuery={props.updateQuery} />
    ) : null;
  }
  function getDownloadVideo() {
    return props.data.videoFileUrl !== "" ? (
      <DownloadVideoItem data={props.data} />
    ) : null;
  }

  function AllItems() {
    return (
      <CDropdownMenu
        className="pt-0"
        modifiers={[{ name: "offset", options: { offset: [0, 5] } }]}
      >
        <ApproveBusinessItem
          data={props.data.businessId}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
        <RejectBusinessItem data={props} showToast={props.showToast} />
        <ChangeToDraftItem
          data={props.data.businessId}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
        <CopyBusinessId
          data={props.data.businessId}
          showToast={props.showToast}
        />
        {getVideo()}
        {getDownloadVideo()}
        <FakeChargeItem data={props} />
        <DeleteBusinessItem
          data={props.data.businessId}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        >
          {" "}
        </DeleteBusinessItem>
      </CDropdownMenu>
    );
  }

  function DraftResumeItems() {
    return (
      <CDropdownMenu
        className="pt-0"
        modifiers={[{ name: "offset", options: { offset: [0, 5] } }]}
      >
        <ApproveBusinessItem
          data={props.data.businessId}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
        <HideItem
          data={props.data.businessId}
          hidden={props.data.accountStatus}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
        <CopyBusinessId
          data={props.data.businessId}
          showToast={props.showToast}
        />
        <DeleteBusinessItem
          data={props.data.businessId}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
        updateQuery={props.updateQuery}
        searchString={props.searchString}
      </CDropdownMenu>
    );
  }

  function PendingItems() {
    return (
      <CDropdownMenu
        className="pt-0"
        modifiers={[{ name: "offset", options: { offset: [0, 5] } }]}
      >
        <ApproveBusinessItem
          data={props.data.businessId}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
        <RejectBusinessItem data={props} showToast={props.showToast} />
        <HideItem
          data={props.data.businessId}
          hidden={props.data.accountStatus}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
        <CopyBusinessId
          data={props.data.businessId}
          showToast={props.showToast}
        />
        <DeleteBusinessItem
          data={props.data.businessId}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
        updateQuery={props.updateQuery}
        searchString={props.searchString}
      </CDropdownMenu>
    );
  }

  function DraftBusinessItems() {
    return (
      <CDropdownMenu
        className="pt-0"
        modifiers={[{ name: "offset", options: { offset: [0, 5] } }]}
      >
        <HideItem
          data={props.data.businessId}
          hidden={props.data.accountStatus}
          searchString={props.searchString}
          updateQuery={props.updateQuery}
          showToast={props.showToast}
        />
        <CopyBusinessId
          data={props.data.businessId}
          showToast={props.showToast}
        />
        <FakeChargeItem data={props} />
        <DeleteBusinessItem
          data={props.data.businessId}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
        searchString={props.searchString}
      </CDropdownMenu>
    );
  }

  function ActiveResumeItems() {
    return (
      <CDropdownMenu
        className="pt-0"
        modifiers={[{ name: "offset", options: { offset: [0, 5] } }]}
      >
        <ChangeToDraftItem
          data={props.data.businessId}
          updateQ={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
        <HideItem
          data={props.data.businessId}
          hidden={props.data.accountStatus}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
        <CopyBusinessId
          data={props.data.businessId}
          showToast={props.showToast}
        />
        {getVideo()}
        {getDownloadVideo()}
        <DeleteBusinessItem
          data={props.data.businessId}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
      </CDropdownMenu>
    );
  }

  let businessStatusId;
  let typeId;
  switch (props.data.businessStatus) {
    case "Active":
      businessStatusId = 1;
      break;
    case "BudgetInsufficient":
      businessStatusId = 2;
      break;
    case "Draft":
      businessStatusId = 3;
      break;
    case "Inactive":
      businessStatusId = 4;
      break;
    case "Rejected":
      businessStatusId = 5;
      break;
    case "PaymentFailed":
      businessStatusId = 6;
      break;
    default:
      businessStatusId = 0;
  }
  switch (props.data.businessType) {
    case "job":
      typeId = "B";
      break;
    case "business":
      typeId = "C";
      break;
    case "pending":
      typeId = "D";
      break;
    case "service":
      typeId = "E";
      break;
    case "resume":
      typeId = "F";
      break;
    default:
      typeId = "A";
  }
  switch (businessStatusId.toString() + typeId) {
    case "1D":
    case "2D":
    case "3D":
    case "4D":
    case "5D":
    case "6D":
      return PendingItems();
    case "3A":
    case "3B":
    case "3E":
    case "3F":
      return DraftResumeItems();

    case "3C":
      return DraftBusinessItems();

    case "1F":
      return ActiveResumeItems();

    default:
      return AllItems();
  }
};

export default PitchcardsDropDownListItems;
