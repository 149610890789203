const keysUsed = [
  "businessName",
  "userName",
  "amount",
  "attempts",
  "prevPaymentDate",
  "nextPaymentDate",
  "status",
  "error",
  "transactions",
];

export default keysUsed;
