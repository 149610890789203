import CIcon from "@coreui/icons-react";
import { cilSettings } from "@coreui/icons";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormSwitch,
} from "@coreui/react";

import CopyTeamID from "./dropdownIndividual/CopyTeamID.jsx";
import DeleteOrganization from "./dropdownIndividual/DeleteOrganization.jsx";
import {
  updateOrganizationTester,
  updateOrganizationBillable,
} from "../../../../services/EmployeeportalService.js";

function SettingsDropdown(
  { id: id1, userRoles },
  id,
  updateQuery,
  searchString,
  hidden,
  displayDropdownItems,
  setDisplayDropdownItems,
  showToast
) {
  let billablePerson = userRoles.split(":")[0] === "true";
  let testerPerson = userRoles.split(":")[1] === "true";
  // let billablePerson = "false";
  // let testerPerson = "false";

  return (
    // This is the dropdown menu that appears when you click the settings icon
    <td
      className="tdOver"
      style={{
        position: "relative",
      }}
    >
      {displayDropdownItems[0] && displayDropdownItems[1] === id1 ? (
        <CDropdown
          autoClose="outside"
          onHide={() => {
            setDisplayDropdownItems([false, ""]);
          }}
          placement="auto"
          variant="nav-item"
          visible={displayDropdownItems[0] && displayDropdownItems[1] === id1}
        >
          <CDropdownToggle className="py-0 clickable" caret={false} size="sm" />
          <div style={{ position: "absolute" }}>
            <CDropdownMenu
              className="pt-0"
              // eslint-disable-next-line
              modifiers={[{ name: "offset", options: { offset: [0, 5] } }]}
            >
              <CopyTeamID
                data={id1}
                showToast={showToast}
                setDisplayDropdownItems={setDisplayDropdownItems}
              />

              <CDropdownItem>
                {" "}
                <CFormSwitch // This is the switch that toggles the billable status
                  //TODO: Notification that shows up when you toggle the switch

                  onClick={() => {
                    billablePerson = !billablePerson;
                    updateOrganizationBillable(id1, billablePerson).then(
                      // refresh the page
                      updateQuery(searchString, true)
                    );
                  }}
                  defaultChecked={billablePerson}
                  label="Billing Ability"
                />
              </CDropdownItem>
              <CDropdownItem>
                {" "}
                <CFormSwitch // This is the switch that toggles the tester status
                  //TODO: Notification that shows up when you toggle the switch

                  onClick={() => {
                    testerPerson = !testerPerson;
                    updateOrganizationTester(id1, testerPerson).then(
                      // refresh the page
                      updateQuery(searchString, true)
                    );
                  }}
                  defaultChecked={testerPerson}
                  label="Testing Ability"
                />
              </CDropdownItem>

              <DeleteOrganization
                data={id1}
                showToast={showToast}
                updateQuery={updateQuery}
                searchString={searchString}
              />
            </CDropdownMenu>
          </div>
        </CDropdown>
      ) : (
        []
      )}

      <CIcon
        role="button"
        icon={cilSettings}
        size="sm"
        onClick={() => {
          if (
            displayDropdownItems[0] === true &&
            displayDropdownItems[1] === id1
          ) {
            setDisplayDropdownItems([false, ""]);
            return;
          }
          setDisplayDropdownItems([true, id1]);
        }}
      />
    </td>
  );
}

export default SettingsDropdown;
