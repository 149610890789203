import React from "react";
import { useLocation } from "react-router-dom";

import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";
import routes from "../../Routes.js";
// import Style.scss which is in the root of the project
import "../../Style.scss";

function AppBreadcrumb() {
  const currentLocation = useLocation().pathname;

  const getRouteName = (pathname) => {
    const currentRoute = routes.find((route) => route.path === pathname);
    return currentRoute ? currentRoute.name : false;
  };

  const getBreadcrumbs = (location) => {
    const breadcrumbs = [];
    location.split("/").reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      const routeName = getRouteName(currentPathname, routes);
      // eslint-disable-next-line
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length,
        });
      return currentPathname;
    });
    return breadcrumbs;
  };

  const breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <CBreadcrumb
      className="m-0 ms-2 breadcrumb"
      style={{
        margin: "0",
      }}
    >
      <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
      {breadcrumbs.map((breadcrumb, index) => (
        <CBreadcrumbItem
          {...(breadcrumb.active
            ? { active: true }
            : { href: breadcrumb.pathname })}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          {breadcrumb.name}
        </CBreadcrumbItem>
      ))}
    </CBreadcrumb>
  );
}

export default React.memo(AppBreadcrumb);
