import { CBadge } from "@coreui/react-pro";
import React from "react";
import { businessStatusBadge } from "../services/BillableColor.jsx";

function BusinessStatusBadge({ Status }) {
  let text = Status;
  try {
    text = text.toUpperCase();
  } catch {
    text = "";
  }
  return (
    <td>
      <CBadge color={businessStatusBadge(Status)}>
        {text.substring(0, 2)}
      </CBadge>
    </td>
  );
}

export default BusinessStatusBadge;
