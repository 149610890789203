import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";
import { useDebounce } from "use-debounce";
import DiscountTable from "../../components/discount_table/DiscountTable.jsx";

import { DatabaseQueriedInformation } from "../../components/discount_table/database_DiscountQueryInformation/DatabaseQueriedInformation.js";
import { generateCode } from "../../services/DiscountService.js";

function Discounts() {
  const [filterData, setData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [resultCount, setResultCount] = useState(0);
  const [queryLimit, setQueryLimit] = useState(25);
  const [refreshStatePagination, setRefreshPagination] = useState(true);
  const [refreshState, setRefresh] = useState(true);

  const [businessStartedDirection, setBusinessStartedDirection] = useState([
    "ID",
    "des",
  ]);

  const [Search] = useDebounce(searchString, 300);
  const [getOffset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataReceived, setDataReceived] = useState(false);
  const [showDiscountEntry, setShowDiscountEntry] = useState(true);
  const [paymentCyclesCount, setPaymentCyclesCount] = useState(0);

  const [isPercent, setIsPercent] = useState(false);
  const [promotionalDiscountValue, setPromotionalDiscountValue] = useState(0);
  // set initial free trial period to the current date
  const [freeTrialPeriodValue, setFreeTrialPeriodValue] = useState();

  const [customCodeName, setCustomCodeName] = useState();
  const [initialCode, setInitialCode] = useState(false);
  const [idOfCode, setIdOfCode] = useState();

  const [startDate, setStartDate] = useState(new Date());

  const [editing, setEditing] = useState(false);
  const fixValueifInvalid = () => {
    if (promotionalDiscountValue < 0) {
      setPromotionalDiscountValue(0);
    } else if (isPercent && promotionalDiscountValue > 100) {
      setPromotionalDiscountValue(100);
    }
  };

  useEffect(() => {
    fixValueifInvalid();
  }, [isPercent]);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
      setOffset(0);
    }
  }, [businessStartedDirection, searchString, queryLimit]);

  async function getData() {
    await DatabaseQueriedInformation(
      searchString,
      getOffset,
      resultCount,
      filterData,
      businessStartedDirection,
      queryLimit,
      setData,
      setResultCount
    );
  }
  const debouncedGetData = useCallback(
    debounce(async () => {
      await getData();
    }, 300),
    [getData]
  );

  useEffect(() => {
    if (refreshStatePagination) {
      setRefreshPagination(false);
    } else {
      debouncedGetData();
    }

    setRefresh(false);
  }, [refreshState === true, Search]);

  // create useref to keep track of the value of the input field
  if (initialCode === false) {
    setInitialCode(true);
    if (customCodeName === undefined) {
      generateCode().then((res) => {
        setCustomCodeName(res); // this is rendering twice and causing the code to be generated twice and should be fixed and the code should be generated once
      });
    }
  }
  return (
    <DiscountTable
      searchString={searchString}
      setSearchString={setSearchString}
      resultCount={resultCount}
      setResultCount={setResultCount}
      queryLimit={queryLimit}
      setQueryLimit={setQueryLimit}
      refreshStatePagination={refreshStatePagination}
      setRefreshPagination={setRefreshPagination}
      refreshState={refreshState}
      setRefresh={setRefresh}
      businessStartedDirection={businessStartedDirection}
      setBusinessStartedDirection={setBusinessStartedDirection}
      getOffset={getOffset}
      setOffset={setOffset}
      Search={Search}
      setData={setData}
      filterData={filterData}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      dataReceived={dataReceived}
      setDataReceived={setDataReceived}
      showDiscountEntry={showDiscountEntry}
      setShowDiscountEntry={setShowDiscountEntry}
      editing={editing}
      setEditing={setEditing}
      paymentCyclesCount={paymentCyclesCount}
      setPaymentCyclesCount={setPaymentCyclesCount}
      isPercent={isPercent}
      setIsPercent={setIsPercent}
      promotionalDiscountValue={promotionalDiscountValue}
      setPromotionalDiscountValue={setPromotionalDiscountValue}
      freeTrialPeriodValue={freeTrialPeriodValue}
      setFreeTrialPeriodValue={setFreeTrialPeriodValue}
      customCodeName={customCodeName}
      setCustomCodeName={setCustomCodeName}
      initialCode={initialCode}
      setInitialCode={setInitialCode}
      startDate={startDate}
      setStartDate={setStartDate}
      idOfCode={idOfCode}
      setIdOfCode={setIdOfCode}
    />
  );
}

export default Discounts;
