import "./changelog.css";

function ChangelogOverflow(props) {
  return (
    <td>
      <span className="breakLine">{props}</span>
    </td>
  );
}

export default ChangelogOverflow;
