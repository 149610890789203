import { CSmartTable } from "@coreui/react-pro";
import "../../../Style.scss";

import SmartEmployeeTablePagination from "../pagination/SmartEmployeeTablePagination.jsx";
import BusinessPaymentFrequencyBadge from "../../pitchcardtable/smarttable/badges/BusinessPaymentFrequencyBadge.jsx";
import BusinessStatusBadge from "../../pitchcardtable/smarttable/badges/BusinessStatusBadge.jsx";

import SettingsDropdown from "./dropdown/SettingsDropdown.jsx";
import PitchcardBadges from "./badges/PitchcardBadges.jsx";
import industryBadge from "./badges/IndustryBadge.jsx";
import { tdOverflow } from "./services/tdEmployeeOverflow.jsx";

function SmartEmployeeTable({
  accountStatus,
  // businessStartedDirection,
  columns,
  currentPage,
  data,
  displayDropdownItems,
  id,
  itemsPerPage,
  resultCount,
  searchString,
  setBusinessStartedDirection,
  setChangeLogId,
  setCurrentPage,
  setDisplayDropdownItems,
  setQueryLimit,
  tableClassName,
  tableHover,
  updateQuery,
  showToast,
  compactView,
  setChangeLogModalShow,
}) {
  SmartEmployeeTable.defaultProps = {
    columns: { Loading: {} },
    data: { Loading: {} },
    tableClassName: "sTable",
    tableHover: true,
    itemsPerPage: 25,
  };
  const showModal = (modelID, userName) => {
    setChangeLogId([modelID, userName]);
    setChangeLogModalShow(true);
  };
  function getSmartTable() {
    return (
      <>
        <CSmartTable
          footer
          className={tableClassName}
          items={data}
          columns={columns}
          itemsPerPage={itemsPerPage}
          columnSorter={{ external: true }}
          onSorterChange={(change) => {
            const columnState = change.state === "asc" ? "asc" : "des";

            switch (change.column) {
              case "name":
                setBusinessStartedDirection(["Name", columnState]);

                break;
              case "ownerName":
                setBusinessStartedDirection(["OwnerName", columnState]);
                break;
              default:
                setBusinessStartedDirection(["Name", columnState]);
                break;
            }

            updateQuery(searchString, true);
          }}
          itemsPerPageSelect
          itemsPerPageOptions={[
            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
            32, 33, 34, 35, 36, 37, 38, 39, 40, 50, 100,
          ]}
          onItemsPerPageChange={(item) => {
            setQueryLimit(item);
            updateQuery(searchString, true);
          }}
          scopedColumns={{
            name: (item) =>
              tdOverflow(
                item.name,
                null,
                null,
                null,
                null,
                false,
                compactView,
                true
              ),
            businessCount: (item) =>
              PitchcardBadges(item.businessCount, compactView),
            paymentFrequency: (item) => BusinessPaymentFrequencyBadge(item),
            status: (item) => BusinessStatusBadge(item),
            createdAt: (item) =>
              tdOverflow(
                item.createdAt,
                showModal,
                [item.id, item.name],
                false,
                null,
                true,
                compactView
              ),
            ownerName: (item) =>
              tdOverflow(
                item.ownerName,
                null,
                null,
                false,
                showToast,
                true,
                compactView,
                true
              ),

            Email: (item) =>
              tdOverflow(
                item.Email,
                null,
                null,
                true,
                showToast,
                true,
                compactView
              ),
            // { id: id1, userRoles },
            //   id,
            //   updateQuery,
            //   searchString,
            //   hidden,
            //   displayDropdownItems,
            //   setDisplayDropdownItems,
            //   showToast
            actions: (item) =>
              SettingsDropdown(
                item,
                id,
                updateQuery,
                searchString,
                accountStatus,
                displayDropdownItems,
                setDisplayDropdownItems,
                showToast
              ),
            industries: (item) => industryBadge(item.industries, compactView),
          }}
          tableProps={{
            hover: tableHover,

            small: true,
          }}
        />

        <SmartEmployeeTablePagination
          pageCount={Math.ceil(resultCount / itemsPerPage)}
          updateQuery={updateQuery}
          searchString={searchString}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
        />
      </>
    );
  }

  return getSmartTable();
}

export default SmartEmployeeTable;
