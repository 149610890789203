import { CCard, CCardBody, CForm } from "@coreui/react";
import CodeExpirationDate from "./discount_columns/CodeExpirationDate.jsx";
import PromotionalDiscount from "./discount_columns/PromotionalDiscount.jsx";
import PaymentCyclesCount from "./discount_columns/PaymentCyclesCount.jsx";
import FreeTrialPeriod from "./discount_columns/FreeTrialPeriod.jsx";
import CustomCodeName from "./discount_columns/CustomCodeName.jsx";
import {
  createNewCode,
  updateCode,
} from "../../../services/DiscountService.js";
import DiscountCreate from "./discount_form_btns/DiscountCreate.jsx";
import DiscountUpdate from "./discount_form_btns/DiscountUpdate.jsx";
import DiscountClose from "./discount_form_btns/DiscountCloseEdit.jsx";

function DiscountEntry({
  customCodeName,
  editing,
  freeTrialPeriodValue,
  idOfCode,
  isPercent,
  paymentCyclesCount,
  promotionalDiscountValue,
  setCustomCodeName,
  setEditing,
  setFreeTrialPeriodValue,
  setInitialCode,
  setIsPercent,
  setPaymentCyclesCount,
  setPromotionalDiscountValue,
  setStartDate,
  startDate,
  updateQuery,
  showToast,
}) {
  // setPaymentCyclesCount use state for the value

  const resetForm = () => {
    setPromotionalDiscountValue(0);
    setFreeTrialPeriodValue(0);
    setInitialCode(false);
    setCustomCodeName(undefined);
    setPaymentCyclesCount(0);
    setIsPercent(false);
    setStartDate(new Date());
    setEditing(false);
    updateQuery("", true);
  };
  const checkandSubmit = () => {
    if (promotionalDiscountValue < 0 || promotionalDiscountValue > 100) {
      // show error message and do not submit
      // alert("Promotional Discount must be between 0 and 100");
    } else {
      createNewCode(
        isPercent ? Number(promotionalDiscountValue) : null,
        !isPercent ? Number(promotionalDiscountValue) : null,
        customCodeName,
        startDate,
        Number(freeTrialPeriodValue), // freetrial period as number
        Number(paymentCyclesCount),
        isPercent ? 1 : 2
      )
        .then(() => {
          showToast("success", "Discount Code Created", 1000, true);
        })
        .finally(() => {
          resetForm();
        });
    }
  };

  const updateSubmittedCode = () => {
    updateCode(
      idOfCode,
      isPercent ? Number(promotionalDiscountValue) : null,
      !isPercent ? Number(promotionalDiscountValue) : null,
      customCodeName,
      startDate,
      Number(freeTrialPeriodValue), // freetrial period as number
      Number(paymentCyclesCount),
      isPercent ? 1 : 2
    ).then(() => {
      resetForm();
    });
  };

  return (
    <CCard className="discount-entry-card">
      <CCardBody>
        <CForm>
          <PromotionalDiscount
            isEditing={editing}
            isPercent={isPercent}
            setIsPercent={setIsPercent}
            inputValue={promotionalDiscountValue}
            setInputValue={setPromotionalDiscountValue}
          />
          <PaymentCyclesCount
            paymentCyclesCount={paymentCyclesCount}
            setPaymentCyclesCount={setPaymentCyclesCount}
          />
          <FreeTrialPeriod
            freeTrialPeriodValue={freeTrialPeriodValue}
            setFreeTrialPeriodValue={setFreeTrialPeriodValue}
          />
          <CustomCodeName
            isEditing={editing}
            customCodeName={customCodeName}
            setCustomCodeName={setCustomCodeName}
          />
          <CodeExpirationDate
            startDate={startDate}
            setStartDate={setStartDate}
          />

          {editing ? (
            <>
              <DiscountClose resetForm={resetForm} />
              <DiscountUpdate updateSubmittedCode={updateSubmittedCode} />
            </>
          ) : (
            <DiscountCreate submit={checkandSubmit} />
          )}
        </CForm>
      </CCardBody>
    </CCard>
  );
}

export default DiscountEntry;
