import { CPopover } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cibAndroid, cibAppStoreIos } from "@coreui/icons";
import uuid from "react-uuid";
import DeviceTable from "./tables/DeviceTable.jsx";

function PlatformFilter(props) {
  let AndroidFound = false;
  let IOSFound = false;

  const getDeviceInfo = (platform) =>
    // eslint-disable-next-line
    props.map((item) =>
      platform === item.platform ? (
        <div key={uuid()}>
          <DeviceTable data={item} />
        </div>
      ) : (
        []
      )
    );
  return (
    <td>
      {
        // eslint-disable-next-line
        props.forEach((item) => {
          Object.keys(item).some((key) => {
            // refactor this later for better performance when scaling application
            if (key === "platform") {
              if (item[key] === "Android") {
                AndroidFound = true;
              }
              if (item[key] === "IOS") {
                IOSFound = true;
              }
              if (AndroidFound && IOSFound) {
                // Exit early once both platforms are found
                return true;
              }
            }
            return false;
          });
        })
      }

      <CPopover
        title="Device(s) Info"
        content={getDeviceInfo("Android")}
        placement="auto"
        trigger={AndroidFound ? "hover" : "focus"}
        offset={[0, 10]}
      >
        <CIcon
          className="extra-margin"
          icon={cibAndroid}
          size="sm"
          style={AndroidFound ? { color: "green" } : { color: "grey" }}
        />
      </CPopover>
      <CPopover
        trigger={IOSFound ? "hover" : "focus"}
        title="Device(s) Info"
        content={getDeviceInfo("IOS")}
        placement="auto"
        offset={[0, 10]}
      >
        <CIcon
          className="extra-margin"
          icon={cibAppStoreIos}
          size="sm"
          style={IOSFound ? { color: "blue" } : { color: "grey" }}
        />
      </CPopover>
    </td>
  );
}

export default PlatformFilter;
