import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";

import ProductsTable from "../../components/products/ProductsTable.jsx";
import { queriedProductsInformation } from "../../components/products/database_user_query_information/QueriedProductsInformation.jsx";

function products() {
  const [compactView, setCompactView] = React.useState(true);
  const [filterData, setData] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [queryLimit, setQueryLimit] = useState(50);
  const [refreshStatePagination, setRefreshPagination] = useState(true);
  const [refreshState, setRefresh] = useState(true);
  const [businessType, setBusinessType] = useState("");
  const [businessStartedDirection, setBusinessStartedDirection] = useState([
    "createdAt",
    "des",
  ]);
  const [paymentFrequency, setPaymentFrequency] = useState(4); // You can set the default value as needed

  const [getOffset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayDropdownItems, setDisplayDropdownItems] = useState([
    false,
    "userId",
  ]);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
      setOffset(0);
    }
  }, [businessStartedDirection, queryLimit]);
  const [dataReceived, setDataReceived] = useState(false);
  const [modalData, setModalData] = useState({
    isVisible: false,
    type: "Position",
    paymentFrequency: "None",
    productType: "SINGLE",
    price: "",
    referralDiscount: "",
  });

  async function getData() {
    await queriedProductsInformation(
      queryLimit,
      getOffset,
      "",
      businessType,
      paymentFrequency,
      setResultCount,
      setData
    );
  }
  const debouncedGetData = useCallback(
    debounce(async () => {
      await getData();
    }, 300),
    [getData]
  );

  useEffect(() => {
    if (refreshStatePagination) {
      setRefreshPagination(false);
    } else {
      debouncedGetData();
    }
    setRefresh(false);
  }, [refreshState === true]);
  return (
    <ProductsTable
      resultCount={resultCount}
      setResultCount={setResultCount}
      queryLimit={queryLimit}
      setQueryLimit={setQueryLimit}
      refreshStatePagination={refreshStatePagination}
      setRefreshPagination={setRefreshPagination}
      refreshState={refreshState}
      setRefresh={setRefresh}
      businessStartedDirection={businessStartedDirection}
      setBusinessStartedDirection={setBusinessStartedDirection}
      getOffset={getOffset}
      setOffset={setOffset}
      setData={setData}
      filterData={filterData}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      displayDropdownItems={displayDropdownItems}
      setDisplayDropdownItems={setDisplayDropdownItems}
      dataRecieved={dataReceived}
      setDataRecieved={setDataReceived}
      compactView={compactView}
      setCompactView={setCompactView}
      businessType={businessType}
      setBusinessType={setBusinessType}
      paymentFrequency={paymentFrequency}
      setPaymentFrequency={setPaymentFrequency}
      modalData={modalData}
      setModalData={setModalData}
    />
  );
}

export default products;
