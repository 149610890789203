import { apiURL, getConfig, putConfig } from "./ConfigService.js";

export function getProducts(
  limit = 50,
  offset = 0,
  isActive = true,
  businessType = "",
  paymentFrequency = ""
) {
  return fetch(
    `${apiURL}products/pricing?limit=${limit}&offset=${offset}&isActive=${isActive}&businessType=${businessType}&paymentFrequency=${paymentFrequency}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function DisableActiveProduct(id, isActive) {
  return fetch(`${apiURL}products/${id}/${isActive}`, putConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}
export function addPrice(
  businessType,
  isDiscountAvailable,
  paymentFrequency,
  price,
  referralDiscount,
  type
) {
  return fetch(`${apiURL}products/add-price`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((res) => res.data);
}
