import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { CSpinner } from "@coreui/react";
import "./Style.scss";
import routes from "./Routes.js";

function AppContent({ setTitle }) {
  return (
    <div className="RouterBorder">
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {routes.map(
            (route, idx) =>
              route.element && (
                <Route
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element setTitle={setTitle} />}
                />
              )
          )}
        </Routes>
      </Suspense>
    </div>
  );
}

export default React.memo(AppContent);
