import {
  CSmartTable,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
} from "@coreui/react-pro";
import { cilMap, cilTrash, cilVideo } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useState } from "react";

import SmartReportedReviewsPagination from "../pagination/SmartReportedReviewsPagination.jsx";
import {
  deleteReportedReview,
  reportedReviewNotSpam,
} from "../../../services/ReportedReviewsService.js";

function SmartReportedReviewsTable({
  data,
  updateQuery,
  id,
  resultCount,
  itemsPerPage,
  searchString,
  currentPage,
  setCurrentPage,
  setQueryLimit,
  columns,
  setBusinessStartedDirection,
  columnInfo,
  setColumnInfo,
  showToast,
}) {
  SmartReportedReviewsTable.defaultProps = {
    columns: { Loading: {} },
    data: { Loading: {} },
    tableClassName: "sTable",
    itemsPerPage: 25,
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  function renderLogo(item) {
    return (
      <td>
        <img
          src={item.logo}
          alt="Logo"
          style={{
            width: "75px",
            height: "50px",
          }}
        />
      </td>
    );
  }

  function renderActions(item) {
    return (
      <td>
        <CIcon
          size="sm"
          icon={cilVideo}
          onClick={() => {
            updateQuery(searchString, false, null, true, item.videoUrl);
          }}
          className="icon-color-pencil extra-margin"
        />
        <CIcon
          size="sm"
          icon={cilMap}
          onClick={() => {
            setColumnInfo([item.id, item.businessCustomerReviewId]);

            setModalVisible(true);
          }}
          className="icon-color-map extra-margin"
        />
        <CIcon
          size="sm"
          icon={cilTrash}
          onClick={() => {
            setDeleteModalVisible(true);
          }}
          className="icon-color-trash extra-margin"
        />
      </td>
    );
  }

  function reasonMessage(item) {
    switch (item.reason) {
      case 1: // 1 = "Sexual content"
        return <td>Sexual content</td>;

      case 2: // 2 = "Violent or repulsive content"
        return <td>Violent or repulsive content</td>;

      case 3: // Hateful or abusive content
        return <td>Hateful or abusive content</td>;

      case 4: // Harmful or dangerous acts
        return <td>Harmful or dangerous acts</td>;

      case 5: // 5 = "Spam or misleading"
        return <td>Spam or misleading</td>;

      default:
        return <td>{item.reason}</td>;
    }
  }

  function getSmartTable() {
    return (
      <>
        <CSmartTable
          columns={columns}
          footer
          className={id}
          items={data}
          tableFilterPlaceholder="Search"
          onTableFilterChange={(item) => {
            updateQuery(item, true);
          }}
          itemsPerPage={itemsPerPage}
          itemsPerPageSelect
          itemsPerPageOptions={[
            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
            32, 33, 34, 35, 36, 37, 38, 39, 40, 50, 100,
          ]}
          onItemsPerPageChange={(item) => {
            setQueryLimit(item);
            updateQuery(searchString, true);
          }}
          columnSorter={{ external: true }}
          onSorterChange={(change) => {
            const columnState = change.state === "asc" ? "asc" : "des";
            switch (change.column) {
              case "Date":
                setBusinessStartedDirection(["updatedAt", columnState]);

                break;
              case "reviewedBy":
                setBusinessStartedDirection(["reviewedBy", columnState]);
                break;
              case "reason":
                setBusinessStartedDirection(["reportReviewText", columnState]);
                break;
              default:
                setBusinessStartedDirection(["updatedAt", columnState]);
                break;
            }

            updateQuery(searchString, true);
          }}
          scopedColumns={{
            logo: (item) => renderLogo(item),
            actions: (item) => renderActions(item),
            reason: (item) => reasonMessage(item),
          }}
        />

        <CModal visible={modalVisible} onClose={() => setModalVisible(false)}>
          <CModalHeader closeButton>
            <h4>Review Not Spam</h4>
          </CModalHeader>
          <CModalBody>Does this review follow pitch59 guidelines?</CModalBody>
          <CModalFooter>
            <CButton
              color="primary"
              onClick={() => {
                reportedReviewNotSpam(columnInfo[0])
                  .then(() => {
                    // show modal saying review is not spam
                    updateQuery(searchString, true);
                    setModalVisible(false);
                  })
                  .finally(() => {
                    showToast("success", "Review is not spam");
                  });
              }}
            >
              Yes
            </CButton>
            <CButton
              color="secondary"
              onClick={() => {
                setModalVisible(false);
                setDeleteModalVisible(true);
              }}
            >
              No
            </CButton>
          </CModalFooter>
        </CModal>
        <CModal
          visible={deleteModalVisible}
          onClose={() => setDeleteModalVisible(false)}
        >
          <CModalHeader closeButton>
            <h4>Delete Review</h4>
          </CModalHeader>
          <CModalBody>Are you sure you want to delete this review?</CModalBody>
          <CModalFooter>
            <CButton
              color="primary"
              onClick={() => {
                deleteReportedReview(columnInfo[0], columnInfo[1])
                  .then(() => {
                    setDeleteModalVisible(false);
                    updateQuery(searchString, true);
                  })
                  .finally(() => {
                    showToast("success", "Review deleted");
                  });
              }}
            >
              Yes
            </CButton>
            <CButton
              color="secondary"
              onClick={() => {
                setDeleteModalVisible(false);
              }}
            >
              No
            </CButton>
          </CModalFooter>
        </CModal>

        <SmartReportedReviewsPagination
          pageCount={Math.ceil(resultCount / itemsPerPage)}
          updateQuery={updateQuery}
          searchString={searchString}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
        />
      </>
    );
  }

  return getSmartTable();
}

export default SmartReportedReviewsTable;
