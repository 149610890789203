import React from "react";
import { CTooltip } from "@coreui/react";
import "bootstrap/dist/css/bootstrap.css";

function PitchcardBadges(numbersString, compactView = true) {
  const colors = ["red", "blue", "green", "purple", "orange"];
  const tooltipsRange = [
    "Business Basic",
    "Employee",
    "Job",
    "Resume",
    "NonProfit",
  ];
  let numbers = [];

  try {
    // eslint-disable-next-line
    numbers = numbersString.split(" ").map((number) => parseInt(number));
  } catch (e) {
    // do nothing
  }

  return (
    <td className={compactView ? "oneLine" : "multiLine"}>
      {numbers.map((number, index) => (
        <CTooltip
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          content={tooltipsRange[index]}
          placement="top"
          trigger="hover"
          offset={[0, 5]}
        >
          <span
            style={{
              color: colors[index % colors.length],
              paddingRight: "5px",
              display: "inline",
            }}
          >
            {number}
          </span>
        </CTooltip>
      ))}
    </td>
  );
}

export default PitchcardBadges;
