import { apiURL, getConfig, postConfig, putConfig } from "./ConfigService.js";

// https://api.pitch59.com/api/tag/list?name=&skip=0&limit=20&sort=nameAZ&status=0&type=0

export function getTagList(
  name = "",
  skip = 0,
  limit = 20,
  sort = "",
  status = 0,
  type = 0
) {
  return fetch(
    `${apiURL}tag/list?name=${name}&skip=${skip}&limit=${limit}&sort=${sort}&status=${status}&type=${type}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function createTag(
  name,
  status,
  type,
  businesses,
  descriptors,
  updatedAt
) {
  //  use  postConfig
  const id = null;
  return fetch(`${apiURL}tag/create`, {
    body: JSON.stringify({
      name,
      status,
      type,
      businesses,
      descriptors,
      updatedAt,
      id,
    }),
    ...postConfig,
  })
    .then((response) => response.json())
    .then((res) => res.data);
}

export function updateTag(
  name,
  status,
  type,
  businesses,
  descriptors,
  updatedAt,
  id
) {
  // eslint-disable-next-line
  if (businesses === undefined) businesses = "";
  return fetch(`${apiURL}tag/update`, {
    body: JSON.stringify([
      {
        name,
        status,
        type,
        businesses,
        descriptors,
        updatedAt,
        id,
      },
    ]),
    ...putConfig,
  })
    .then((response) => response.json())
    .then((res) => res.data);
}
