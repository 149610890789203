import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "@coreui/coreui/dist/css/coreui.min.css";
import { CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react";
import SimpleBar from "simplebar-react";
import NavSidebarItems from "./NavSidebarItems.jsx";
import { Sidebar } from "./Sidebar.jsx";

function NavSidebar() {
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
    >
      <CSidebarBrand>Pitch59</CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <Sidebar items={NavSidebarItems} />
        </SimpleBar>
      </CSidebarNav>
    </CSidebar>
  );
}

export default React.memo(NavSidebar);
