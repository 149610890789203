import { CButton, CCard, CFormInput, CImage } from "@coreui/react";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  resetPasswordEmail,
  sendOtpCodeForAnonymous,
} from "../../services/LoginService.js";

function ResetPassword({ email, setPage }) {
  return (
    <div className="loginForm">
      <ToastContainer autoClose={3000} hideProgressBar limit={1} />
      <CCard className="CCardBorder">
        <div className="loginFormLogo">
          <CImage src="pitch-logo.png" width={200} />
        </div>

        <CFormInput
          type="text"
          placeholder="Enter 4 Digit Code"
          id="otpCode"
          className="optCode"
        />
        <CFormInput
          type="password"
          placeholder="New Password"
          id="password"
          className="NewPassword"
        />
        <CFormInput
          type="password"
          placeholder="Confirm New Password"
          id="passwordConfirm"
          className="NewConfirmPassword"
        />
        <CButton
          color="primary"
          className="loginButton"
          onClick={() => {
            if (
              document.getElementById("otpCode").value === "" ||
              document.getElementById("password").value === "" ||
              document.getElementById("passwordConfirm").value === "" ||
              document.getElementById("password").value !==
                document.getElementById("passwordConfirm").value
            ) {
              let message = "";
              // check if the email is valid a and then set the error message
              if (
                document.getElementById("password").value !==
                document.getElementById("passwordConfirm").value
              ) {
                message = "Passwords do not match";
              }
              // // otp is not 4 digits long or password is not 8 characters long
              if (document.getElementById("otpCode").value.length !== 4) {
                message = "OTP code is not 4 digits long";
              }
              if (document.getElementById("password").value.length < 8) {
                message = "Password is not 8 characters long";
              }

              const notify = () => toast(message);
              notify();
            } else {
              resetPasswordEmail(
                document.getElementById("password").value,
                document.getElementById("passwordConfirm").value,
                email,
                document.getElementById("otpCode").value
              ).then((response) => {
                const notify = () => toast(response[0].message);
                notify();
              });
            }
          }}
        >
          Reset Password
        </CButton>
        <div className="buttonGroup">
          <CButton
            className="backToLogin"
            onClick={() => {
              setPage("login");
            }}
          >
            Back to Login
          </CButton>
          <CButton
            className="forgotPassword"
            onClick={() => {
              sendOtpCodeForAnonymous("", email, "").then(() => {});
            }}
          >
            Resend OTP
          </CButton>
        </div>
      </CCard>
    </div>
  );
}

export default ResetPassword;
