import React from "react";
import CIcon from "@coreui/icons-react";
import { cilPencil } from "@coreui/icons";

function EditCodeBTN({
  item,
  setCustomCodeName,
  setEditing,
  setFreeTrialPeriodValue,
  setIdOfCode,
  setPaymentCyclesCount,
  setPromotionalDiscountValue,
  setShowDiscountEntry,
  setStartDate,
}) {
  const date = new Date(item.hiddenFullDate);

  return (
    <CIcon
      icon={cilPencil}
      className="extra-margin"
      style={{
        cursor: "pointer",
      }}
      size="sm"
      onClick={() => {
        setCustomCodeName(item.code);
        setStartDate(date);
        setPromotionalDiscountValue(item.discount);
        setFreeTrialPeriodValue(item.freeTrialPeriod);
        setPaymentCyclesCount(item.paymentCyclesCount);
        setEditing(true);
        setIdOfCode(item.id);
        setShowDiscountEntry(true);
        window.scrollTo(0, 0); // scroll to top of page to edit the code
      }}
    />
  );
}

export default EditCodeBTN;
