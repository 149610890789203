import Modal from "react-bootstrap/Modal";

import ChangelogTable from "./ChangelogTable.jsx";
import { changelogkeysUsed } from "../exportedVariables/filterVariables.js";
import stampToDate from "../../../services/TimeService.jsx";
import { changes } from "../../../services/BusinessService.js";

function Changelog({
  businessId,
  changelogData,
  onHide,
  setChangelogData,
  show,
  businessName,
}) {
  const columnData = [
    {
      key: "field",
    },
    {
      key: "oldValue",
    },
    {
      key: "newValue",
    },
    {
      key: "createdAt",
    },
    {
      key: "userName",
    },
  ];

  async function getData() {
    await changes(businessId, 1000, 0, "des").then((res) => {
      const dataSend = res.data.map((items) => {
        const neededData = {};
        changelogkeysUsed.forEach((item) => {
          switch (item) {
            case "field":
              neededData.field = items[item];
              break;
            case "oldValue":
              neededData.oldValue = items[item];
              break;
            case "newValue":
              neededData.newValue = items[item];
              break;
            case "createdAt":
              neededData.createdAt = stampToDate(items[item]);
              break;
            case "userName":
              neededData.userName = items[item];
              break;
            default:
              neededData[item] = items[item];
          }
        });
        return neededData;
      });

      setChangelogData(dataSend);
    });
  }

  return (
    <Modal show={show} onHide={onHide} onEntered={getData} fullscreen centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Change Log{" "}
          {businessName != null && businessName !== ""
            ? `: ${businessName}`
            : ""}
        </Modal.Title>
      </Modal.Header>{" "}
      <Modal.Body>
        <div style={{ padding: "1em" }}>
          <ChangelogTable data={changelogData} columns={columnData} />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Changelog;
