import "./Login.scss";

import React, { useState } from "react";
import LoginForm from "./LoginForm.jsx";
import ResetPassword from "./ResetPassword.jsx";

// const [page, setPage] = useState("login");
function LoginPage() {
  const [page, setPage] = useState("login");
  const [email, setEmail] = useState("");
  // forgot password link that should make an api call and prompt the user to enter a valid email only if the email box is empty or not valid;
  document.body.style.backgroundImage =
    "linear-gradient(128deg,#25AFB5,#0094C3)";
  if (page === "forgotPassword") {
    return <ResetPassword email={email} setPage={setPage} />;
  }
  return <LoginForm setPage={setPage} setEmail={setEmail} />;
}

export default LoginPage;
