import Dashboard from "./views/dashboard/Dashboard.jsx";
import Category from "./views/category/Category.jsx";
import Discounts from "./views/discounts/Discounts.jsx";
import Ep from "./views/ep/EP.jsx";
import PitchcardsTab from "./views/pitchcards/PitchcardsTab.jsx";
import Referrals from "./views/referrals/Referrals.jsx";
import ReportedReviews from "./views/reportedreviews/ReportedReviews.jsx";
import Transactions from "./views/transactions/Transactions.jsx";
import Users from "./views/users/Users.jsx";
import products from "./views/products/Products.jsx";
import Reports from "./views/reports/Reports.jsx";

const DropDown1Name = "general";
const routes = [
  { path: "/", exact: true, element: Dashboard },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: `/${DropDown1Name}/category`, name: "Category", element: Category },
  {
    path: `/${DropDown1Name}/discounts`,
    name: "Discounts",
    element: Discounts,
  },
  { path: `/${DropDown1Name}/ep`, name: "Employee Home", element: Ep },
  {
    path: `/${DropDown1Name}/pitchcards`,
    name: "Pitchcards",
    element: PitchcardsTab,
  },
  { path: `/${DropDown1Name}/products`, name: "Products", element: products },
  {
    path: `/${DropDown1Name}/referrals`,
    name: "Referrals",
    element: Referrals,
  },
  {
    path: `/${DropDown1Name}/reportedreviews`,
    name: "Reported Reviews",
    element: ReportedReviews,
  },
  {
    path: `/${DropDown1Name}/transactions`,
    name: "Transactions",
    element: Transactions,
  },
  { path: `/${DropDown1Name}/users`, name: "Users", element: Users },
  { path: `/${DropDown1Name}/reports`, name: "Reports", element: Reports },
];
export default routes;
