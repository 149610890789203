import React, { useState } from "react";
import { CButton, CCol, CFormLabel, CRow } from "@coreui/react";
import DatePicker from "react-datepicker";
import { Dropdown } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";
import CIcon from "@coreui/icons-react";
import { cilArrowLeft, cilArrowRight } from "@coreui/icons";

function CodeExpirationDate({ setStartDate, startDate }) {
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 5 }, (_, i) => currentYear + i);
  const [selectedYear, setSelectedYear] = useState(startDate.getFullYear());

  const handleYearSelect = (selectedYearVar) => {
    const newDate = new Date(startDate);
    newDate.setFullYear(selectedYearVar);
    setStartDate(newDate);
    setSelectedYear(selectedYearVar);
  };

  const handleDateChange = (date) => {
    date.setFullYear(selectedYear);
    setStartDate(date);
  };

  return (
    <CRow className="discount-row">
      <CCol>
        <CFormLabel className="col-form-label col-left">
          Code Expiration Date
        </CFormLabel>
      </CCol>

      <CCol className="cDateCol col-right">
        <DatePicker
          className="cDate col-right"
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="header-container-discountcalendar">
              <CButton
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                color="primary"
                size="sm"
                className="month-arrow"
              >
                <CIcon icon={cilArrowLeft} />
              </CButton>
              <span
                className="month-year"
                style={{ minWidth: "120px", textAlign: "center" }}
              >
                {date.toLocaleString("en-US", { month: "long" })}{" "}
              </span>
              <Dropdown
                style={{
                  // add padding to the right
                  paddingRight: "1rem",
                }}
              >
                <Dropdown.Toggle
                  variant="primary"
                  size="sm"
                  className="year-dropdown"
                >
                  {selectedYear}
                </Dropdown.Toggle>
                <Dropdown.Menu className="year-dropdown-menu">
                  {yearOptions.map((year) => (
                    <Dropdown.Item
                      key={year}
                      onClick={() => handleYearSelect(year)}
                    >
                      {year}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <CButton
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                color="primary"
                size="sm"
                className="month-arrow"
              >
                <CIcon icon={cilArrowRight} />
              </CButton>
            </div>
          )}
          selected={startDate}
          onChange={(date) => handleDateChange(date)}
          minDate={new Date()}
        />
      </CCol>
    </CRow>
  );
}

export default CodeExpirationDate;
