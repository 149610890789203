import React, { useEffect, useState, useCallback } from "react";
import { useDebounce } from "use-debounce";
import debounce from "lodash/debounce";

import CategoryTable from "../../components/category_table/CategoryTable.jsx";
import { queriedCategoryInformation } from "../../components/category_table/database_user_query_information/QueriedCategoryInformation.jsx";

function Category() {
  const [compactView, setCompactView] = React.useState(true);
  const [filterData, setData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [resultCount, setResultCount] = useState(0);
  const [queryLimit, setQueryLimit] = useState(25);
  const [refreshStatePagination, setRefreshPagination] = useState(true);
  const [refreshState, setRefresh] = useState(true);
  const [modalData, setModalData] = useState({
    isVisible: false,
    tagName: "",
    status: "Active",
    businesses: [],
    type: "Position",
    descriptors: "",
    id: 0,
  });

  const [businessStartedDirection, setBusinessStartedDirection] =
    useState("nameAZ");

  const [Search] = useDebounce(searchString, 300);
  const [getOffset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusCategory, setStatusCategory] = useState([0, 0]);

  const [dataReceived, setDataReceived] = useState(false);
  async function getData() {
    await queriedCategoryInformation(
      searchString,
      getOffset,
      setResultCount,
      setData,
      businessStartedDirection,
      queryLimit,
      statusCategory[0],
      statusCategory[1]
    );
  }
  const debouncedGetData = useCallback(
    debounce(async () => {
      await getData();
    }, 300),
    [getData]
  );

  useEffect(() => {
    if (refreshStatePagination) {
      setRefreshPagination(false);
    } else {
      debouncedGetData();
    }

    setRefresh(false);
  }, [refreshState === true, Search]);
  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
      setOffset(0);
    }
  }, [searchString, queryLimit, statusCategory]);
  useEffect(() => {
    debouncedGetData();
  }, [businessStartedDirection]);

  return (
    <CategoryTable
      searchString={searchString}
      setSearchString={setSearchString}
      resultCount={resultCount}
      setResultCount={setResultCount}
      queryLimit={queryLimit}
      setQueryLimit={setQueryLimit}
      refreshStatePagination={refreshStatePagination}
      setRefreshPagination={setRefreshPagination}
      refreshState={refreshState}
      setRefresh={setRefresh}
      businessStartedDirection={businessStartedDirection}
      setBusinessStartedDirection={setBusinessStartedDirection}
      getOffset={getOffset}
      setOffset={setOffset}
      Search={Search}
      setData={setData}
      filterData={filterData}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      dataRecieved={dataReceived}
      setDataRecieved={setDataReceived}
      compactView={compactView}
      setCompactView={setCompactView}
      statusCategory={statusCategory}
      setStatusCategory={setStatusCategory}
      modalData={modalData}
      setModalData={setModalData}
    />
  );
}

export default Category;
