import Multiselect from "multiselect-react-dropdown";

function ViolationFilters({ setViolationType }) {
  const violationTypes = {
    options: [
      { name: "Sexual content", id: 1 },
      { name: "Violent or repulsive content", id: 2 },
      { name: "Hateful or abusive content", id: 3 },
      { name: "Harmful or dangerous acts", id: 4 },
      { name: "Spam or misleading", id: 5 },
    ],
  };

  function onSelectViolationType(selectedList, selectedItem) {
    setViolationType(selectedItem.id);
  }
  function ClearViolationType() {
    setViolationType("");
  }

  return (
    <Multiselect
      options={violationTypes.options}
      onSelect={onSelectViolationType}
      displayValue="name"
      onRemove={ClearViolationType}
      singleSelect
      showArrow
      placeholder="Select Violation Type"
      className="MultiSelectViolationType"
    />
  );
}

export default ViolationFilters;
