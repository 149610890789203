import { NavLink } from "react-router-dom";
import navLink from "./NavLink.jsx";

function NavItem(item, index) {
  const { component, name, badge, icon, ...rest } = item;
  const Component = component;
  return (
    <Component
      {...(rest.to &&
        !rest.items && {
          component: NavLink,
          activeclassname: "active",
        })}
      key={index}
      {...rest}
    >
      {navLink(name, icon, badge)}
    </Component>
  );
}

export default NavItem;
