import { CButton } from "@coreui/react";

function DiscountUpdate({ updateSubmittedCode }) {
  return (
    <CButton
      className="discount-update-btn"
      color="success"
      onClick={() => {
        updateSubmittedCode();
      }}
    >
      Update
    </CButton>
  );
}

export default DiscountUpdate;
