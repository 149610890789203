import CIcon from "@coreui/icons-react";
import { cilCopy } from "@coreui/icons";
import { CDropdownItem } from "@coreui/react";

import React from "react";

function CopyBusinessId({ data, showToast }) {
  async function copyIdToClipboard() {
    navigator.clipboard.writeText(data).then(() => {
      showToast("success", "ID copied to clipboard");
    });
  }

  return (
    <CDropdownItem
      component="button"
      onClick={() => {
        copyIdToClipboard();
      }}
    >
      <CIcon icon={cilCopy} className="me-2" />
      Copy Business ID
    </CDropdownItem>
  );
}

export default CopyBusinessId;
