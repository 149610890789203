import { CCol, CFormLabel, CRow } from "@coreui/react";

function CustomCodeName({ customCodeName, isEditing, setCustomCodeName }) {
  return (
    <CRow className="discount-row">
      <CCol>
        <CFormLabel className="col-form-label col-left">
          Custom Code Name
        </CFormLabel>
      </CCol>
      <CCol>
        <input
          disabled={isEditing}
          className="form-control"
          type="text"
          placeholder="Name"
          value={customCodeName || ""}
          onInput={(e) => {
            setCustomCodeName(e.target.value);
          }}
        />
      </CCol>
    </CRow>
  );
}

export default CustomCodeName;
