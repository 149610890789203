import { toast, ToastContainer } from "react-toastify";
import { CCol, CFormInput, CRow } from "@coreui/react";
import SmartReportedReviewsTable from "./smartTable/SmartReportedReviewsTable.jsx";
import VideoPlayer from "../videoplayer/VideoPlayer.jsx";
import ViolationFilters from "./violationDropdown.jsx";

function ReportedReviewsTable({
  currentPage,
  filterData,
  getOffset,
  queryLimit,
  resultCount,
  searchString,
  setCurrentPage,
  setDataRecieved,
  setOffset,
  setQueryLimit,
  setRefresh,
  setRefreshPagination,
  setSearchString,
  setVideoModalShow,
  setVideoURL,
  videoModalShow,
  videoURL,
  setBusinessStartedDirection,
  businessStartedDirection,
  setViolationType,
  columnInfo,
  setColumnInfo,
}) {
  const columnCustomizations = [
    {
      key: "logo",
      filter: false,
      sorter: false,
    },
    {
      key: "businessName",
      filter: false,
      sorter: false,
    },
    {
      key: "reportedBy",
      filter: false,
      sorter: false,
    },
    {
      key: "Date",
      filter: false,
    },
    {
      key: "reviewedBy",
      filter: false,
    },
    {
      key: "reason",

      filter: false,
    },
    {
      key: "actions",
      filter: false,
      sorter: false,
      label: "Review & Response",
    },
  ];

  const showVideoModal = (url) => {
    setVideoURL(url);
    setVideoModalShow(true);
  };
  const showToast = (
    type = toast.success,
    message = "Success",
    autoClose = 1000,
    hideProgressBar = true,
    position = toast.POSITION.TOP_CENTER
  ) => {
    toast.dismiss(); // Dismiss currently displayed toasts
    toast.clearWaitingQueue();
    toast(message, {
      type,
      autoClose,
      hideProgressBar,
      position,
    });
  };

  const updateQuery = (
    // used from other child components to refresh the query.

    searchQueryString = "",
    refresh = false,
    offset = getOffset,
    showVideo = false,
    url = ""
  ) => {
    if (showVideo) {
      // Show video a q
      showVideoModal(url);
    } else if (offset >= 0) {
      // If the offset needs to change
      setOffset(offset);
    }
    if (refresh) {
      // If the query needs to be refreshed
      setRefresh(true);
      setRefreshPagination(true);
    }

    try {
      setSearchString(searchQueryString);
      setDataRecieved(false);
    } catch {
      // console.log("Error searching database based on your search query.");
    }
  };

  return (
    <div>
      <div>
        <ToastContainer limit={2} autoClose={2000} />
      </div>
      <CRow>
        <CCol>
          <CFormInput
            delay
            type="search"
            placeholder="Search"
            value={searchString}
            onInput={(event) => {
              try {
                updateQuery(event.target.value);
              } catch (e) {
                // just ignore
              }
            }}
          />
        </CCol>

        <CCol>
          <ViolationFilters setViolationType={setViolationType} />
        </CCol>
      </CRow>

      {videoModalShow ? (
        <VideoPlayer
          url={videoURL}
          show={videoModalShow}
          onHide={() => setVideoModalShow(false)}
        />
      ) : (
        []
      )}
      <ToastContainer limit={2} autoClose={2000} />
      <SmartReportedReviewsTable
        data={filterData}
        updateQuery={updateQuery}
        id="pitchcardTable"
        resultCount={resultCount}
        itemsPerPage={queryLimit}
        searchString={searchString}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setQueryLimit={setQueryLimit}
        columns={columnCustomizations}
        setBusinessStartedDirection={setBusinessStartedDirection}
        businessStartedDirection={businessStartedDirection}
        columnInfo={columnInfo}
        setColumnInfo={setColumnInfo}
        showToast={showToast}
      />
    </div>
  );
}

export default ReportedReviewsTable;
