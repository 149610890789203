import { toast, ToastContainer } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { CButton, CCol, CRow } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import SmartProductsTable from "./smartProductsTable/SmartProductsTable.jsx";
import ModalForm from "./ModalForm.jsx";

function ProductsTable({
  setRefresh,
  setRefreshPagination,
  setDataRecieved,
  getOffset,
  setOffset,
  displayDropdownItems,
  setDisplayDropdownItems,
  compactView,
  setCompactView,
  filterData,
  resultCount,
  queryLimit,
  currentPage,
  setCurrentPage,
  setQueryLimit,
  businessStartedDirection,
  setBusinessStartedDirection,
  businessType,
  setBusinessType,
  paymentFrequency,
  setPaymentFrequency,
  modalData,
  setModalData,
}) {
  const BusinessType = {
    options: [
      { name: "service", id: 0 },
      { name: "resume", id: 1 },
      { name: "employee", id: 2 },
      { name: "basic", id: 3 },
      { name: "premium", id: 4 },
      { name: "job", id: 5 },
      { name: "virtualVideo", id: 6 },
      { name: "employerPortal", id: 7 },
      { name: "all", id: 8 },
    ],
  };
  const PaymentFrequency = {
    options: [
      { name: "None", id: 0 },
      { name: "Monthly", id: 1 },
      { name: "Annual", id: 2 },
      { name: "Lifetime", id: 3 },
      { name: "All", id: null },
    ],
  };

  function onSelectBusinessType(selectedList, selectedItem) {
    if (selectedItem.name !== "all") {
      setBusinessType(selectedItem.name);
    } else {
      setBusinessType("");
    }
    setCurrentPage(1);
    setRefresh(true);
    setRefreshPagination(true);
    setDataRecieved(false);
  }

  function getBusinessPlaceholder() {
    switch (businessType) {
      case 0:
        return "Service";
      case 1:
        return "Resume";
      case 2:
        return "Employee";
      case 3:
        return "Basic";
      case 4:
        return "Premium";
      case 5:
        return "Job";
      case 6:
        return "VirtualVideo";
      case 7:
        return "EmployerPortal";
      default:
        return "Select a Type"; // Default placeholder
    }
  }
  function getMultiselect() {
    return (
      <Multiselect
        placeholder={getBusinessPlaceholder()}
        options={BusinessType.options}
        onSelect={onSelectBusinessType}
        selectedValues={BusinessType.options.filter(
          (option) => option.name === businessType
        )}
        displayValue="name"
        singleSelect
        showArrow
      />
    );
  }
  function onSelectPaymentFrequency(selectedList, selectedItem) {
    setPaymentFrequency(selectedItem.id);
    setCurrentPage(1);
    setRefresh(true);
    setRefreshPagination(true);
    setDataRecieved(false);
  }

  function getPaymentPlaceholder() {
    switch (paymentFrequency) {
      case 0:
        return "None";
      case 1:
        return "Monthly";
      case 2:
        return "Annual";
      case 3:
        return "Lifetime";
      default:
        return "All"; // Default placeholder
    }
  }
  function getPaymentMultiselect() {
    return (
      <Multiselect
        placeholder={getPaymentPlaceholder()}
        options={PaymentFrequency.options}
        onSelect={onSelectPaymentFrequency}
        selectedValues={PaymentFrequency.options.filter(
          (option) => option.id === paymentFrequency
        )}
        displayValue="name"
        singleSelect
        showArrow
      />
    );
  }
  const columnCustomizations = [
    {
      key: "name",
    },

    {
      key: "businessType",
    },
    {
      key: "paymentFrequency",
    },
    {
      key: "price",
    },
    {
      key: "referralDiscount",
    },
    {
      key: "type",
      label: "Portal",
    },
    {
      key: "isActive",
    },
  ];

  const showToast = (
    type = toast.success,
    message = "Success",
    autoClose = 1000,
    hideProgressBar = true,
    position = toast.POSITION.TOP_CENTER
  ) => {
    toast.dismiss(); // Dismiss currently displayed toasts
    toast.clearWaitingQueue();
    toast(message, {
      type,
      autoClose,
      hideProgressBar,
      position,
    });
  };

  const updateQuery = (refresh = false, offset = getOffset) => {
    if (offset >= 0) {
      // If the offset needs to change
      setOffset(offset);
    }
    if (refresh) {
      // If the query needs to be refreshed
      setRefresh(true);
      setRefreshPagination(true);
    }
    setDataRecieved(false);
  };
  return (
    <div>
      <ToastContainer limit={2} autoClose={2000} />
      <CRow>
        <CCol>{getMultiselect()}</CCol>
        <CCol>{getPaymentMultiselect()}</CCol>
        <CCol style={{ textAlign: "right" }}>
          <CButton
            onClick={() => {
              setModalData((prev) => ({ ...prev, isVisible: true }));
            }}
          >
            <CIcon icon={cilPlus} />
          </CButton>
        </CCol>
      </CRow>
      <ModalForm modalData={modalData} setModalData={setModalData} />
      <hr />
      <SmartProductsTable
        columns={columnCustomizations}
        data={filterData}
        updateQuery={updateQuery}
        id="pitchcardTable"
        resultCount={resultCount}
        itemsPerPage={queryLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setQueryLimit={setQueryLimit}
        businessStartedDirection={businessStartedDirection}
        setBusinessStartedDirection={setBusinessStartedDirection}
        displayDropdownItems={displayDropdownItems}
        setDisplayDropdownItems={setDisplayDropdownItems}
        showToast={showToast}
        compactView={compactView}
        setCompactView={setCompactView}
      />
    </div>
  );
}
export default ProductsTable;
