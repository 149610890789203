export function businessTypeBadge(status) {
  switch (status) {
    case "job":
      return "success";
    case "business":
      return "secondary";
    case "pending":
      return "warning";
    case "service":
      return "info";
    case "resume":
      return "danger";
    default:
      return "primary";
  }
}

export function businessStatusBadge(status) {
  switch (status) {
    case "Active":
      return "success";
    case "BudgetInsufficient":
      return "secondary";
    case "Draft":
      return "warning";
    case "Inactive":
      return "info";
    case "Rejected":
      return "danger";
    case "PaymentFailed":
      return "dark";
    default:
      return "primary";
  }
}

export function testColor() {
  return "dark";
}

export function billableColor() {
  return "primary";
}

export function businessPaymentFrequencyColor(status) {
  switch (status) {
    case "None":
      return "dark";
    case "Lifetime":
      return "success";
    case "Monthly":
      return "primary";
    case "Annual":
      return "secondary";
    default:
      return "danger";
  }
}
