import { CBadge } from "@coreui/react-pro";
import React from "react";
import { billableColor } from "../services/BillableColor.jsx";

function BillableBadge({ Billable: text }) {
  let localText;
  try {
    localText = text.toUpperCase();
  } catch {
    localText = "";
  }
  return (
    <td>
      <CBadge color={billableColor()}>{localText[0]}</CBadge>
    </td>
  );
}

export default BillableBadge;
