import { CCol, CFormInput, CRow, CFormCheck, CButton } from "@coreui/react";
import Multiselect from "multiselect-react-dropdown";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";

function CategoryFilters({
  Search,
  // hiddenColumns,
  searchString,
  setCurrentPage,
  // sethiddenColumns,
  updateQuery,
  compactView,
  setCompactView,
  statusCategory,
  setStatusCategory,
  setModalData,
}) {
  const statusOptions = [
    { name: "ACTIVE", id: 1 },
    { name: "DRAFT", id: 2 },
    { name: "BLOCKED", id: 3 },
  ];

  const categoryOptions = [
    { name: "CATEGORY TAG", id: 1 },
    { name: "POSITION", id: 2 },
    { name: "SKILL", id: 3 },
    { name: "EDUCATIONAL INSTITUTION", id: 4 },
    { name: "WORK TYPE", id: 5 },
    { name: "EDUCATION LEVEL", id: 6 },
    { name: "BENEFITS", id: 7 },
    { name: "COMPENSATION TYPE", id: 8 },
    { name: "INDUSTRY", id: 9 },
  ];

  function onStatusChange(selectedItems, selectedItem) {
    setCurrentPage(1);
    setStatusCategory([selectedItem.id - 1, statusCategory[1]]);
    updateQuery(searchString, true);
  }

  function onCategoryChange(selectedItems, selectedItem) {
    setCurrentPage(1);
    setStatusCategory([statusCategory[0], selectedItem.id - 1]);
    updateQuery(searchString, true);
  }

  return (
    <CRow className="align-items-start">
      <CCol>
        <CFormInput
          delay={Search}
          type="search"
          placeholder="Search"
          onInput={(event) => {
            updateQuery(event.target.value);
          }}
        />
      </CCol>

      <CCol>
        <Multiselect
          options={statusOptions}
          singleSelect
          displayValue="name"
          placeholder="Select Status"
          onSelect={onStatusChange}
        />
      </CCol>

      <CCol>
        <Multiselect
          options={categoryOptions}
          singleSelect
          displayValue="name"
          placeholder="Select Category"
          onSelect={onCategoryChange}
        />
      </CCol>

      <CCol
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
        }}
      >
        <CFormCheck
          tooltipFeedback
          type="checkbox"
          defaultChecked={compactView}
          onChange={() => {
            setCompactView(!compactView);
          }}
        />
        <div
          style={{
            marginLeft: "7.5px",
          }}
        >
          Compact View
        </div>
      </CCol>
      <CCol>
        <CButton
          onClick={() => {
            setModalData((prev) => ({ ...prev, isVisible: true }));
          }}
        >
          <CIcon icon={cilPlus} />
        </CButton>
      </CCol>
    </CRow>
  );
}

export default CategoryFilters;
