import CIcon from "@coreui/icons-react";
import { cilMoney } from "@coreui/icons";
import { CDropdownItem } from "@coreui/react";
import React from "react";

function FakeChargeItem() {
  function fakeCharge() {}

  return (
    <CDropdownItem component="button" onClick={fakeCharge}>
      <CIcon icon={cilMoney} className="me-2" />
      Fake Charge
    </CDropdownItem>
  );
}

export default FakeChargeItem;
