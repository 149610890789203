import { CBadge } from "@coreui/react-pro";
import React from "react";
import { businessTypeBadge } from "../services/BillableColor.jsx";

function BusinessTypeBadge(item) {
  let text = item;
  try {
    text = text.toUpperCase();
  } catch {
    text = "";
  }

  return (
    <td>
      <CBadge color={businessTypeBadge(item)}>{text.substring(0, 2)}</CBadge>
    </td>
  );
}

export default BusinessTypeBadge;
