import React from "react";

function Dashboard() {
  return (
    <div style={{ textAlign: "left" }}>
      <h1>Version .1.5.2</h1>
      <h5>Update Notes</h5>
      <ul>
        <li>Reported Reviews Tab Complete</li>
        <li>Fixed API calls to delay when typing to minimize request sent</li>
      </ul>
      <h5>Known Bugs / things to take note of</h5>
      <ul>
        <li>
          The EP sorting is not working properly due to the backend only
          allowing one way sorting regardless of asc or desc.
        </li>
        <li>
          The auto resizing of the table is not very graphically appealing and
          will be fixed in the future.
          <br />
        </li>
        <li>
          The Users table has an all Users option that does not render all users
          since the backend has not been routed for it yet.
        </li>
        <li>
          Pitchcards All users endpoint does not allow Payment frequency
          filtering for all users due to backend api
        </li>
      </ul>
    </div>
  );
}

export default Dashboard;
