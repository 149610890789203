const keysUsed = [
  "name",
  "count",
  "Pitchcards",
  "businessCount",
  "ownerName",
  "Email",
  "industries",
  "createdAt",
  "actions",
  "id",
  "userRoles",
];
export default keysUsed;
