import { CButton, CTooltip } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilArrowBottom, cilArrowTop } from "@coreui/icons";
import { toast, ToastContainer } from "react-toastify";
import DiscountEntry from "./discount_form/DiscountEntry.jsx";
import DiscountSmartTable from "./smart_table/DiscountSmartTable.jsx";

function DiscountTable({
  businessStartedDirection,
  currentPage,
  customCodeName,
  discountProps,
  displayDropdownItems,
  editing,
  filterData,
  freeTrialPeriodValue,
  getOffset,
  idOfCode,
  initialCode,
  isPercent,
  paymentCyclesCount,
  promotionalDiscountValue,
  queryLimit,
  refresh,
  refreshPagination,
  resultCount,
  searchString,
  setBusinessStartedDirection,
  setCurrentPage,
  setCustomCodeName,
  setDataReceived,
  setDiscountProps,
  setDisplayDropdownItems,
  setEditing,
  setFreeTrialPeriodValue,
  setIdOfCode,
  setInitialCode,
  setIsPercent,
  setOffset,
  setPaymentCyclesCount,
  setPromotionalDiscountValue,
  setQueryLimit,
  setRefresh,
  setRefreshPagination,
  setResultCount,
  setSearchString,
  setShowDiscountEntry,
  setStartDate,
  showDiscountEntry,
  startDate,
  tableClassName,
  tableHover,
  tableResponsive,
}) {
  const showToast = (
    type = toast.success,
    message = "Success",
    autoClose = 1000,
    hideProgressBar = true,
    position = toast.POSITION.TOP_CENTER
  ) => {
    toast.dismiss(); // Dismiss currently displayed toasts
    toast.clearWaitingQueue();
    toast(message, {
      type,
      autoClose,
      hideProgressBar,
      position,
    });
  };
  const columnCustomizations = [
    // each key is a column name
    {
      key: "code",
      label: "Code",
    },
    {
      key: "admin",
      label: "Admin",
    },
    {
      key: "discount",
      label: "Discount",
    },
    {
      key: "paymentCyclesCount",
      label: "Payment Cycles Count",
    },
    {
      key: "freeTrialPeriod",
      label: "Free Trial Period",
    },
    {
      key: "codeExpirationDate",
      label: "Code Expiration Date",
    },
    {
      key: "usageCount",
      label: "Usage Count",
    },
    {
      key: "buttons",
      label: "",
      sorter: false,
    },
  ];
  // show hide button for DiscountEntry component with an arrow that points up or down
  const updateQuery = (
    newSearchString = searchString,
    // used from other child components to refresh the query.
    refreshData = false,
    offset = getOffset
  ) => {
    if (offset >= 0) {
      // If the offset needs to change
      setOffset(offset);
    }
    if (refreshData) {
      // If the query needs to be refreshed
      setRefresh(true);
      setRefreshPagination(true);
    }
    if (newSearchString !== searchString) {
      setRefreshPagination(false);
    }

    setSearchString(newSearchString);
    setDataReceived(false);
  };
  return (
    <>
      <ToastContainer limit={2} autoClose={2000} />
      <CButton
        onClick={() => {
          setShowDiscountEntry(!showDiscountEntry);
        }}
        style={{
          float: "right",
        }}
      >
        {" "}
        <CTooltip
          content={
            showDiscountEntry ? "Hide Discount Form" : "Show Discount Form"
          }
          placement="top"
          trigger="hover"
          offset={[0, 5]}
        >
          <CIcon icon={showDiscountEntry ? cilArrowTop : cilArrowBottom} />
        </CTooltip>
      </CButton>
      <div style={{ display: showDiscountEntry ? "block" : "none" }}>
        <h2>Discounts</h2>
        <DiscountEntry
          editing={editing}
          setEditing={setEditing}
          discountProps={discountProps}
          setDiscountProps={setDiscountProps}
          paymentCyclesCount={paymentCyclesCount}
          setPaymentCyclesCount={setPaymentCyclesCount}
          isPercent={isPercent}
          setIsPercent={setIsPercent}
          promotionalDiscountValue={promotionalDiscountValue}
          setPromotionalDiscountValue={setPromotionalDiscountValue}
          freeTrialPeriodValue={freeTrialPeriodValue}
          setFreeTrialPeriodValue={setFreeTrialPeriodValue}
          customCodeName={customCodeName}
          setCustomCodeName={setCustomCodeName}
          initialCode={initialCode}
          setInitialCode={setInitialCode}
          startDate={startDate}
          setStartDate={setStartDate}
          idOfCode={idOfCode}
          setIdOfCode={setIdOfCode}
          updateQuery={updateQuery}
          showToast={showToast}
        />
        <hr />
      </div>
      <h2>Code History</h2>

      <DiscountSmartTable
        columns={columnCustomizations}
        data={filterData}
        tableClassName={tableClassName}
        tableHover={tableHover}
        tableResponsive={tableResponsive}
        itemsPerPage={queryLimit}
        updateQuery={updateQuery}
        searchString={searchString}
        setQueryLimit={setQueryLimit}
        setOffset={setOffset}
        setRefresh={setRefresh}
        setRefreshPagination={setRefreshPagination}
        setSearchString={setSearchString}
        setDataRecieved={setDataReceived}
        displayDropdownItems={displayDropdownItems}
        setDisplayDropdownItems={setDisplayDropdownItems}
        getOffset={getOffset}
        refresh={refresh}
        refreshPagination={refreshPagination}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        resultCount={resultCount}
        setResultCount={setResultCount}
        businessStartedDirection={businessStartedDirection}
        setBusinessStartedDirection={setBusinessStartedDirection}
        editing={editing}
        setEditing={setEditing}
        paymentCyclesCount={paymentCyclesCount}
        setPaymentCyclesCount={setPaymentCyclesCount}
        isPercent={isPercent}
        setIsPercent={setIsPercent}
        promotionalDiscountValue={promotionalDiscountValue}
        setPromotionalDiscountValue={setPromotionalDiscountValue}
        freeTrialPeriodValue={freeTrialPeriodValue}
        setFreeTrialPeriodValue={setFreeTrialPeriodValue}
        customCodeName={customCodeName}
        setCustomCodeName={setCustomCodeName}
        initialCode={initialCode}
        setInitialCode={setInitialCode}
        startDate={startDate}
        setStartDate={setStartDate}
        idOfCode={idOfCode}
        setIdOfCode={setIdOfCode}
        setShowDiscountEntry={setShowDiscountEntry}
        showDiscountEntry={showDiscountEntry}
        showToast={showToast}
      />
    </>
  );
}

export default DiscountTable;
