import { getDiscounts } from "../../../services/DiscountService.js";
import keysUsed from "../FilterComponents/FilterQueriedItems.js";

/* eslint-disable */

export async function DatabaseQueriedInformation(
  searchString,
  getOffset,
  resultCount,
  filterData,
  businessStartedDirection,
  queryLimit,
  setData,
  setResultCount
) {
  const res = await getDiscounts(
    queryLimit,
    getOffset,
    searchString,
    businessStartedDirection[0],
    businessStartedDirection[1]
  );
  setResultCount(res.count);
  const dataSend = res.records.map((items) => {
    const neededData = {};

    keysUsed.forEach((item) => {
      switch (item) {
        case "code":
          try {
            neededData[item] = items[item];
          } catch (e) {
            neededData[item] = "";
          }
          break;

        case "admin":
          try {
            neededData[item] = items[item];
          } catch (e) {
            neededData[item] = "";
          }
          break;

        case "discount":
          try {
            neededData[item] = items[item];
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "paymentCyclesCount":
          try {
            // null is not displayed in the table
            neededData[item] = items[item] === null ? "" : items[item];
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "freeTrialPeriod":
          try {
            neededData[item] = items[item] === null ? "" : items[item];
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "codeExpirationDate":
          try {
            // only extract in this format : YYYY-MM-DD
            neededData[item] = items[item].slice(0, 10);
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "usageCount":
          try {
            neededData[item] = items[item];
          } catch (e) {
            neededData[item] = "";
          }

          break;

        case "id":
          try {
            neededData[item] = items[item];
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "buttons":
          try {
            neededData[item] = "";
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "hiddenFullDate":
          try {
            neededData[item] = items.codeExpirationDate;
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "actions":
          neededData[item] = "";
        default:
          try {
            neededData[item] = items[item];
          } catch (e) {
            neededData[item] = "";
          }
      }
    });
    return neededData;
  });
  setData(dataSend);
}

/* eslint-enable */
