import React from "react";
import ReactPlayer from "react-player";
import Modal from "react-bootstrap/Modal";

function VideoPlayer({ url, show, onHide }) {
  // props.url should be included in the props validation, but it is not, so I am adding it here
  return (
    <Modal
      show={show}
      onHide={onHide}
      url={url}
      size="lg"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Pitch Video</Modal.Title>
      </Modal.Header>
      <div className="player-wrapper">
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          className="react-player"
          playing
          controls
        />
      </div>
    </Modal>
  );
}

export default VideoPlayer;
