import keysUsed from "../exportedVariables/FilterQueriedItems.js";

import stampToDate from "../../../services/TimeService.jsx";
import { getOrganizationList } from "../../../services/EmployeeportalService.js";

export async function QueriedEmployeePortalInformation(
  refreshBillableTester = [false, "", ""],
  limit = 25,
  offset = 0,
  searchText = "",
  // searchStatuses = "",
  sortColumn = "",
  sortType = "des",
  testing = false,
  billable = true,
  // businessBounced = "",
  setResultCount = () => {},
  setData = () => {}
) {
  let res;
  if (!refreshBillableTester[0]) {
    // Backend needs endpoint for this to function properly.
    res = await getOrganizationList(
      limit,
      offset,
      searchText,

      sortColumn,
      sortType,
      testing,
      billable,
      false
    );
  } else {
    res = await getOrganizationList(
      limit,
      offset,
      searchText,

      sortColumn,
      sortType,
      testing,
      billable,
      false
    );
  }

  setResultCount(res.count);
  const dataSend = res.records.map((items) => {
    const neededData = {};

    keysUsed.forEach((item) => {
      switch (item) {
        case "businessName":
          try {
            neededData[item] = items[item];
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "count":
          try {
            neededData[item] = items.count;
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "businessCount":
          try {
            // an array of key value pairs {"resume":0,"basic":0,"employee":0,"service":0,"job":1}, return each value in the array as 1 string concatenated
            const orderedKeys = [
              "basic",
              "employee",
              "job",
              "resume",
              "nonprofit",
            ];
            const orderedValues = orderedKeys.map(
              (key) => items[item][key] || 0
            );
            neededData[item] = orderedValues.join(" ");
          } catch (e) {
            neededData[item] = "";
          }
          break;

        case "Email":
          try {
            neededData[item] = items.email;
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "industries":
          try {
            // each array has a name key with a value, return each value in the array as 1 string concatenated separated by a comma
            neededData[item] = items[item].map((i) => i.name).join(": ");
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "createdAt":
          try {
            neededData[item] = stampToDate(items[item]);
          } catch (e) {
            neededData[item] = "";
          }
          break;

        case "actions":
          neededData[item] = "";

          break;
        case "id":
          try {
            neededData.id = items.id;
          } catch (e) {
            neededData.id = "";
          }
          break;
        case "userRoles": // Parsed in smart table
          try {
            neededData.userRoles = `${items.isBillableOrganization}:${items.isTesterOrganization}`;
          } catch (e) {
            neededData.userRoles = "";
          }
          break;
        default:
          try {
            if (items[item] == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items[item];
            }
          } catch (e) {
            neededData[item] = "";
          }

          break;
      }
    });
    return neededData;
  });

  setData(dataSend);
}
