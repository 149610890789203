import React from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CRow,
  CCol,
} from "@coreui/react";
import "../../Style.scss";
import stampToDate from "../../services/TimeService.jsx";

function TransactionsPane({ currentTransaction }) {
  const fields = [
    { key: "status", label: "Transaction Status" },
    { key: "type", label: "Transaction Type" },
    { key: "amount", label: "Amount Charged" },
    { key: "dates", label: "Created/Updated At" },
    { key: "error", label: "Failed Type" },
  ];

  const transactionTypes = [
    { name: "CREATED", id: 0 },
    { name: "PROCESSING", id: 1 },
    { name: "PROCESSED", id: 2 },
    { name: "CAPTURE", id: 3 },
    { name: "CANCELLED", id: 4 },
    { name: "ERROR", id: 5 },
    { name: "RETURN FUNDS", id: 6 },
  ];

  const getTypeName = (id) => {
    const typeName = transactionTypes.find((type) => type.id === id);
    return typeName ? typeName.name : "Unknown";
  };

  return (
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol>
            <h4>Transaction Details</h4>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        {currentTransaction && currentTransaction.length > 0 ? (
          <CTable responsive className="transactions-pane-table">
            <thead>
              <tr>
                {fields.map((field) => (
                  <th key={field.key}>{field.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentTransaction.map((transaction, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index} className="Dark-rows">
                  <td>{getTypeName(transaction.status)}</td>
                  <td>{getTypeName(transaction.type)}</td>
                  <td>{transaction.amount}</td>
                  <td>
                    {stampToDate(transaction.createdAt)} /{" "}
                    {stampToDate(transaction.updatedAt)}
                  </td>
                  <td>{transaction.error}</td>
                </tr>
              ))}
            </tbody>
          </CTable>
        ) : (
          <p>No transactions available.</p>
        )}
      </CCardBody>
    </CCard>
  );
}

export default TransactionsPane;
