const keysUsed = [
  "code",
  "admin",
  "discount",
  "paymentCyclesCount",
  "freeTrialPeriod",
  "codeExpirationDate",
  "usageCount",
  "id",
  "buttons",
  "hiddenFullDate",
];

export default keysUsed;
