import "./SmartTable.css";
import { CSmartTable } from "@coreui/react-pro";
import { CTooltip } from "@coreui/react";
import "../../../Style.scss";

import BusinessTypeBadge from "./badges/BusinessTypeBadge.jsx";
import BusinessStatusBadge from "./badges/BusinessStatusBadge.jsx";
import BillableBadge from "./badges/BillableBadge.jsx";
import TesterBadge from "./badges/TesterBadge.jsx";
import BusinessPaymentFrequencyBadge from "./badges/BusinessPaymentFrequencyBadge.jsx";
import DropDownOptions from "./dropdown/DropDownOptions.jsx";
import SmartTablePagination from "./pagination/SmartTablePagination.jsx";
import cellCustomizations from "./services/CellCustomizations.jsx";
import { impersonateUserRequest } from "../../../services/UserService.js";

function SmartTable(props) {
  SmartTable.defaultProps = {
    columns: { Loading: {} },
    data: { Loading: {} },
    tableClassName: "sTable",
    tableHover: true,
    tableStriped: true,
    itemsPerPage: 25,
    searchString: "",
  };

  const showModal = (id, businessName) => {
    props.setChangeLogId([id, businessName]);
    props.setChangeLogModalShow(true);
  };

  function getSmartTable() {
    function getFullNameTd(item) {
      return (
        <td className={props.compactView ? "oneLine" : "multiLine"}>
          <CTooltip
            content="Login as User"
            placement="top"
            trigger="hover"
            offset={[0, 5]}
          >
            <button
              type="button"
              className="buttonText"
              onClick={() => {
                impersonateUserRequest(item.userId).then((res) => {
                  window.open(
                    `https://staging.pitch59.com/welcome?token=${res}`,
                    "_blank"
                  );
                });
              }}
            >
              {item.fullName}
            </button>
          </CTooltip>
        </td>
      );
    }

    return (
      <>
        <CSmartTable
          clickableRows={!props.arrowDirection}
          footer
          className="sTable"
          items={props.data}
          columns={props.columns}
          itemsPerPageSelect
          itemsPerPageOptions={[
            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
            32, 33, 34, 35, 36, 37, 38, 39, 40, 50, 100,
          ]}
          onRowClick={(item) => {
            props.updatePane(item);
          }}
          columnSorter={{ external: true }}
          onSorterChange={(change) => {
            {
              const columnState = change.state === "asc" ? "asc" : "des";
              if (change.column === "businessName") {
                props.setBusinessStartedDirection([
                  "businessName",
                  columnState,
                ]);
              } else if (change.column === "started")
                props.setBusinessStartedDirection(["createdAt", columnState]);
            }

            props.updateQuery(props.searchString, true);
          }}
          sorterValue={{
            column:
              props.businessStartedDirection[0] === "createdAt"
                ? "started"
                : "businessName",
            state: props.businessStartedDirection[1] === "asc" ? "asc" : "desc",
          }}
          tableFilterLabel=""
          itemsPerPage={props.itemsPerPage}
          tableProps={{
            hover: props.tableHover,
            small: true,
          }}
          onItemsPerPageChange={(item) => {
            props.setQueryLimit(item);
            props.updateQuery(props.searchString, true);
          }}
          scopedColumns={{
            Type: (item) => BusinessTypeBadge(item.Type),
            businessName: (item) =>
              cellCustomizations(
                item.businessName,
                null,
                null,
                null,
                false,
                props.compactView,
                false,
                true
              ),
            fullName: (item) => getFullNameTd(item),
            phone: (item) =>
              cellCustomizations(
                item.phone,
                null,
                null,
                props.showToast,

                true,
                props.compactView,
                true
              ),
            email: (item) =>
              cellCustomizations(
                item.email,
                null,
                null,
                props.showToast,
                true,
                props.compactView,
                true
              ),
            started: (item) =>
              cellCustomizations(
                item.started,
                showModal,
                [item.businessId, item.businessName],
                null,
                true,
                props.compactView
              ),
            approved: (item) =>
              cellCustomizations(
                item.approved,
                null,
                null,
                false,
                false,
                props.compactView,
                false,
                true
              ),
            category: (item) =>
              cellCustomizations(
                item.category,
                null,
                null,
                false,
                null,
                props.compactView,
                false,
                true
              ),
            Status: (item) => BusinessStatusBadge(item),
            Billable: (item) => BillableBadge(item),
            tester: (item) => TesterBadge(item),
            paymentFrequency: (item) => BusinessPaymentFrequencyBadge(item),
            Options: (item) =>
              DropDownOptions(
                item,
                props.id,
                props.updateQuery,
                props.searchString,
                props.accountStatus,
                props.displayDropdownItems,
                props.setDisplayDropdownItems,
                props.showToast
              ),
          }}
          tableHeadProps={{
            className: "sTableHead",
          }}
          tableBodyProps={{
            className: "sTableBody",
          }}
        />

        <SmartTablePagination
          pageCount={Math.ceil(props.resultCount / props.itemsPerPage)}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          currentPage={props.currentPage}
          setCurrentPage={props.setCurrentPage}
          itemsPerPage={props.itemsPerPage}
        />
      </>
    );
  }

  return getSmartTable();
}

export default SmartTable;
