import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import { cilTrash } from "@coreui/icons";
import { deleteDiscountCode } from "../../../services/DiscountService.js";
import ConfirmationDeleteModal from "../../reusable_components/ConfirmationDeleteModal.jsx";

function DeleteCodeBTN({ _id, searchString, updateQuery, showToast }) {
  const [showModal, setShowModal] = useState(false);

  const handleDelete = () => {
    deleteDiscountCode(_id.id)
      .then(() => {
        showToast("success", "Discount Code Deleted");
        updateQuery(searchString, true);
      })
      .catch(() => {
        showToast("error", "Error deleting the Discount Code");
      })
      .finally(() => {
        setShowModal(false);
      });
  };

  return (
    <>
      <CIcon
        icon={cilTrash}
        className="extra-margin"
        size="sm"
        style={{ cursor: "pointer", color: "red" }}
        onClick={() => setShowModal(true)}
      />
      <ConfirmationDeleteModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onConfirm={handleDelete}
        title="Delete Discount Code"
        body="Are you sure you want to delete this Discount Code?"
      />
    </>
  );
}

export default DeleteCodeBTN;
