import { toast, ToastContainer } from "react-toastify";
import SmartCategoryTable from "./smartTable/SmartCategoryTable.jsx";
import CategoryFilters from "./filterComponents/CategoryFilters.jsx";
import ModalForm from "./ModalForm.jsx";

function CategoryTable({
  setRefresh,
  setRefreshPagination,
  searchString,
  setSearchString,
  setDataRecieved,
  getOffset,
  setOffset,
  hiddenColumns,
  sethiddenColumns,
  compactView,
  setCompactView,
  changeLogModalShow,
  setChangeLogModalShow,
  changeLogId,
  setChangeLogId,

  filterData,
  resultCount,
  queryLimit,
  currentPage,
  setCurrentPage,
  setQueryLimit,
  businessStartedDirection,
  setBusinessStartedDirection,
  statusCategory,
  setStatusCategory,
  modalData,
  setModalData,
}) {
  const columnCustomizations = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "status",
      label: "Status",
      sorter: false,
    },
    {
      key: "type",
      label: "Type",
      sorter: false,
    },
    {
      key: "businesses",
      label: "Businesses",
      sorter: false,
    },
    {
      key: "descriptors",
      label: "Descriptors",
      sorter: false,
    },
    {
      key: "updatedAt",
      label: "Updated At",
    },
    {
      key: "actions",
      label: "",
      sorter: false,
    },
  ];

  const showToast = (
    type = toast.success,
    message = "Success",
    autoClose = 1000,
    hideProgressBar = true,
    position = toast.POSITION.TOP_CENTER
  ) => {
    toast.dismiss(); // Dismiss currently displayed toasts
    toast.clearWaitingQueue();
    toast(message, {
      type,
      autoClose,
      hideProgressBar,
      position,
    });
  };

  const updateQuery = (
    query = searchString,
    refresh = false,
    offset = getOffset
  ) => {
    if (offset >= 0) {
      // If the offset needs to change
      setOffset(offset);
    }
    if (refresh) {
      // If the query needs to be refreshed
      setRefresh(true);
      setRefreshPagination(true);
    }
    if (query !== searchString) {
      setRefreshPagination(false);
    }

    setSearchString(query);
    setDataRecieved(false);
  };

  return (
    <div>
      <ToastContainer limit={2} autoClose={2000} />
      <CategoryFilters
        updateQuery={updateQuery}
        setCurrentPage={setCurrentPage}
        searchString={searchString}
        sethiddenColumns={sethiddenColumns}
        hiddenColumns={hiddenColumns}
        compactView={compactView}
        setCompactView={setCompactView}
        statusCategory={statusCategory}
        setStatusCategory={setStatusCategory}
        setModalData={setModalData}
      />

      <ModalForm modalData={modalData} setModalData={setModalData} />
      <hr />
      <SmartCategoryTable
        columns={columnCustomizations}
        data={filterData}
        updateQuery={updateQuery}
        id="pitchcardTable"
        resultCount={resultCount}
        itemsPerPage={queryLimit}
        searchString={searchString}
        setSearchString={setSearchString}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setQueryLimit={setQueryLimit}
        businessStartedDirection={businessStartedDirection}
        setBusinessStartedDirection={setBusinessStartedDirection}
        changeLogModalShow={changeLogModalShow}
        setChangeLogModalShow={setChangeLogModalShow}
        changeLogId={changeLogId}
        setChangeLogId={setChangeLogId}
        showToast={showToast}
        compactView={compactView}
        setCompactView={setCompactView}
        setModalData={setModalData}
      />
    </div>
  );
}
export default CategoryTable;
