import NavLink from "./NavLink.jsx";
import NavItem from "./NavItem.jsx";

function NavGroup({ component, icon, items, name, to, ...rest }, index) {
  const Component = component;
  return (
    <Component
      idx={String(index)}
      key={index}
      toggler={NavLink(name, icon)}
      // eslint-disable-next-line
      visible={location.pathname.startsWith(to)}
      {...rest}
    >
      {items?.map((item, indexOfItem) =>
        item.items ? NavGroup(item, indexOfItem) : NavItem(item, indexOfItem)
      )}
    </Component>
  );
}

export default NavGroup;
