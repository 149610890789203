import { CSmartPagination } from "@coreui/react-pro";
import { useEffect } from "react";

function SmartCategoryTablePagination({
  currentPage,
  itemsPerPage,
  pageCount,
  searchString,
  setCurrentPage,
  updateQuery,
}) {
  function getNewQuery() {
    updateQuery(
      searchString,
      true,
      itemsPerPage * (currentPage - 1 >= -1 ? currentPage - 1 : 0)
    );
  }

  useEffect(() => {
    getNewQuery();
  }, [currentPage]);
  return (
    <div style={{ maxWidth: "100%", zIndex: 1, position: "relative" }}>
      <CSmartPagination
        pages={pageCount}
        activePage={currentPage || 1}
        onActivePageChange={setCurrentPage}
        align="center"
        limit={10}
      />
    </div>
  );
}

export default SmartCategoryTablePagination;
