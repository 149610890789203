import React, { useEffect, useState, useCallback } from "react";
import { useDebounce } from "use-debounce";
import debounce from "lodash/debounce";
import UserTable from "../../components/usertable/UserTable.jsx";
import { queriedUserInformation } from "../../components/usertable/database_user_query_information/QueriedUserInformation.jsx";

function Users() {
  const [compactView, setCompactView] = React.useState(true);
  const [filterData, setData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [resultCount, setResultCount] = useState(0);
  const [queryLimit, setQueryLimit] = useState(25);
  const [refreshStatePagination, setRefreshPagination] = useState(true);
  const [refreshState, setRefresh] = useState(true);
  const [refreshBillableTester, setRefreshBillableTester] = useState([
    false,
    true,
    true,
  ]);
  const [businessStartedDirection, setBusinessStartedDirection] = useState([
    "createdAt",
    "des",
  ]);
  const [Search] = useDebounce(searchString, 300);
  const [getOffset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [changeLogModalShow, setChangeLogModalShow] = React.useState(false);
  const [changeLogId, setChangeLogId] = React.useState(["", ""]);
  const [changelogData, setChangelogData] = React.useState([]);
  const [displayDropdownItems, setDisplayDropdownItems] = useState([
    false,
    "userId",
  ]);

  const [hiddenColumns, sethiddenColumns] = React.useState({
    0: " Visible",
    userName: " Visible",
    createdAt: "Visible",
    email: " Visible",
    contactNumber: " Visible",
    referralCount: " hidden",
    lastOtp: " hidden",
    userRoles: " Visible",
    businessesCount: " Visible",
    platform: " Visible",
    teamsCount: " Visible",
  });

  const getHiddenColumns = () => {
    const hiddenColumnsData = JSON.parse(
      localStorage.getItem("hiddenUserColumns")
    );
    if (hiddenColumnsData !== hiddenColumns) {
      sethiddenColumns(hiddenColumnsData);
    }
  };

  // create a function to save hiddenColumns in local storage
  const saveHiddenColumns = (hiddenC) => {
    localStorage.setItem("hiddenUserColumns", JSON.stringify(hiddenC));
  };
  // create a function to get hiddenColumns from local storage

  useEffect(() => {
    saveHiddenColumns();
  }, [hiddenColumns]);
  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
      setOffset(0);
    }
  }, [
    refreshBillableTester,
    businessStartedDirection,
    searchString,
    queryLimit,
  ]);
  const [dataReceived, setDataReceived] = useState(false);

  async function getData() {
    await queriedUserInformation(
      refreshBillableTester,
      searchString,
      getOffset,
      setResultCount,
      setData,
      businessStartedDirection,
      queryLimit
    );
  }
  const debouncedGetData = useCallback(
    debounce(async () => {
      await getData();
    }, 300),
    [getData]
  );

  useEffect(() => {
    if (refreshStatePagination) {
      setRefreshPagination(false);
    } else {
      debouncedGetData();
    }

    setRefresh(false);
  }, [refreshState === true, Search]);
  return (
    <UserTable
      searchString={searchString}
      setSearchString={setSearchString}
      resultCount={resultCount}
      setResultCount={setResultCount}
      queryLimit={queryLimit}
      setQueryLimit={setQueryLimit}
      refreshStatePagination={refreshStatePagination}
      setRefreshPagination={setRefreshPagination}
      refreshState={refreshState}
      setRefresh={setRefresh}
      refreshBillableTester={refreshBillableTester}
      setRefreshBillableTester={setRefreshBillableTester}
      businessStartedDirection={businessStartedDirection}
      setBusinessStartedDirection={setBusinessStartedDirection}
      getOffset={getOffset}
      setOffset={setOffset}
      Search={Search}
      setData={setData}
      filterData={filterData}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      hiddenColumns={hiddenColumns}
      sethiddenColumns={sethiddenColumns}
      changeLogModalShow={changeLogModalShow}
      setChangeLogModalShow={setChangeLogModalShow}
      changeLogId={changeLogId}
      setChangeLogId={setChangeLogId}
      changelogData={changelogData}
      setChangelogData={setChangelogData}
      displayDropdownItems={displayDropdownItems}
      setDisplayDropdownItems={setDisplayDropdownItems}
      dataRecieved={dataReceived}
      setDataRecieved={setDataReceived}
      getHiddenColumns={getHiddenColumns}
      saveHiddenColumns={saveHiddenColumns}
      compactView={compactView}
      setCompactView={setCompactView}
    />
  );
}

export default Users;
