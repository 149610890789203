import keysUsed from "../exportedVariables/QueriedKeysUsed.js";

import { getProducts } from "../../../services/ProductsService.js";

export async function queriedProductsInformation(
  limit,
  getOffset,
  isActive,
  businessType,
  paymentFrequency,
  setResultCount,
  setData
) {
  const res = await getProducts(
    limit,
    getOffset,
    isActive,
    businessType,
    paymentFrequency
  );

  setResultCount(res.length); // This needs to be changed later when the backend stores the total number of results

  const dataSend = res.map((items) => {
    //
    const neededData = {};

    keysUsed.forEach((item) => {
      switch (item) {
        case "price":
          neededData[item] = `$${items[item]}`;
          break;
        case "referralDiscount":
          neededData[item] = `$${items[item]}`;
          break;
        case "paymentFrequency":
          switch (items[item]) {
            case 1:
              neededData[item] = "Monthly";
              break;
            case 2:
              neededData[item] = "Annual";
              break;
            case 3:
              neededData[item] = "Lifetime";
              break;
            default:
              neededData[item] = "None";
              break;
          }

          break;
        default:
          try {
            if (items[item] == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items[item];
            }
          } catch (e) {
            neededData[item] = "";
          }

          break;
      }
    });
    return neededData;
  });

  setData(dataSend);
}
