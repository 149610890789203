import keysUsed from "../exportedVariables/QueriedKeysUsed.js";
import { userListAdminTesterBillable } from "../../../services/UserService.js";
import stampToDate from "../../../services/TimeService.jsx";

export async function queriedUserInformation(
  refreshBillableTester,
  queryString,
  getOffset,
  setResultCount,
  setData,
  businessStartedDirection,
  queryLimit
) {
  let res;
  if (!refreshBillableTester[0]) {
    // Backend needs endpoint for this to function properly.
    res = await userListAdminTesterBillable(
      queryString,
      queryLimit,
      getOffset,
      businessStartedDirection[0],
      businessStartedDirection[1],
      "",
      false
    );
  } else {
    res = await userListAdminTesterBillable(
      queryString,
      queryLimit,
      getOffset,
      businessStartedDirection[0],
      businessStartedDirection[1],
      "",
      false,

      refreshBillableTester[2],
      refreshBillableTester[1]
    );
  }

  setResultCount(res.count);
  const dataSend = res.records.map((items) => {
    const neededData = {};

    keysUsed.forEach((item) => {
      switch (item) {
        case "id":
          try {
            neededData[item] = items[item]; // hidden from the table
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "createdAt":
          try {
            neededData.createdAt = stampToDate(items[item]);
          } catch (e) {
            neededData.createdAt = "";
          }
          break;

        case "referralCount": // parse in smarttable
          try {
            neededData.referralCount = items[item];
          } catch (e) {
            neededData.referralCount = "";
          }
          break;
        case "lastOtp":
          try {
            neededData.lastOtp = items[item] ? items[item] : "";
          } catch (e) {
            neededData.lastOtp = "";
          }
          break;
        case "userRoles": // Parsed in smart table
          try {
            neededData.userRoles = `${items.isAdmin}:${items.isBillableUser}:${items.isTesterUser}:${items.referredByName}`;
          } catch (e) {
            neededData.userRoles = "";
          }
          break;
        case "platform": // Parsed in smart table
          try {
            neededData.platform = items.userDeviceInfo;
          } catch (e) {
            neededData.platform = "";
          }
          break;

        default:
          try {
            if (items[item] == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items[item];
            }
          } catch (e) {
            neededData[item] = "";
          }

          break;
      }
    });
    return neededData;
  });

  setData(dataSend);
}
