import keysUsed from "../exportedVariables/QueriedKeysUsed.js";
import stampToDate from "../../../services/TimeService.jsx";
import { getTagList } from "../../../services/CategoryService.js";

export async function queriedCategoryInformation(
  queryString,
  getOffset,
  setResultCount,
  setData,
  businessStartedDirection,
  queryLimit,
  queryStatus,
  queryCategory
) {
  // Backend needs endpoint for this to function properly.
  const res = await getTagList(
    queryString,
    getOffset,
    queryLimit,
    businessStartedDirection,
    queryStatus,
    queryCategory
  );

  setResultCount(res.count);
  const dataSend = res.records.map((items) => {
    const neededData = {};

    keysUsed.forEach((item) => {
      switch (item) {
        case "updatedAt":
          neededData[item] = stampToDate(items[item]);
          break;
        default:
          neededData[item] = items[item];
      }
    });
    return neededData;
  });

  setData(dataSend);
}
