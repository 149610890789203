import { toast, ToastContainer } from "react-toastify";
import SmartUserTable from "./smartTable/SmartUserTable.jsx";

import UserFilters from "./filterComponents/UserFilters.jsx";
import Changelog from "../pitchcardtable/changelog/Changelog.jsx";

function UserTable({
  refreshBillableTester,
  setRefreshBillableTester,
  setRefresh,
  setRefreshPagination,
  searchString,
  setSearchString,
  setDataRecieved,
  getOffset,
  setOffset,
  displayDropdownItems,
  setDisplayDropdownItems,
  hiddenColumns,
  sethiddenColumns,
  compactView,
  setCompactView,
  changeLogModalShow,
  setChangeLogModalShow,
  changeLogId,
  setChangeLogId,
  changelogData,
  setChangelogData,
  filterData,
  resultCount,
  queryLimit,
  currentPage,
  setCurrentPage,
  setQueryLimit,
  businessStartedDirection,
  setBusinessStartedDirection,
}) {
  const columnCustomizations = [
    {
      key: "userName",
      className: `userName${hiddenColumns["0"]}`,
    },
    {
      key: "createdAt",
      className: `createdAt${hiddenColumns.createdAt}`,

      label: "Started",
    },
    {
      key: "email",
      className: `email${hiddenColumns.email}`,
      sorter: false,
    },
    {
      key: "contactNumber",
      className: `contactNumber${hiddenColumns.contactNumber}`,
      sorter: false,

      label: "Phone",
    },
    {
      key: "referralCount",
      className: `referralCount${hiddenColumns.referralCount}`,
    },
    {
      key: "lastOtp",
      className: `lastOtp${hiddenColumns.lastOtp}`,
      sorter: false,
    },
    {
      key: "userRoles",
      className: `userRoles${hiddenColumns.userRoles}`,
      sorter: false,
    },
    {
      key: "businessesCount",
      className: `businessesCount${hiddenColumns.businessesCount}`,
      label: "Pitchcards",
    },
    {
      key: "platform",
      className: `platform${hiddenColumns.platform}`,
      sorter: false,
    },
    {
      key: "teamsCount",
      className: `teamsCount${hiddenColumns.teamsCount}`,
    },
    {
      key: "Options",
      className: "Options",
      filter: false,
      sorter: false,
      label: "",
    },
  ];

  const showToast = (
    type = toast.success,
    message = "Success",
    autoClose = 1000,
    hideProgressBar = true,
    position = toast.POSITION.TOP_CENTER
  ) => {
    toast.dismiss(); // Dismiss currently displayed toasts
    toast.clearWaitingQueue();
    toast(message, {
      type,
      autoClose,
      hideProgressBar,
      position,
    });
  };

  const updateQuery = (
    query = searchString,
    refresh = false,
    offset = getOffset
  ) => {
    if (displayDropdownItems[0] === "true" || displayDropdownItems[1] !== "") {
      setDisplayDropdownItems(["false", ""]); // this is used to close the dropdown menu
    }

    if (offset >= 0) {
      // If the offset needs to change
      setOffset(offset);
    }
    if (refresh) {
      // If the query needs to be refreshed
      setRefresh(true);
      setRefreshPagination(true);
    }
    if (query !== searchString) {
      setRefreshPagination(false);
    }

    setSearchString(query);
    setDataRecieved(false);
  };

  return (
    <div>
      <ToastContainer limit={2} autoClose={2000} />
      <UserFilters
        refreshBillableTester={refreshBillableTester}
        setRefreshBillableTester={setRefreshBillableTester}
        updateQuery={updateQuery}
        setCurrentPage={setCurrentPage}
        searchString={searchString}
        sethiddenColumns={sethiddenColumns}
        hiddenColumns={hiddenColumns}
        compactView={compactView}
        setCompactView={setCompactView}
      />
      {changeLogModalShow ? (
        <Changelog
          show={changeLogModalShow}
          onHide={() => setChangeLogModalShow(false)}
          businessId={changeLogId[0]}
          changelogData={changelogData}
          setChangelogData={setChangelogData}
          userchangelog
          businessName={changeLogId[1]}
        />
      ) : (
        []
      )}
      <hr />
      <SmartUserTable
        columns={columnCustomizations.filter(
          (value) =>
            value.className.toString().includes("Visible") ||
            value.className.toString().includes("Options")
        )}
        data={filterData}
        updateQuery={updateQuery}
        id="pitchcardTable"
        resultCount={resultCount}
        itemsPerPage={queryLimit}
        searchString={searchString}
        setSearchString={setSearchString}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setQueryLimit={setQueryLimit}
        businessStartedDirection={businessStartedDirection}
        setBusinessStartedDirection={setBusinessStartedDirection}
        changeLogModalShow={changeLogModalShow}
        setChangeLogModalShow={setChangeLogModalShow}
        changeLogId={changeLogId}
        setChangeLogId={setChangeLogId}
        displayDropdownItems={displayDropdownItems}
        setDisplayDropdownItems={setDisplayDropdownItems}
        showToast={showToast}
        compactView={compactView}
        setCompactView={setCompactView}
      />
    </div>
  );
}
export default UserTable;
