import CIcon from "@coreui/icons-react";
import { cilTrash } from "@coreui/icons";
import { CDropdownItem } from "@coreui/react";

import React, { useState } from "react";
import { deleteBusiness } from "../../../../services/BusinessService.js";
import ConfirmationDeleteModal from "../../../reusable_components/ConfirmationDeleteModal.jsx";

function DeleteBusinessItem({ data, searchString, updateQuery, showToast }) {
  const [showModal, setShowModal] = useState(false);

  const handleDelete = async () => {
    deleteBusiness(data)
      .then(() => {
        showToast("success", "Business Deleted");
      })
      .catch(() => {
        showToast("error", "Error deleting the Business");
      })
      .finally(() => {
        setShowModal(false);
        updateQuery(searchString, true);
      });
  };

  return (
    <>
      <CDropdownItem
        component="button"
        style={{ color: "red" }}
        onClick={() => {
          handleDelete();
        }}
      >
        <CIcon icon={cilTrash} className="me-2" />
        Delete Business
      </CDropdownItem>
      <ConfirmationDeleteModal
        show={showModal}
        title="Delete Business"
        body="Are you sure you want to delete this Business?"
      />
    </>
  );
}

export default DeleteBusinessItem;
