import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CButton, CCol, CHeader, CHeaderToggler, CLink } from "@coreui/react";
import CIcon from "@coreui/icons-react";

import {
  cilApplications,
  cilCarAlt,
  cilCheckAlt,
  cilLibraryBuilding,
  cilMenu,
  cilMoney,
  cilMoodGood,
  cilPaint,
  cilPeople,
  cilStar,
} from "@coreui/icons";
import AppBreadcrumb from "../breadcrumb/AppBreadcrumb.jsx";
import AppHeaderDropDownMenu from "./appheaderdropdownmenu/AppHeaderDropDownMenu.jsx";
import "../../Style.scss";

function AppHeader() {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const [selectedPath, setSelectedPath] = useState(window.location.hash);

  const isSelected = (path) =>
    selectedPath === `#/general/${path}` ? "selected" : "";
  const updateSelectedPath = () => {
    setSelectedPath(window.location.hash);
  };
  useEffect(() => {
    // Listen for hash changes
    window.addEventListener("hashchange", updateSelectedPath);

    // Clean up the listener when the component is unmounted
    return () => window.removeEventListener("hashchange", updateSelectedPath);
  }, []); // Empty dependency array means the effect will run once on mount and cleanup on unmount
  const handleClick = (path) => {
    setSelectedPath(`#/general/${path}`);
    document.location = `/#/general/${path}`;
  };

  return (
    <CHeader position="sticky" className="MainHeader AppHeader">
      <CHeaderToggler
        className="ps-1"
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
        onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
      >
        <CIcon icon={cilMenu} size="sm" />
      </CHeaderToggler>

      <AppBreadcrumb />

      <CCol className="HeaderCol">
        {[
          { icon: cilApplications, path: "category", label: "Category" },
          { icon: cilMoney, path: "discounts", label: "Discounts" },
          { icon: cilStar, path: "pitchcards", label: "Pitchcards" },
          { icon: cilLibraryBuilding, path: "products", label: "Products" },
          { icon: cilMoodGood, path: "referrals", label: "Referrals" },
          {
            icon: cilCheckAlt,
            path: "reportedreviews",
            label: "Reported Reviews",
          },
          { icon: cilCarAlt, path: "transactions", label: "Transactions" },
          { icon: cilPeople, path: "users", label: "Users" },
          { icon: cilMoodGood, path: "ep", label: "EP" },
          { icon: cilPaint, path: "reports", label: "Reports" },
        ].map(({ icon, path, label }) => (
          <CButton
            size="sm"
            width="100%"
            key={path}
            color="transparent"
            className="NavButton"
            onClick={() => handleClick(path)}
            data-path={path}
          >
            <CLink className={`nav-link ${isSelected(path)}`}>
              <CIcon icon={icon} />
              {label}
            </CLink>
          </CButton>
        ))}
      </CCol>
      <AppHeaderDropDownMenu />
    </CHeader>
  );
}
export default AppHeader;
