import React, { useEffect, useState, useCallback } from "react";
import { useDebounce } from "use-debounce";
import debounce from "lodash/debounce";
import { Allotment } from "allotment";
import { queriedTransactionInformation } from "../../components/transactionstable/database_user_query_information/QueriedTransactionInformation.jsx";
import TransactionsTable from "../../components/transactionstable/TransactionsTable.jsx";
import TransactionsPane from "./TransactionsPane.jsx";

function Transactions() {
  const [checked, setChecked] = React.useState(false);
  const [filterData, setData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [resultCount, setResultCount] = useState(0);
  const [queryLimit, setQueryLimit] = useState(25);
  const [refreshStatePagination, setRefreshPagination] = useState(true);
  const [refreshState, setRefresh] = useState(true);
  const [currentTransaction, setCurrentTransaction] = useState([]);

  const handleAdditionalContentChecked = () => {
    setChecked(!checked);
  };
  const [businessStartedDirection, setBusinessStartedDirection] = useState([
    "businessName",
    "des",
  ]);

  const [Search] = useDebounce(searchString, 0);
  const [getOffset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayDropdownItems, setDisplayDropdownItems] = useState([
    false,
    "userId",
  ]);
  const [selectedTransactionStatus, setSelectedTransactionStatus] = useState(0);

  const [hiddenColumns, sethiddenColumns] = React.useState({
    0: " Visible",
    userName: " Visible",
    amount: " Visible",
    attempts: " Visible",
    prevPaymentDate: " Visible",
    nextPaymentDate: " Visible",
    status: " Visible",
    error: " Visible",
    transactions: " hidden",
  });

  const getHiddenColumns = () => {
    const hiddenColumnsData = JSON.parse(
      localStorage.getItem("hiddenUserColumns")
    );
    if (hiddenColumnsData !== hiddenColumns) {
      sethiddenColumns(hiddenColumnsData);
    }
  };

  // create a function to save hiddenColumns in local storage
  const saveHiddenColumns = (hiddenC) => {
    localStorage.setItem("hiddenUserColumns", JSON.stringify(hiddenC));
  };
  // create a function to get hiddenColumns from local storage

  useEffect(() => {
    saveHiddenColumns();
  }, [hiddenColumns]);
  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
      setOffset(0);
    }
  }, [businessStartedDirection, searchString, queryLimit]);
  const [dataReceived, setDataReceived] = useState(false);

  async function getData() {
    await queriedTransactionInformation(
      searchString,
      getOffset,
      setResultCount,
      setData,
      businessStartedDirection,
      queryLimit,
      selectedTransactionStatus
    );
  }
  const debouncedGetData = useCallback(
    debounce(async () => {
      await getData();
    }, 300),
    [getData]
  );
  useEffect(() => {
    if (refreshStatePagination) {
      setRefreshPagination(false);
    } else {
      debouncedGetData();
    }

    setRefresh(false);
  }, [refreshState === true, Search]);

  return (
    <div className="divAllotment">
      <Allotment>
        <Allotment.Pane preferredSize={checked ? "75%" : "100%"} snap>
          <div className="divScrollable">
            <TransactionsTable
              searchString={searchString}
              setSearchString={setSearchString}
              resultCount={resultCount}
              setResultCount={setResultCount}
              queryLimit={queryLimit}
              setQueryLimit={setQueryLimit}
              refreshStatePagination={refreshStatePagination}
              setRefreshPagination={setRefreshPagination}
              refreshState={refreshState}
              setRefresh={setRefresh}
              businessStartedDirection={businessStartedDirection}
              setBusinessStartedDirection={setBusinessStartedDirection}
              getOffset={getOffset}
              setOffset={setOffset}
              Search={Search}
              setData={setData}
              filterData={filterData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              hiddenColumns={hiddenColumns}
              sethiddenColumns={sethiddenColumns}
              displayDropdownItems={displayDropdownItems}
              setDisplayDropdownItems={setDisplayDropdownItems}
              dataRecieved={dataReceived}
              setDataRecieved={setDataReceived}
              getHiddenColumns={getHiddenColumns}
              saveHiddenColumns={saveHiddenColumns}
              handleAdditionalContentChecked={handleAdditionalContentChecked}
              arrowDirection={!checked}
              selectedTransactionStatus={selectedTransactionStatus}
              setSelectedTransactionStatus={setSelectedTransactionStatus}
              setCurrentTransaction={setCurrentTransaction}
            />
          </div>
        </Allotment.Pane>
        <Allotment.Pane
          visible={checked}
          preferredSize="25%"
          style={{ display: checked ? "block" : "none" }}
        >
          <TransactionsPane currentTransaction={currentTransaction} />
        </Allotment.Pane>
      </Allotment>
    </div>
  );
}

export default Transactions;
