import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";
import { CDropdownItem } from "@coreui/react";
import React from "react";
import {
  checkVideoUrl,
  getVideoUrl,
} from "../../../../services/BusinessService.js";

function DownloadVideoItem({ data }) {
  let i = 0;

  async function downloadVideo() {
    setTimeout(async () => {
      await checkVideoUrl(data.videoFileId).then(() => {
        getVideoUrl(data.videoFileId).then((r) => {
          if (r !== "" && r !== false) {
            window.open(r, "_blank"); // Implement url verification in the future and a loading modal
            i = 0;
          } else if (i < 10) {
            i += 1;
            downloadVideo();
          }
        });
      });
    }, 3000);
  }

  return (
    <CDropdownItem component="button" onClick={downloadVideo}>
      <CIcon icon={cilCloudDownload} className="me-2" />
      Download Video
    </CDropdownItem>
  );
}

export default DownloadVideoItem;
