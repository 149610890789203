import React, { useEffect, useRef } from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { cilLockLocked, cilSettings } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

function AppHeaderDropDownMenu() {
  const fontSizeRef = useRef(12);

  const applyFontSize = (newFontSize) => {
    const tableRows = document.querySelectorAll("tr");
    tableRows.forEach((row) => {
      // eslint-disable-next-line no-param-reassign
      row.style.fontSize = `${newFontSize}px`;
    });

    // Scale elements with the icon-sm class
    const scaleFactor = newFontSize / 10;
    const iconElements = document.querySelectorAll(".icon-sm");
    iconElements.forEach((icon) => {
      // eslint-disable-next-line no-param-reassign
      icon.style.transform = `scale(${scaleFactor})`;
    });
    // Add extra margin to the surrounding elements
    const marginScaleFactor = scaleFactor * 0.3;
    const extraMarginElements = document.querySelectorAll(".extra-margin");
    extraMarginElements.forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.style.marginLeft = `${marginScaleFactor}rem`;
      // eslint-disable-next-line no-param-reassign
      element.style.marginRight = `${marginScaleFactor}rem`;
    });
  };

  useEffect(() => {
    // eslint-disable-next-line radix
    const savedFontSize = parseInt(localStorage.getItem("fontSize"));
    if (savedFontSize) {
      fontSizeRef.current = savedFontSize;
      applyFontSize(savedFontSize);
    }

    // Create a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(() => {
      applyFontSize(fontSizeRef.current);
    });

    // Set the observer to watch the entire document
    observer.observe(document, { childList: true, subtree: true });

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleFontSizeChange = (e) => {
    e.stopPropagation();
    // eslint-disable-next-line radix
    const newFontSize = parseInt(e.target.value);
    fontSizeRef.current = newFontSize;
    applyFontSize(newFontSize);
    localStorage.setItem("fontSize", newFontSize);
  };

  const handleDropdownItemClick = (e) => {
    e.stopPropagation();
  };

  const onDropdownMenuShow = () => {
    const fontSizeSlider = document.getElementById("fontSizeSlider");
    if (fontSizeSlider) {
      fontSizeSlider.value = fontSizeRef.current;
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <CDropdown
        variant="nav-item"
        style={{ display: "inline-block" }}
        onShow={onDropdownMenuShow}
      >
        <CDropdownToggle caret={false} className="noBullet">
          <CIcon icon={cilSettings} className="me-2" size="xxl" />
        </CDropdownToggle>
        <CDropdownMenu
          className="pt-0"
          style={{
            position: "absolute",
            top: "100%",
            right: 0,
            left: "auto",
            marginTop: "5px",
          }}
        >
          <CDropdownItem
            onClick={() => {
              document.body.classList.toggle("dark-theme");
              const isDarkThemeEnabled =
                document.body.classList.contains("dark-theme");
              localStorage.setItem(
                "dark-theme",
                isDarkThemeEnabled ? "true" : "false"
              );
            }}
          >
            <CIcon icon={cilSettings} className="me-2" />
            Dark Theme Toggle
          </CDropdownItem>
          <CDropdownItem>
            <div
              onClick={handleDropdownItemClick}
              onKeyDown={handleDropdownItemClick}
              className="d-flex align-items-center"
              tabIndex="0"
              role="button"
            >
              <p className="me-2 mb-0" htmlFor="fontSizeSlider">
                Font Size
              </p>
              <input
                id="fontSizeSlider"
                type="range"
                min="8"
                max="16"
                defaultValue={fontSizeRef.current}
                onChange={handleFontSizeChange}
              />
            </div>
          </CDropdownItem>
          <CDropdownItem
            onClick={() => {
              const cookies = document.cookie.split(";");

              for (let i = 0; i < cookies.length; i += 1) {
                const cookie = cookies[i].split("=");
                const name = cookie[0].trim();
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
              }
              window.location.reload();
            }}
          >
            <CIcon icon={cilLockLocked} className="me-2" />
            Logout
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </div>
  );
}

export default AppHeaderDropDownMenu;
