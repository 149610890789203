import CIcon from "@coreui/icons-react";
import { cilLockUnlocked } from "@coreui/icons";
import { CDropdownItem } from "@coreui/react";
import React from "react";

import { makeAdmin } from "../../../../../services/UserService.js";

function MakeAdmin({ data, showToast, setDisplayDropdownItems }) {
  return (
    <CDropdownItem
      component="button"
      onClick={() => {
        makeAdmin(data);
        showToast("success", "User is now an admin");
        setDisplayDropdownItems(false);
      }}
    >
      <CIcon icon={cilLockUnlocked} className="me-2" />
      Make User Admin
    </CDropdownItem>
  );
}

export default MakeAdmin;
