import keysUsed from "../exportedVariables/QueriedFilterTerms.js";
import { reportedReviewList } from "../../../services/ReportedReviewsService.js";
import stampToDate from "../../../services/TimeService.jsx";

export async function queriedReportedReviews(
  searchText,
  searchTextReason,
  offset,
  limit,
  businessStartedDirection,
  setResultCount,
  setData
) {
  const res = await reportedReviewList(
    searchText,
    searchTextReason,
    offset,
    limit,
    businessStartedDirection[0],
    businessStartedDirection[1]
  );

  setResultCount(res.count);
  const dataSend = res.records.map((record) => {
    const neededData = {};

    keysUsed.forEach((item) => {
      const businessData = record.businessCustomerReviews?.business;
      const userReviewData = record.user;

      if (businessData) {
        switch (item) {
          case "logo":
            try {
              if (businessData.businessLogoThumbnailUrl == null) {
                neededData.logo = "";
              } else {
                neededData.logo = businessData.businessLogoThumbnailUrl;
              }
            } catch (e) {
              neededData[item] = "";
            }
            break;
          case "businessName":
            try {
              if (businessData.businessName == null) {
                neededData.businessName = "";
              } else {
                neededData.businessName = businessData.businessName;
              }
            } catch (e) {
              neededData[item] = "";
            }

            break;
          case "reportedBy":
            try {
              const { firstName } = userReviewData;
              const { lastName } = userReviewData;
              const fullName = `${firstName} ${lastName}`;
              if (fullName == null) {
                neededData.reportedBy = "";
              } else {
                neededData.reportedBy = fullName;
              }
            } catch (e) {
              neededData[item] = "";
            }
            break;
          case "Date":
            try {
              if (record.createdAt == null) {
                neededData.Date = "";
              } else {
                neededData.Date = stampToDate(record.createdAt);
              }
            } catch (e) {
              neededData[item] = "";
            }
            break;
          case "reviewedBy":
            try {
              if (record.reviewedBy == null) {
                neededData.reviewedBy = "";
              } else {
                neededData.reviewedBy = record.reviewUserName;
              }
            } catch (e) {
              neededData[item] = "";
            }
            break;
          case "reason":
            try {
              neededData.reason = record.reportReviewText;
            } catch (e) {
              neededData[item] = "";
            }
            break;
          case "videoUrl":
            try {
              neededData.videoUrl = businessData.videoFileUrl;
            } catch (e) {
              neededData[item] = "";
            }
            break;
          case "id":
            try {
              neededData.id = record.id;
            } catch (e) {
              neededData[item] = "";
            }
            break;
          case "businessCustomerReviewId":
            try {
              neededData.businessCustomerReviewId =
                record.businessCustomerReviewId;
            } catch (e) {
              neededData[item] = "";
            }
            break;
          default:
            neededData[item] = "";
            break;
        }
      }
    });
    return neededData;
  });

  setData(dataSend);
}
