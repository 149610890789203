import CIcon from "@coreui/icons-react";
import { cilVideo } from "@coreui/icons";
import { CDropdownItem } from "@coreui/react";
import React from "react";

function PlayVideoItem({ data, updateQuery }) {
  async function playVideo() {
    updateQuery(data.searchString, false, true, data.videoFileUrl);
  }

  return (
    <CDropdownItem component="button" onClick={playVideo}>
      <CIcon icon={cilVideo} className="me-2" />
      Play Video
    </CDropdownItem>
  );
}

export default PlayVideoItem;
