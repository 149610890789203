// the token should be named bearerToken in the cookies
// get the bearer token

// create a function to get the bearer token

function getCookie(name) {
  const cookies = document.cookie.split(";");
  const prefix = `${name}=`;

  for (let i = 0; i < cookies.length; i += 1) {
    let cookie = cookies[i];
    cookie = cookie.trim();

    if (cookie.startsWith(prefix)) {
      return cookie.slice(prefix.length);
    }
  }

  return "";
}

export const tokenStaging = getCookie("bearerToken");
export const tokenProduction = getCookie("bearerTokenProd");

export const apiMode = "staging";

const apiProductionURL = "https://api.pitch59.com/api/";
const apiStagingURL = "https://staging-api.pitch59.com/api/";
export const apiURL = apiMode === "staging" ? apiStagingURL : apiProductionURL;
export const tokenInUse =
  apiMode === "staging" ? tokenStaging : tokenProduction;

export const getConfig = {
  headers: { Authorization: `Bearer ${tokenInUse}` },
  method: "GET",
};
export const putConfig = {
  headers: {
    Authorization: `Bearer ${tokenInUse}`,
    "Content-Type": "application/json",
  },
  method: "PUT",
};
export const postConfig = {
  headers: {
    Authorization: `Bearer ${tokenInUse}`,
    "Content-Type": "application/json",
  },
  method: "POST",
};
export const deleteConfig = {
  headers: { Authorization: `Bearer ${tokenInUse}` },
  method: "DELETE",
};

// use for staging server, once login is setup, we will no longer need the token here.
