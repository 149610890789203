const keysUsed = [
  "logo",
  "businessName",
  "reportedBy",
  "Date",
  "reviewedBy",
  "reason",
  "actions",
  "videoUrl",
  "id",
  "businessCustomerReviewId",
];

export default keysUsed;
