import "bootstrap/dist/css/bootstrap.css";
import { CTooltip } from "@coreui/react";
import "../../../../Style.scss";

/**
 * A table cell with overflow handling and optional actions.
 *
 * @param {string} textContent - The text content of the table cell.
 * @param {boolean} tooltip - Whether to show a tooltip when hovering over the table cell.
 * @param {function} showToast - Function to display a toast message when copying text to the clipboard.
 * @param {boolean} cursor - Whether to show a pointer cursor when hovering over the table cell.
 * @param {boolean} compactView - Whether to use a compact view for the table cell.
 * @param {boolean} regularView - Whether to use a regular view for the table cell.
 */
export const tdOverflow = (
  textContent,
  tooltip = true,
  showToast = false,
  cursor = false,
  compactView = true,
  regularView = false
) => {
  /**
   * Copies the table cell text to the clipboard.
   */
  async function copyDataToClipBoard() {
    if (textContent !== "" && showToast) {
      navigator.clipboard.writeText(textContent).then(() => {
        showToast("success", "Copied to clipboard");
      });
    }
  }

  return (
    <td className={compactView ? "oneLine" : "multiLine"}>
      {!regularView && textContent !== "" ? (
        <CTooltip
          content={tooltip ? "Copy to Clipboard" : ""}
          placement="top"
          trigger="hover"
          offset={[0, 5]}
        >
          <button
            type="button"
            className={cursor ? "buttonText" : ""}
            onClick={() => {
              copyDataToClipBoard().then(() => {});
            }}
          >
            {textContent}
          </button>
        </CTooltip>
      ) : (
        <div>{textContent}</div>
      )}
    </td>
  );
};
