import React, { useEffect, useState, useCallback } from "react";
import { useDebounce } from "use-debounce";

import debounce from "lodash/debounce";

import ReportedReviewsTable from "../../components/reportedreviewstable/ReportedReviewsTable.jsx";
import { queriedReportedReviews } from "../../components/reportedreviewstable/database_user_query_information/QueriedReportedReviews.jsx";

function ReportedReviews() {
  const [videoModalShow, setVideoModalShow] = React.useState(false); // Show the video for a pitchcard
  const [videoURL, setVideoURL] = React.useState(""); // Set the video url for a pitchcard
  const [filterData, setData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [resultCount, setResultCount] = useState(0);
  const [queryLimit, setQueryLimit] = useState(25);
  const [refreshStatePagination, setRefreshPagination] = useState(false);
  const [refreshState, setRefresh] = useState(true);

  const [violationType, setViolationType] = useState(null);
  const [businessStartedDirection, setBusinessStartedDirection] = useState([
    "updatedAt",
    "des",
  ]);
  const [Search] = useDebounce(searchString, 300);
  const [getOffset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
      setOffset(0);
    }
  }, [businessStartedDirection, searchString, queryLimit, violationType]);
  const [dataReceived, setDataReceived] = useState(false);
  const [columnInfo, setColumnInfo] = useState([]);
  async function getData() {
    await queriedReportedReviews(
      searchString,
      violationType === 0 ? "" : violationType,
      getOffset,
      queryLimit,
      businessStartedDirection,
      setResultCount,
      setData
    );
  }
  const debouncedGetData = useCallback(
    debounce(async () => {
      await getData();
    }, 300),
    [getData]
  );

  useEffect(() => {
    if (refreshStatePagination) {
      setRefreshPagination(false);
    } else {
      debouncedGetData();
    }

    setRefresh(false);
  }, [refreshState === true, Search]);
  useEffect(() => {
    debouncedGetData();
  }, [violationType]);

  return (
    <ReportedReviewsTable
      searchString={searchString}
      setSearchString={setSearchString}
      resultCount={resultCount}
      setResultCount={setResultCount}
      queryLimit={queryLimit}
      setQueryLimit={setQueryLimit}
      refreshStatePagination={refreshStatePagination}
      setRefreshPagination={setRefreshPagination}
      refreshState={refreshState}
      setRefresh={setRefresh}
      businessStartedDirection={businessStartedDirection}
      setBusinessStartedDirection={setBusinessStartedDirection}
      getOffset={getOffset}
      setOffset={setOffset}
      Search={Search}
      setData={setData}
      filterData={filterData}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      dataRecieved={dataReceived}
      setDataRecieved={setDataReceived}
      videoModalShow={videoModalShow}
      setVideoModalShow={setVideoModalShow}
      videoURL={videoURL}
      setVideoURL={setVideoURL}
      violationType={violationType}
      setViolationType={setViolationType}
      columnInfo={columnInfo}
      setColumnInfo={setColumnInfo}
    />
  );
}

export default ReportedReviews;
