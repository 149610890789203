import { Allotment } from "allotment";
import "./Resizable.css";
import "allotment/dist/style.css";
import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { useDebounce } from "use-debounce";
import debounce from "lodash/debounce";
import PitchcardsTable from "../../components/pitchcardtable/PitchcardsTable.jsx";
import Rightpane from "../../components/pitchcardtable/rightpane/Rightpane.jsx";
import { queriedInformation } from "../../components/pitchcardtable/databaseQueryInformation/PitchcardQueriedInformation.jsx";

function PitchcardsTab() {
  const [videoModalShow, setVideoModalShow] = React.useState(false); // Show the video for a pitchcard
  const [changeLogModalShow, setChangeLogModalShow] = React.useState(false);
  const [changeLogId, setChangeLogId] = React.useState(["", ""]); // id for 0 and businessName for 1
  const [videoURL, setVideoURL] = React.useState(""); // Set the video url for a pitchcard
  const [checked, setChecked] = React.useState(false);
  const [getInformation, setInformation] = React.useState([]);
  const [filterData, setData] = React.useState([]);
  const [changelogData, setChangelogData] = React.useState([]);
  const [filterColumns, setColumns] = React.useState([]);
  const [resultCount, setResultCount] = React.useState(0); // Set the result count for the query
  const [queryLimit, setQueryLimit] = React.useState(25); // Set the query limit for the pitchcards table
  const [businessStartedDirection, setBusinessStartedDirection] =
    React.useState(["createdAt", "des"]); // Set the sorted column and direction
  const [searchString, setsearchString] = React.useState("");
  const [allUsersSelected, setAllUsersSelected] = useState(true);
  const [refreshBillableTester, setRefreshBillableTester] = React.useState([
    // Set the Billable and Tester Filter
    false,
    true,
    true,
  ]);

  const [Search] = useDebounce(searchString, 300); // Slight delay for the query
  const [FilterString, setFilterString] = React.useState(""); // Set the filter string for the pitchcards table
  const [paymentFilterString, setPaymentFilterString] = React.useState("");
  const [refreshState, setRefresh] = React.useState(true);
  const [refreshStatePagination, setRefreshPagination] = React.useState(false);
  const [dataReceived, setDataReceived] = React.useState(false);
  // For the pagination
  const [currentPage, setCurrentPage] = React.useState(0);
  const [displayDropdownItems, setDisplayDropdownItems] = useState([
    false,
    "uuid",
  ]);
  const [getOffset, setOffset] = React.useState(0); // Set the offset for the query
  const [hiddenColumns, setHiddenColumns] = React.useState({
    0: " Visible",
    phone: " Visible",
    email: " Visible",
    Started: " Visible",
    Approved: " hidden",
    Category: " Visible",
    Type: " hidden",
    Status: " hidden",
    "Payment Frequency": " hidden",
    Billable: " hidden",
    Tester: " hidden",
  });
  const [compactView, setCompactView] = React.useState(true);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
      setOffset(0);
    }
  }, [
    refreshBillableTester,
    businessStartedDirection,
    searchString,
    paymentFilterString,
    queryLimit,
  ]);

  const handleAdditionalContentChecked = () => {
    setChecked(!checked);
  };

  async function getData() {
    await queriedInformation(
      refreshBillableTester,
      searchString,
      getOffset,
      FilterString,
      paymentFilterString,
      setResultCount,
      setData,
      businessStartedDirection,
      queryLimit
    );
  }
  const debouncedGetData = useCallback(
    debounce(async () => {
      await getData();
    }, 300),
    [getData]
  );

  useEffect(() => {
    if (refreshStatePagination) {
      setRefreshPagination(false);
    } else {
      debouncedGetData();
    }

    setRefresh(false);
  }, [refreshState === true, Search]);

  function returnPitchCardTable() {
    return (
      <PitchcardsTable
        updateInfo={setInformation}
        arrowDirection={!checked}
        handleAdditionalContentChecked={handleAdditionalContentChecked}
        businessStartedDirection={businessStartedDirection}
        setBusinessStartedDirection={setBusinessStartedDirection}
        searchString={searchString}
        setsearchString={setsearchString}
        refreshBillableTester={refreshBillableTester}
        setRefreshBillableTester={setRefreshBillableTester}
        Search={Search}
        setRefresh={setRefresh}
        refreshState={refreshState}
        refreshStatePagination={refreshStatePagination}
        setRefreshPagination={setRefreshPagination}
        videoModalShow={videoModalShow}
        setVideoModalShow={setVideoModalShow}
        videoURL={videoURL}
        setVideoURL={setVideoURL}
        getOffset={getOffset}
        setOffset={setOffset}
        FilterString={FilterString}
        setFilterString={setFilterString}
        paymentFilterString={paymentFilterString}
        setPaymentFilterString={setPaymentFilterString}
        filterData={filterData}
        setData={setData}
        resultCount={resultCount}
        setResultCount={setResultCount}
        queryLimit={queryLimit}
        setQueryLimit={setQueryLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        filterColumns={filterColumns}
        setColumns={setColumns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={setHiddenColumns}
        changeLogModalShow={changeLogModalShow}
        setChangeLogModalShow={setChangeLogModalShow}
        changeLogId={changeLogId}
        setChangeLogId={setChangeLogId}
        changelogData={changelogData}
        setChangelogData={setChangelogData}
        displayDropdownItems={displayDropdownItems}
        setDisplayDropdownItems={setDisplayDropdownItems}
        dataRecieved={dataReceived}
        setDataRecieved={setDataReceived}
        compactView={compactView}
        setCompactView={setCompactView}
        allUsersSelected={allUsersSelected}
        setAllUsersSelected={setAllUsersSelected}
      />
    );
  }

  return (
    <div className="divAllotment">
      <Allotment>
        <Allotment.Pane preferredSize={checked ? "75%" : "100%"} snap>
          <div className="divScrollable">{returnPitchCardTable()}</div>
        </Allotment.Pane>
        <Allotment.Pane
          visible={checked}
          preferredSize="25%"
          style={{ display: checked ? "block" : "none" }}
        >
          <Rightpane info={getInformation.All} />
        </Allotment.Pane>
      </Allotment>
    </div>
  );
}

export default PitchcardsTab;
