import { toast, ToastContainer } from "react-toastify";

import Multiselect from "multiselect-react-dropdown";
import { CCol, CFormCheck, CFormInput, CRow } from "@coreui/react";
import SmartReferralTable from "./smartTable/SmartReferralTable.jsx";

function ReferralTable({
  compactView,
  currentPage,
  displayDropdownItems,
  filterData,
  getOffset,
  hiddenColumns,
  queryLimit,
  resultCount,
  searchString,
  setCompactView,
  setCurrentPage,
  setDataRecieved,
  setDisplayDropdownItems,
  setOffset,
  setQueryLimit,
  setRefresh,
  setRefreshPagination,
  setSearchString,
  invitationStatus,
  setInvitationStatus,
  updateEmail,
  setUpdateEmail,
}) {
  const InvitationStatus = {
    options: [
      { name: "Invited", id: 0 },
      { name: "Joined", id: 1 },
      { name: "Processed", id: 2 },
      { name: "All", id: 3 },
    ],
  };

  function onSelectInvitation(selectedList, selectedItem) {
    setInvitationStatus(selectedItem.id !== 3 ? selectedItem.id : "");

    setCurrentPage(1);
    setRefresh(true);
    setRefreshPagination(true);
    setDataRecieved(false);
  }

  function getInvitationPlaceholder() {
    switch (invitationStatus) {
      case 1:
        return "Joined";

      case 2:
        return "Processed";

      case 0:
        return "Invited";

      default:
        return "All";
    }
  }

  function getMultiselect() {
    return (
      <Multiselect
        placeholder={getInvitationPlaceholder()}
        options={InvitationStatus.options}
        onSelect={onSelectInvitation}
        selectedValues={invitationStatus}
        displayValue="name"
        singleSelect
        showArrow
      />
    );
  }

  const columnCustomizations = [
    {
      key: "userName",
      className: `userName${hiddenColumns["0"]}`,

      sorter: false,
      label: "Referral User Name",
    },
    {
      key: "referralEmail",
      className: `email${hiddenColumns.email}`,
      sorter: false,
    },
    {
      key: "fullName",
      className: `fullName${hiddenColumns.fullName}`,
      sorter: false,
    },
    {
      key: "emailId",
      className: `emailId${hiddenColumns.emailId}`,
      sorter: false,
      label: "Referrer Email",
    },

    {
      key: "referralPhone",
      className: `referralPhone${hiddenColumns.referralPhone}`,
      sorter: false,
    },
    {
      key: "contactNumber",
      className: `contactNumber${hiddenColumns.contactNumber}`,
      sorter: false,
      label: "Referrer Phone",
    },

    {
      key: "createdAt",
      className: `createdAt${hiddenColumns.createdAt}`,

      label: "Started",
      sorter: false,
    },
    {
      key: "updatedAt",
      className: `updatedAt${hiddenColumns.updatedAt}`,
      label: "Updated",
      sorter: false,
    },

    {
      key: "invitationStatus",
      className: `invitationStatus${hiddenColumns.invitationStatus}`,
      label: "Invitation Status",
      sorter: false,
    },
    {
      key: "Actions",
      className: `Actions${hiddenColumns.Actions}`,

      label: "",
      sorter: false,
    },
  ];

  const showToast = (
    type = toast.success,
    message = "Success",
    autoClose = 1000,
    hideProgressBar = true,
    position = toast.POSITION.TOP_CENTER
  ) => {
    toast.dismiss(); // Dismiss currently displayed toasts
    toast.clearWaitingQueue();
    toast(message, {
      type,
      autoClose,
      hideProgressBar,
      position,
    });
  };

  const updateQuery = (
    // used from other child components to refresh the query.

    searchQueryString = "",
    refresh = false,
    offset = getOffset
  ) => {
    if (displayDropdownItems[0] === "true" || displayDropdownItems[1] !== "") {
      setDisplayDropdownItems(["false", ""]); // this is used to close the dropdown menu
    }

    if (offset >= 0) {
      // If the offset needs to change
      setOffset(offset);
    }
    if (refresh) {
      // If the query needs to be refreshed
      setRefresh(true);
      setRefreshPagination(true);
    }

    try {
      setSearchString(searchQueryString);
      setDataRecieved(false);
    } catch {
      // console.log("Error searching database based on your search query.");
    }
  };

  return (
    <div>
      <CRow>
        <CCol>
          <CFormInput
            delay
            type="search"
            placeholder="Search"
            value={searchString}
            onInput={(event) => {
              try {
                updateQuery(event.target.value);
              } catch (e) {
                // just ignore
              }
            }}
          />
        </CCol>
        <CCol> {getMultiselect()}</CCol>
        <CCol
          style={{
            // center items horizontally
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
          }}
        >
          <CFormCheck
            tooltipFeedback
            type="checkbox"
            defaultChecked={compactView}
            onChange={() => {
              setCompactView(!compactView);
            }}
          />
          <div
            style={{
              marginLeft: "7.5px",
            }}
          >
            Compact View
          </div>
        </CCol>
      </CRow>

      <ToastContainer limit={2} autoClose={2000} />
      <SmartReferralTable
        data={filterData}
        updateQuery={updateQuery}
        id="pitchcardTable"
        resultCount={resultCount}
        itemsPerPage={queryLimit}
        searchString={searchString}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setQueryLimit={setQueryLimit}
        displayDropdownItems={displayDropdownItems}
        setDisplayDropdownItems={setDisplayDropdownItems}
        showToast={showToast}
        compactView={compactView}
        setCompactView={setCompactView}
        columns={columnCustomizations}
        updateEmail={updateEmail}
        setUpdateEmail={setUpdateEmail}
      />
    </div>
  );
}

export default ReferralTable;
