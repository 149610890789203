import { CButton, CCol, CFormLabel, CInputGroup, CRow } from "@coreui/react";

function PromotionalDiscount({
  inputValue,
  isEditing,
  isPercent,
  setInputValue,
  setIsPercent,
}) {
  return (
    <CRow className="discount-row">
      <CCol>
        <CFormLabel className="col-form-label col-left">
          Promotional Discount
        </CFormLabel>
      </CCol>
      <CCol>
        <CRow>
          <CCol>
            <CInputGroup>
              <input
                disabled={isEditing}
                type="number"
                className="form-control"
                placeholder="Discount"
                value={inputValue || ""}
                onInput={(e) => {
                  if (!isEditing) {
                    // is percent should only allow 100% and no less than 0%
                    // is dollar sign should not allow negative numbers
                    if (isPercent) {
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      } else if (e.target.value < 0 && e.target.value !== "") {
                        e.target.value = 0;
                      }
                    } else if (e.target.value < 0 && e.target.value !== "") {
                      e.target.value = 0;
                    }
                    // only allow 2 decimal places if more than 2 decimal places are entered
                    if (e.target.value.includes(".")) {
                      if (e.target.value.split(".")[1].length > 2) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                    }

                    setInputValue(e.target.value);
                  }
                }}
              />

              <CButton
                disabled={isEditing}
                variant="outline"
                className="btnDiscount"
                style={
                  isPercent
                    ? { backgroundColor: "#321fdb", color: "white", zIndex: 0 }
                    : { backgroundColor: "white", color: "black", zIndex: 0 }
                }
                onClick={() => {
                  setIsPercent(true);
                }}
              >
                %
              </CButton>
              <CButton
                disabled={isEditing}
                variant="outline"
                className="btnDiscount"
                onClick={() => {
                  setIsPercent(false);
                }}
                style={
                  isPercent
                    ? { backgroundColor: "white", color: "black", zIndex: 0 }
                    : { backgroundColor: "#321fdb", color: "white", zIndex: 0 }
                }
              >
                $
              </CButton>
            </CInputGroup>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  );
}

export default PromotionalDiscount;
