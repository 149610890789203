import {
  apiURL,
  deleteConfig,
  getConfig,
  postConfig,
  putConfig,
} from "./ConfigService.js";

export function getDiscounts(
  limit = 50,
  offset = 0,
  searchText = "",
  sortColumn = "createdAt",
  sortType = "des"
) {
  return fetch(
    `${apiURL}products/get-discounts?limit=${limit}&offset=${offset}&searchText=${searchText}&sortColumn=${sortColumn}&sortType=${sortType}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function deleteDiscountCode(id) {
  return fetch(`${apiURL}products/${id}/code`, deleteConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function generateCode() {
  return fetch(`${apiURL}products/generate-code`, getConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

// create new code endpoint with https://staging-api.pitch59.com/api/products/create-new-code as the url
// and amountDollar, code, codeExpirationDate, freeTrialPeriod, paymentCyclesCount, type as individual parameters
export function createNewCode(
  amountDollar,
  amountPercentage,
  code,
  codeExpirationDate,
  freeTrialPeriod,
  paymentCyclesCount,
  type
) {
  if (amountDollar == null) {
    // application/json type request
    return fetch(`${apiURL}products/create-new-code`, {
      ...postConfig,

      body: JSON.stringify({
        amountPercentage,
        code,
        codeExpirationDate,
        freeTrialPeriod,
        paymentCyclesCount,
        type,
      }),
    })
      .then((response) => response.json())
      .then((res) => res.data);
  }
  return fetch(`${apiURL}products/create-new-code`, {
    ...postConfig,

    body: JSON.stringify({
      amountDollar,
      code,
      codeExpirationDate,
      freeTrialPeriod,
      paymentCyclesCount,
      type,
    }),
  })
    .then((response) => response.json())
    .then((res) => res.data);
}

export function updateCode(
  id,
  amountDollar,
  amountPercentage,
  code,
  codeExpirationDate,
  freeTrialPeriod,
  paymentCyclesCount,
  type
) {
  return fetch(`${apiURL}products/${id}/code`, {
    ...putConfig,
    body: JSON.stringify({
      amountDollar,
      amountPercentage,
      code,
      codeExpirationDate,
      freeTrialPeriod,
      paymentCyclesCount,
      type,
    }),
  })
    .then((response) => response.json())
    .then((res) => {
      //
      try {
        if (res[0].message != null) {
          return res[0].message;
        }
      } catch (e) {
        return res.message;
      }
      return "loading";
    });
}
