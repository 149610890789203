import { CSmartTable } from "@coreui/react-pro";

import SmartDiscountPagePagination from "./pagination/SmartDiscountPagePagination.jsx";

import EditDeleteButtons from "./EditDeleteButtons.jsx";

function DiscountSmartTable(props) {
  DiscountSmartTable.defaultProps = {
    columns: { Loading: {} },
    data: { Loading: {} },
    tableClassName: "sTable",
    tableHover: true,
    tableResponsive: true,
    itemsPerPage: 25,
  };

  function getSmartTable() {
    function getEditDeleteButtons(item) {
      return (
        <EditDeleteButtons
          item={item}
          setEditing={props.setEditing}
          paymentCyclesCount={props.paymentCyclesCount}
          setPaymentCyclesCount={props.setPaymentCyclesCount}
          isPercent={props.isPercent}
          setIsPercent={props.setIsPercent}
          promotionalDiscountValue={props.promotionalDiscountValue}
          setPromotionalDiscountValue={props.setPromotionalDiscountValue}
          setShowDiscountEntry={props.setShowDiscountEntry}
          showDiscountEntry={props.showDiscountEntry}
          freeTrialPeriodValue={props.freeTrialPeriodValue}
          setFreeTrialPeriodValue={props.setFreeTrialPeriodValue}
          customCodeName={props.customCodeName}
          setCustomCodeName={props.setCustomCodeName}
          initialCode={props.initialCode}
          setInitialCode={props.setInitialCode}
          startDate={props.startDate}
          setStartDate={props.setStartDate}
          idOfCode={props.idOfCode}
          setIdOfCode={props.setIdOfCode}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          showToast={props.showToast}
        />
      );
    }

    return (
      <>
        <CSmartTable
          footer
          className={props.tableClassName}
          items={props.data}
          columns={props.columns}
          itemsPerPage={props.itemsPerPage}
          columnSorter={{ external: true }}
          itemsPerPageSelect
          itemsPerPageOptions={[
            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
            32, 33, 34, 35, 36, 37, 38, 39, 40, 50, 100,
          ]}
          onItemsPerPageChange={(item) => {
            props.setQueryLimit(item);
            props.updateQuery(props.searchString, true);
          }}
          tableProps={{
            hover: props.tableHover,
            responsive: props.tableResponsive,
            small: true,
          }}
          tableFilter={{ external: true }}
          tableFilterLabel=""
          tableFilterPlaceholder="Search"
          tableFootProps={{
            placeholder: "Search",
          }}
          onTableFilterChange={(item) => {
            props.updateQuery(item);
          }}
          onSorterChange={(change) => {
            const columnState = change.state === "asc" ? "asc" : "des";
            switch (change.column) {
              case "code":
                props.setBusinessStartedDirection(["code", columnState]);

                break;
              case "admin":
                props.setBusinessStartedDirection(["admin", columnState]);
                break;
              case "discount":
                props.setBusinessStartedDirection(["discount", columnState]);
                break;
              case "paymentCyclesCount":
                props.setBusinessStartedDirection([
                  "paymentCyclesCount",
                  columnState,
                ]);
                break;
              case "freeTrialPeriod":
                props.setBusinessStartedDirection([
                  "freeTrialPeriod",
                  columnState,
                ]);
                break;
              case "codeExpirationDate":
                props.setBusinessStartedDirection([
                  "codeExpirationDate",
                  columnState,
                ]);
                break;
              case "usageCount":
                props.setBusinessStartedDirection(["usageCount", columnState]);
                break;
              case "id":
                props.setBusinessStartedDirection(["id", columnState]);
                break;
              default:
                break;
            }

            props.updateQuery(props.searchString, true);
          }}
          sorterValue={{
            column:
              props.businessStartedDirection[0] === "teams"
                ? "teamsCount"
                : props.businessStartedDirection[0],
            state: props.businessStartedDirection[1] === "asc" ? "asc" : "desc",
          }}
          scopedColumns={{
            buttons: (item) => getEditDeleteButtons(item),
          }}
        />
        <SmartDiscountPagePagination
          pageCount={Math.ceil(props.resultCount / props.itemsPerPage)}
          updateQuery={props.updateQuery}
          searchString={props.searchString}
          currentPage={props.currentPage}
          setCurrentPage={props.setCurrentPage}
          itemsPerPage={props.itemsPerPage}
        />
      </>
    );
  }

  return getSmartTable();
}

export default DiscountSmartTable;
