import CIcon from "@coreui/icons-react";
import { cilCheckAlt } from "@coreui/icons";
import { CDropdownItem } from "@coreui/react";

import React from "react";
import { approveBusinessDetail } from "../../../../services/BusinessService.js";

function ApproveBusinessItem({ data, searchString, updateQuery, showToast }) {
  async function approveBusiness() {
    await approveBusinessDetail(data)
      .then(updateQuery(searchString, true))
      .finally(() => {
        showToast("success", "Business Approved");
      });
  }

  return (
    <>
      {" "}
      <CDropdownItem
        component="button"
        onClick={() => {
          approveBusiness();
        }}
      >
        <CIcon icon={cilCheckAlt} className="me-2" />
        Approve business
      </CDropdownItem>
    </>
  );
}

export default ApproveBusinessItem;
