const keysUsed = [
  "userName",
  "createdAt",
  "email",
  "contactNumber",
  "referralCount",
  "lastOtp",
  "userRoles",
  "businessesCount",
  "platform",
  "teamsCount",
  "id",
];
export default keysUsed;
