import {
  apiURL,
  deleteConfig,
  getConfig,
  postConfig,
  putConfig,
} from "./ConfigService.js";

export function getBusinessListAdmin(
  searchText = "",
  limit = 50,
  offset = 0,
  searchStatuses = "",
  sortColumn = "createdAt",
  sortType = "des",
  paymentFrequency = ""
) {
  // toCsv = false will be added later as a parameter
  return fetch(
    `${apiURL}business/get-business-list-admin?limit=${limit}&offset=${offset}&searchText=${searchText}&searchStatuses=${searchStatuses}&paymentFrequency=${paymentFrequency}&sortColumn=${sortColumn}&sortType=${sortType}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function getBusinessListAdminBillableTester(
  searchText = "",
  limit = 50,
  offset = 0,
  searchStatuses = "",
  sortColumn = "createdAt",
  sortType = "des",
  paymentFrequency = "",
  billable = true,
  tester = true
) {
  // toCsv = false will be added later as a parameter
  return fetch(
    `${apiURL}business/get-business-list-admin?limit=${limit}&offset=${offset}&searchText=${searchText}&searchStatuses=${searchStatuses}&paymentFrequency=${paymentFrequency}&sortColumn=${sortColumn}&sortType=${sortType}&testing=${tester}&billable=${billable}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function changes(businessId, limit = 10, offset = 0, sortType = "des") {
  return fetch(
    `${apiURL}business/${businessId}/changes?limit=${limit}&offset=${offset}&sortType=${sortType}`,
    postConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function getTile(businessid) {
  return fetch(`${apiURL}business/${businessid}/tile`, putConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function getVideoUrl(videoFileId) {
  return fetch(`${apiURL}business/${videoFileId}/video-master-url`, getConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function checkVideoUrl(videoFileId) {
  return fetch(
    `${apiURL}business/${videoFileId}/request-video-master`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function changeBusinessStatusToDraft(businessId) {
  return fetch(`${apiURL}business/${businessId}/change-to-draft`, putConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function approveBusinessDetail(businessId) {
  return fetch(`${apiURL}business/${businessId}/approve-business`, getConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function rejectBusinessDetail(businessId) {
  return fetch(`${apiURL}business/${businessId}/reject-business`, postConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function deleteBusiness(businessId) {
  return fetch(`${apiURL}business/${businessId}/delete-business`, deleteConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function hideShowBusiness(businessId, status) {
  return fetch(
    `${apiURL}business/${businessId}/activate-deactivate-company-status?activeStatus=${status}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}
