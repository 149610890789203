import { CButton } from "@coreui/react";

function DiscountCreate({ submit }) {
  return (
    <CButton
      className="discount-create-btn"
      onClick={() => {
        submit();
      }}
    >
      Create
    </CButton>
  );
}

export default DiscountCreate;
