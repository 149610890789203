import CIcon from "@coreui/icons-react";
import { cilDelete } from "@coreui/icons";
import { CDropdownItem } from "@coreui/react";
import React, { useState } from "react";
import { deleteOrganization } from "../../../../../services/EmployeeportalService.js";
import ConfirmationDeleteModal from "../../../../reusable_components/ConfirmationDeleteModal.jsx";

function DeleteOrganization({ data, showToast, updateQuery, searchString }) {
  const [showModal, setShowModal] = useState(false);

  const handleDelete = () => {
    deleteOrganization(data)
      .then((r) => {
        if (r.status === 200) {
          showToast("success", "Organization deleted");
        } else {
          showToast("error", "Organization not deleted");
        }
        updateQuery(searchString, true);
      })
      .catch(() => {
        showToast("error", "Error deleting the Organization");
      })
      .finally(() => {
        setShowModal(false);
      });
  };

  return (
    <>
      <CDropdownItem
        style={{ color: "red" }}
        component="button"
        onClick={() => setShowModal(true)}
      >
        <CIcon icon={cilDelete} className="me-2" />
        Delete User
      </CDropdownItem>
      <ConfirmationDeleteModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onConfirm={handleDelete}
        title="Delete Organization"
        body="Are you sure you want to delete this Organization?"
      />
    </>
  );
}
export default DeleteOrganization;
