import { CSmartTable } from "@coreui/react-pro";
import "../../../Style.scss";

import SmartUserTablePagination from "../pagination/SmartUserTablePagination.jsx";
import ReferralCountBadge from "./badges/ReferralCountBadge.jsx";
import PlatformFilter from "./badges/PlatformFilter.jsx";
import userRoles from "./badges/UserRolesBadges.jsx";
import SettingsDropdown from "./dropdown/SettingsDropdown.jsx";
import { tdOverflow } from "./services/CellCustomizations.jsx";
import { impersonateUserRequest } from "../../../services/UserService.js";
import { LoginAsUserCell } from "./services/LoginAsUserCell.jsx";

function SmartUserTable({
  accountStatus,
  columns,
  currentPage,
  data,
  displayDropdownItems,
  id,
  itemsPerPage,
  resultCount,
  searchString,
  setBusinessStartedDirection,
  setChangeLogId,
  setChangeLogModalShow,
  setCurrentPage,
  setDisplayDropdownItems,
  setQueryLimit,
  tableClassName,
  tableHover,
  updateQuery,
  showToast,
  compactView,
}) {
  SmartUserTable.defaultProps = {
    columns: { Loading: {} },
    data: { Loading: {} },
    tableClassName: "sTable",
    tableHover: true,

    itemsPerPage: 25,
  };
  const showModal = (modelID, userName) => {
    setChangeLogId(modelID, userName);
    setChangeLogModalShow(true);
  };

  function getSmartTable() {
    function getLoginAsUserCell(item) {
      return (
        <LoginAsUserCell
          compactView={compactView}
          onClick={() => {
            impersonateUserRequest(item.id).then((res) => {
              window.open(
                `https://staging.pitch59.com/welcome?token=${res}`,
                "_blank"
              );
            });
          }}
          item={item}
        />
      );
    }

    return (
      <>
        <CSmartTable
          style={{
            lineHeight: "1.5",
            paddingTop: "0.25rem",
            paddingBottom: "0.25rem",
          }}
          footer
          className={tableClassName}
          items={data}
          columns={columns}
          itemsPerPage={itemsPerPage}
          columnSorter={{ external: true }}
          itemsPerPageSelect
          itemsPerPageOptions={[
            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
            32, 33, 34, 35, 36, 37, 38, 39, 40, 50, 100,
          ]}
          onItemsPerPageChange={(item) => {
            setQueryLimit(item);
            updateQuery(searchString, true);
          }}
          tableProps={{
            hover: tableHover,
            small: true,
          }}
          scopedColumns={{
            referralCount: (item) => ReferralCountBadge(item.referralCount),
            platform: (item) => PlatformFilter(item.platform),
            createdAt: (item) =>
              tdOverflow(
                item.createdAt,
                showModal,
                [item.id, item.userName],
                false,
                null,
                true,
                compactView
              ),
            userRoles: (item) => userRoles(item.userRoles),
            email: (item) =>
              tdOverflow(
                item.email,
                null,
                null,
                true,
                showToast,
                true,
                compactView
              ),
            contactNumber: (item) =>
              tdOverflow(
                item.contactNumber,
                null,
                null,
                true,
                showToast,
                true,
                compactView
              ),
            userName: (item) => getLoginAsUserCell(item),
            Options: (item) =>
              SettingsDropdown(
                item,
                id,
                updateQuery,
                searchString,
                accountStatus,
                displayDropdownItems,
                setDisplayDropdownItems,
                showToast
              ),
          }}
          onSorterChange={(change) => {
            const columnState = change.state === "asc" ? "asc" : "des";
            switch (change.column) {
              case "userName":
                setBusinessStartedDirection(["userName", columnState]);

                break;
              case "createdAt":
                setBusinessStartedDirection(["createdAt", columnState]);
                break;
              case "referralCount":
                setBusinessStartedDirection(["referralCount", columnState]);
                break;
              case "businessesCount":
                setBusinessStartedDirection(["pitchCards", columnState]);
                break;
              case "teamsCount":
                setBusinessStartedDirection(["teams", columnState]);
                break;
              default:
                break;
            }

            updateQuery(searchString, true);
          }}
        />
        <SmartUserTablePagination
          pageCount={Math.ceil(resultCount / itemsPerPage)}
          updateQuery={updateQuery}
          searchString={searchString}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
        />
      </>
    );
  }

  return getSmartTable();
}

export default SmartUserTable;
