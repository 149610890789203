import {
  CButton,
  CCol,
  CFormCheck,
  CFormInput,
  CRow,
  CTooltip,
} from "@coreui/react";
import Multiselect from "multiselect-react-dropdown";

import CIcon from "@coreui/icons-react";
import { cilArrowLeft, cilArrowRight } from "@coreui/icons";
import "../../../Style.scss";

function PitchcardFilters({
  arrowDirection,
  filterString,
  handleAdditionalContentChecked,
  hiddenColumns,
  paymentFilterString,
  refreshBillableTester,
  searchString,
  setCurrentPage,
  setFilterString,
  setHiddenColumns,
  setPaymentFilterString,
  setRefreshBillableTester,
  updateQuery,
  compactView,
  setCompactView,
  allUsersSelected,
  setAllUsersSelected,
}) {
  const selectedBusinessStatus = filterString.split(",");

  const filterStatus = [];
  const businessState = {
    options: [
      { name: "Active", id: 2 },
      { name: "Pending", id: 1 },
      { name: "Draft", id: 7 },
      { name: "Paused", id: 4 },
      { name: "Suspended", id: 6 },
      { name: "Rejected", id: 3 },
      { name: "Pending Video", id: 8 },
      { name: "Payment Failed", id: 9 },
    ],
  };

  const columnState = {
    options: [
      { name: "phone", id: 1 },
      { name: "email", id: 2 },
      { name: "Started", id: 3 },
      { name: "Approved", id: 4 },
      { name: "Category", id: 5 },
      { name: "Type", id: 6 },
      { name: "Status", id: 7 },
      { name: "Payment Frequency", id: 8 },
      { name: "Billable", id: 9 },
      { name: "Tester", id: 10 },
    ],
  };
  const payFrequencyState = {
    options: [
      { name: "All Payment Freq", id: 0 },
      { name: "Monthly", id: 1 },
      { name: "Annual", id: 2 },
      { name: "Lifetime", id: 3 },
      { name: "None", id: 4 },
    ],
  };
  const BillableState = {
    options: [
      { name: "All Users", id: 0 },
      { name: "Billable and Tester", id: 1 },
      { name: "Billable Only", id: 2 },
      { name: "Tester Only", id: 3 },
      { name: "None", id: 4 },
    ],
  };

  const selectedV = () => {
    if (refreshBillableTester[0] === false) {
      return 0;
    }
    if (refreshBillableTester[1] && refreshBillableTester[2]) {
      return 1;
    }
    if (refreshBillableTester[1] && refreshBillableTester[2] === false) {
      return 2;
    }
    if (
      refreshBillableTester[1] === false &&
      refreshBillableTester[2] === true
    ) {
      return 3;
    }
    if (
      refreshBillableTester[1] === false &&
      refreshBillableTester[2] === false
    ) {
      return 4;
    }
    return 0;
  }; // Set the value of the billable tester to match persistent state
  const selectedPayFreq = () => {
    if (paymentFilterString === 0) {
      return 0;
    }
    if (paymentFilterString === 1) {
      return 1;
    }
    if (paymentFilterString === 2) {
      return 2;
    }
    if (paymentFilterString === 3) {
      return 3;
    }
    if (paymentFilterString === 4) {
      return 4;
    }
    return 0;
  };

  function onSelectBusinessStatus(selectedList) {
    setCurrentPage(1);
    selectedList.map((index) => filterStatus.push(index.id));

    setFilterString(filterStatus.join(","));
    updateQuery(searchString, true);
  }

  function onRemoveBusinessStatus(selectedList) {
    setCurrentPage(1);
    filterStatus.splice(0, filterStatus.length);
    selectedList.map((index) => filterStatus.push(index.id));
    setFilterString(filterStatus.join(","));
    updateQuery(searchString, true);
  }

  function onSelectColumnChange(selectedList, selectedItem) {
    const data = selectedItem.name;
    setHiddenColumns((state) => ({
      ...state,
      [data]: " Visible",
    }));
  }

  function onRemoveColumnChange(selectedList, removedItem) {
    const data = removedItem.name;
    setHiddenColumns((state) => ({
      ...state,
      [data]: " Hidden",
    }));
  }

  function onSelectPayFrequencyChange(selectedList, selectedItem) {
    setCurrentPage(1);
    if (selectedItem.id !== 0) {
      setPaymentFilterString(selectedItem.id);
    } else {
      setPaymentFilterString("");
    }
    updateQuery(searchString, true);
  }

  function onSelectBillableTesterChange(selectedList, selectedItem) {
    setCurrentPage(1);
    switch (selectedItem.id) {
      case 0:
        setRefreshBillableTester([false, true, true]);
        setAllUsersSelected(true);
        setPaymentFilterString("");
        filterStatus.splice(0, filterStatus.length);
        setFilterString("");
        break;
      case 1:
        setRefreshBillableTester([true, true, true]);
        setAllUsersSelected(false);
        break;
      case 2:
        setRefreshBillableTester([true, true, false]);
        setAllUsersSelected(false);
        break;
      case 3:
        setRefreshBillableTester([true, false, true]);
        setAllUsersSelected(false);
        break;
      case 4:
        setRefreshBillableTester([true, false, false]);
        setAllUsersSelected(false);
        break;
      default:
        setRefreshBillableTester([false, true, true]);
        setAllUsersSelected(true);
        setPaymentFilterString("");
        filterStatus.splice(0, filterStatus.length);
        setFilterString("");
    }
    updateQuery(searchString, true);
  }

  function getColumns() {
    return Object.keys(hiddenColumns).filter(
      (key) => hiddenColumns[key] === " Visible"
    );
  }

  return (
    <CRow className="align-items-start">
      <CCol>
        <CFormInput
          delay
          type="search"
          placeholder="Search"
          value={searchString}
          onInput={(event) => {
            try {
              updateQuery(event.target.value);
            } catch (e) {
              // just ignore
            }
          }}
        />
      </CCol>
      <CCol>
        <Multiselect
          showCheckbox
          hideSelectedList
          options={columnState.options}
          selectedValues={columnState.options.filter((value) =>
            getColumns().includes(value.name)
          )}
          onSelect={onSelectColumnChange}
          onRemove={onRemoveColumnChange}
          displayValue="name"
          showArrow
          placeholder="Columns"
          className="MultiSelectColumns"
        />
      </CCol>
      <CCol>
        <Multiselect
          showCheckbox
          hideSelectedList
          options={businessState.options}
          onSelect={onSelectBusinessStatus}
          onRemove={onRemoveBusinessStatus}
          disable={allUsersSelected}
          selectedValues={businessState.options.filter((value) =>
            selectedBusinessStatus.includes(value.id.toString())
          )}
          displayValue="name"
          showArrow
          placeholder="Business Status"
          className="MultiSelectStatus"
        />
      </CCol>
      <CCol>
        <Multiselect
          options={payFrequencyState.options}
          selectedValues={[payFrequencyState.options[selectedPayFreq()]]}
          onSelect={onSelectPayFrequencyChange}
          displayValue="name"
          disable={allUsersSelected}
          singleSelect
          showArrow
          placeholder="Payment Frequency"
          className="MultiSelectPaymentFrequency"
        />
      </CCol>

      <CCol>
        <Multiselect
          singleSelect
          options={BillableState.options}
          selectedValues={[BillableState.options[selectedV()]]}
          onSelect={onSelectBillableTesterChange}
          selectionLimit={1}
          displayValue="name"
          showArrow
          placeholder="Billable Tester Filter"
          className="MultiSelectStatus"
        />
      </CCol>
      <CCol
        style={{
          // center items horizontally
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
        }}
      >
        <CFormCheck
          type="checkbox"
          className="checkboxCompact"
          defaultChecked={compactView}
          onChange={() => {
            setCompactView(!compactView);
          }}
        />
        <div
          style={{
            marginLeft: "7.5px",
          }}
        >
          Compact View
        </div>
      </CCol>
      <CCol>
        <CTooltip
          content={arrowDirection ? "Show Progress" : "Hide Progress"}
          placement="top"
          trigger="hover"
          offset={[0, 5]}
        >
          <CButton
            style={{ float: "right" }}
            onClick={() => {
              handleAdditionalContentChecked();
            }}
          >
            <CIcon icon={arrowDirection ? cilArrowLeft : cilArrowRight} />
          </CButton>
        </CTooltip>
      </CCol>
    </CRow>
  );
}

export default PitchcardFilters;
