import { CCol, CFormLabel, CRow } from "@coreui/react";

function FreeTrialPeriod({ freeTrialPeriodValue, setFreeTrialPeriodValue }) {
  return (
    <CRow className="discount-row">
      <CCol>
        <CFormLabel className="col-form-label col-left">
          Free Trial Period
        </CFormLabel>
      </CCol>
      <CCol>
        <input
          className="form-control"
          type="number"
          placeholder="Cycles before charge"
          value={freeTrialPeriodValue || ""}
          onInput={(e) => {
            // only allow whole numbers and no less than 0
            if (e.target.value < 0 && e.target.value !== "") {
              e.target.value = 0;
            }

            setFreeTrialPeriodValue(e.target.value);
          }}
        />
      </CCol>
    </CRow>
  );
}

export default FreeTrialPeriod;
