import { CButton, CCard, CFormInput, CImage } from "@coreui/react";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { login } from "../../services/LoginService.js";
import "./Login.scss";
import { apiMode } from "../../services/ConfigService.js";

function LoginForm({ setPage, setEmail }) {
  return (
    <div className="loginForm">
      <ToastContainer autoClose={3000} hideProgressBar limit={1} />
      <CCard className="CCardBorder">
        <div className="loginFormLogo">
          <CImage src="pitch-logo.png" width={200} />
        </div>

        <CFormInput
          type="email"
          placeholder="Email"
          id="email"
          className="InputFormUsername"
        />
        <CFormInput
          type="password"
          placeholder="Password"
          id="password"
          className="InputFormPassword"
        />
        <CButton
          color="primary"
          className="loginButton"
          onClick={() => {
            // call the login function in the login Service
            login(
              document.getElementById("email").value,
              document.getElementById("password").value
            ).then((response) => {
              // get the bearer token in the response and save it as a cookie
              try {
                if (apiMode === "staging") {
                  document.cookie = `bearerToken=${response.token};path=/`;
                } else if (apiMode === "production") {
                  document.cookie = `bearerTokenProd=${response.token};path=/`;
                }
                // reroute the user to the home page and refresh the page
                document.location.href = "/#/general/pitchcards";
                window.location.reload();
              } catch (error) {
                toast.error("Invalid Credentials");
              }
            });
          }}
        >
          Login
        </CButton>
        <div>
          <CButton
            className="forgotPassword"
            onClick={() => {
              if (
                document.getElementById("email").value === "" ||
                !document.getElementById("email").value.includes("@")
              ) {
                // alert here in the future to show an email address
              } else {
                setPage("forgotPassword");
                setEmail(document.getElementById("email").value);
              }
            }}
          >
            Forgot Password?
          </CButton>
        </div>
      </CCard>
    </div>
  );
}

export default LoginForm;
