import { CSmartTable } from "@coreui/react-pro";
import "../../../Style.scss";

import SmartTransactionsTablePagination from "../pagination/SmartTransactionsTablePagination.jsx";

function SmartTransactionsTable({
  columns,
  currentPage,
  data,
  itemsPerPage,
  resultCount,
  searchString,
  setBusinessStartedDirection,
  setCurrentPage,
  setQueryLimit,
  tableClassName,
  tableHover,
  tableResponsive,
  updateQuery,
  setCurrentTransaction,
}) {
  SmartTransactionsTable.defaultProps = {
    columns: { Loading: {} },
    data: { Loading: {} },
    tableClassName: "sTable",
    tableHover: true,
    tableResponsive: true,
    itemsPerPage: 25,
  };

  function errorMessage(item) {
    switch (item.error) {
      case 15: // 15 = "Not Sufficent Funds"
        return <td>Not Sufficent Funds</td>;

      case 14: // New Error
        return <td>New Error</td>;

      default:
        return <td>{item.error}</td>;
    }
  }

  function getSmartTable() {
    return (
      <>
        <CSmartTable
          clickableRows
          onRowClick={(item) => {
            setCurrentTransaction([...item.transactions]);
          }}
          style={{
            lineHeight: "1.5",
          }}
          footer
          className={tableClassName}
          items={data}
          columns={columns}
          itemsPerPage={itemsPerPage}
          columnSorter={{ external: true }}
          onSorterChange={(item) => {
            const columnState = item.state === "asc" ? "asc" : "des";

            switch (item.column) {
              case "businessName":
                setBusinessStartedDirection(["businessName", columnState]);
                updateQuery(searchString, true);
                break;
              case "userName":
                setBusinessStartedDirection(["userName", columnState]);
                updateQuery(searchString, true);
                break;
              case "amount":
                setBusinessStartedDirection(["amount", columnState]);
                updateQuery(searchString, true);
                break;
              default:
                break;
            }
          }}
          itemsPerPageSelect
          itemsPerPageOptions={[
            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
            32, 33, 34, 35, 36, 37, 38, 39, 40, 50, 100,
          ]}
          onItemsPerPageChange={(item) => {
            setQueryLimit(item);
            updateQuery(searchString, true);
          }}
          scopedColumns={{
            error: (item) => errorMessage(item),
          }}
          tableProps={{
            hover: tableHover,
            responsive: tableResponsive,
            small: true,
          }}
        />
        <SmartTransactionsTablePagination
          pageCount={Math.ceil(resultCount / itemsPerPage)}
          updateQuery={updateQuery}
          searchString={searchString}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
        />
      </>
    );
  }

  return getSmartTable();
}

export default SmartTransactionsTable;
