function stampToDate(timestamp) {
  if (timestamp !== 0 && timestamp != null) {
    const timeCreated = timestamp / 1;
    const dateOfCreationCreated = new Date(timeCreated);
    return dateOfCreationCreated.toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  }
  return "";
}

export default stampToDate;
