import { apiURL, getConfig } from "./ConfigService.js";

export function reportedReviewList(
  searchText = "",
  searchTextReason = "",
  offset = 0,
  limit = 10,
  sortColumn = "",
  sortType = ""
) {
  return fetch(
    // https://staging-api.pitch59.com/api/customer-analytics/reported-review-list?searchText=&searchTextReason=&offset=0&limit=10&sortColumn=&sortType=asc
    `${apiURL}customer-analytics/reported-review-list?searchText=${searchText}&searchTextReason=${searchTextReason}&offset=${offset}&limit=${limit}&sortColumn=${sortColumn}&sortType=${sortType}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function reportedReviewNotSpam(id) {
  return fetch(
    `${apiURL}customer-analytics/${id}/reported-review-not-spam`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function deleteReportedReview(id, businessCustomerReviewId) {
  return fetch(
    `${apiURL}customer-analytics/${id}/delete-reported-review?businessCustomerReviewId=${businessCustomerReviewId}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}
