import "bootstrap/dist/css/bootstrap.css";
import { CTooltip } from "@coreui/react";

/**
 * A table cell with overflow handling and optional actions.
 *
 * @param {string} textContent - The text content of the table cell.
 * @param {function} showChangeLog - Function to display the changelog when the table cell is clicked.
 * @param {Array} changeId - An array with two string items representing the ID of the change to show in the changelog.
 * @param {function} showToast - Function to display a toast message when copying text to the clipboard.
 * @param {boolean} cursor - Whether to show a pointer cursor when hovering over the table cell.
 * @param {boolean} compactView - Whether to use a compact view for the table cell.
 * @param {boolean} tooltip - Whether to show a tooltip when hovering over the table cell.
 * @param {boolean} regularView - Whether to use a regular view for the table cell.
 */
const cellCustomizations = (
  textContent,
  showChangeLog,
  changeId = [],
  showToast = false,
  cursor = false,
  compactView = true,
  tooltip = false,
  regularView = false
) => {
  const maxWidth = "250";
  const widthData = "250";

  /**
   * Copies the table cell text to the clipboard or shows the changelog.
   */
  async function copyDataToClipBoard() {
    if (showChangeLog && textContent !== "") {
      showChangeLog(changeId[0], changeId[1]);
    } else if (textContent !== "" && showToast) {
      navigator.clipboard.writeText(textContent).then(() => {
        showToast("success", "Copied to clipboard");
      });
    }
  }

  return (
    <td
      className={compactView ? "oneLine" : "multiLine"}
      style={{
        maxWidth: `${maxWidth}px`,
        width: widthData,
      }}
    >
      {!regularView && textContent !== "" ? (
        <CTooltip
          content={tooltip ? "Copy to Clipboard" : "View Changelog"}
          placement="top"
          trigger="hover"
          offset={[0, 5]}
        >
          <button
            type="button"
            className={cursor ? "buttonText" : ""}
            onClick={() => {
              copyDataToClipBoard().then(() => {});
            }}
          >
            {textContent}
          </button>
        </CTooltip>
      ) : (
        <div>{textContent}</div>
      )}
    </td>
  );
};
export default cellCustomizations;
