import { getResumePitchcardDetails } from "./GetResumePitchcardDetails.jsx";
import { getBusinessPitchcardDetails } from "./GetBusinessPitchcardDetails.jsx";
import { getServicePitchcardDetails } from "./GetServicePitchcardDetails.jsx";

function Rightpane({ info }) {
  const neededData = {};

  const items = info;
  const getResumePitchcardData = getResumePitchcardDetails(neededData);

  const getBusinessPitchcardData = getBusinessPitchcardDetails(neededData);
  const getServicePitchcardData = getServicePitchcardDetails(neededData);

  try {
    neededData.progress =
      items.progress != null ? `${items.progress}%` : "None";
    neededData["Full Name"] =
      items.user.firstName != null && items.user.lastName != null
        ? `${items.user.firstName} ${items.user.lastName}`
        : "Full Name";
    neededData.Email = items.email != null ? items.email : "Email";
    neededData.Phone =
      items.contactNumber != null ? items.contactNumber : "Phone";
    neededData.Address = items.address != null ? items.address : "Address";
    neededData.facebookLink =
      items.facebookLink != null ? items.facebookLink : ":Facebook Link";
    neededData.instagramLink =
      items.instagramLink != null ? items.instagramLink : ":Instagram Link";
    neededData.twitterLink =
      items.twitterLink != null ? items.twitterLink : ":Twitter Link";
    neededData.linkedinLink =
      items.linkedinLink != null ? items.linkedinLink : ":Linkedin Link";
    neededData.pinterestLink =
      items.pinterestLink != null ? items.pinterestLink : ":Pinterest Link";
    neededData.otherApplicationLink =
      items.otherApplicationLink != null
        ? items.otherApplicationLink
        : ":Other ApplicationLink";
    neededData.radius =
      items.radius != null ? `Radius: ${items.radius}` : "Advertising Radius";
    neededData.jobAddress =
      items.jobAddress != null ? items.jobAddress : ":Job Address";
    neededData.categoryNames =
      items.categoryNames != null ? items.categoryNames : ":categoryNames"; // Contains multiple fields, split later
    neededData.resumeFileId = items.resumeFileId != null ? "Resume" : ":Resume";
    neededData.videoFileUrl = items.videoFileUrl != null ? "Video" : ":Video";
    neededData.videoCoverImageFileId =
      items.videoCoverImageFileId != null
        ? items.videoCoverImageFileId
        : "none";
  } catch (e) {
    return <h4>Click on a row to view the pitchcards progress</h4>;
  }
  if (items.businessType === "resume") {
    return (
      <div className="card">
        <div className="card-body">{getResumePitchcardData()}</div>
      </div>
    );
  }
  if (items.businessType === "service") {
    return (
      <div className="card">
        <div className="card-body">{getServicePitchcardData()}</div>
      </div>
    );
  }
  return (
    <div className="card">
      <div className="card-body">{getBusinessPitchcardData()}</div>
    </div>
  );
}

export default Rightpane;
