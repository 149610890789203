import React from "react";
import PropTypes from "prop-types";
import NavItem from "./NavItem.jsx";
import NavGroup from "./NavGroup.jsx";

export function Sidebar({ items }) {
  /* const navLink = (name, icon, badge) => (
    <>
      {icon && icon}
      {name && name}
      {badge && (
        <CBadge color={badge.color} className="ms-auto">
          {badge.text}
        </CBadge>
      )}
    </>
  ); */

  return (
    // eslint-disable-next-line
    <>
      {items &&
        items.map((item, index) =>
          item.items ? NavGroup(item, index) : NavItem(item, index)
        )}
    </>
  );
}

Sidebar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
