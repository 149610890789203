import CIcon from "@coreui/icons-react";
import { cilFace, cilMoney, cilNotes } from "@coreui/icons";

import { CTooltip } from "@coreui/react";

import "../../../../Style.scss";

function UserRolesBadges(userRoles) {
  // eslint-disable-next-line
  const data = userRoles.split(":");
  return (
    <td className="oneLine">
      {data[0] === "true" ? "Admin  " : "User   "}

      <CTooltip
        content="Billing Ability"
        placement="top"
        trigger="hover"
        offset={[0, 5]}
      >
        <CIcon
          className="extra-margin"
          icon={cilMoney}
          size="sm"
          style={data[1] === "true" ? { color: "green" } : { color: "grey" }}
        />
      </CTooltip>
      <CTooltip
        content="Testing Ability"
        placement="top"
        trigger="hover"
        offset={[0, 5]}
      >
        <CIcon
          className="extra-margin"
          icon={cilNotes}
          size="sm"
          style={data[2] === "true" ? { color: "red" } : { color: "grey" }}
        />
      </CTooltip>

      <CTooltip
        content={data[3] !== "null" ? data[3] : "No referral"}
        placement="top"
        trigger="hover"
        offset={[0, 5]}
      >
        <CIcon
          className="extra-margin"
          icon={cilFace}
          size="sm"
          style={data[3] !== "null" ? { color: "teal" } : { color: "grey" }}
        />
      </CTooltip>
    </td>
  );
}

export default UserRolesBadges;
