import {
  getBusinessListAdmin,
  getBusinessListAdminBillableTester,
} from "../../../services/BusinessService.js";
import {
  businessStatusString,
  keysUsed,
  paymentFrequency,
} from "../exportedVariables/filterVariables.js";
import stampToDate from "../../../services/TimeService.jsx";

export async function queriedInformation(
  refreshBillableTester,
  queryString,
  getOffset,
  FilterString,
  paymentFilterString,
  setResultCount,
  setData,
  businessStartedDirection,
  queryLimit
) {
  let res;
  if (!refreshBillableTester[0]) {
    res = await getBusinessListAdmin(
      queryString,
      queryLimit,
      getOffset,
      FilterString,
      businessStartedDirection[0],
      businessStartedDirection[1],
      paymentFilterString
    );
  } else {
    res = await getBusinessListAdminBillableTester(
      queryString,
      queryLimit,
      getOffset,
      FilterString,
      businessStartedDirection[0],
      businessStartedDirection[1],
      paymentFilterString,
      refreshBillableTester[1],
      refreshBillableTester[2]
    );
  }
  setResultCount(res.count);
  const dataSend = res.records.map((items) => {
    const neededData = {};
    neededData.All = items;
    keysUsed.forEach((item) => {
      let email = "";
      let phoneNumber = "";
      switch (item) {
        case "user":
          try {
            neededData.fullName = `${items.user.firstName} ${items.user.lastName}`;
          } catch (e) {
            neededData.fullName = "";
          }

          break;
        case "businessStatus":
          neededData.Status = businessStatusString[items[item]];
          break;
        case "email":
          try {
            if (items.contactNumber != null) {
              phoneNumber = items.contactNumber;
            }
            if (items.email != null) {
              email = items.email;
            }
            neededData.email = email;
            neededData.phone = phoneNumber;
          } catch (e) {
            neededData.email = "";
            neededData.phone = "";
          }

          break;
        case "billable":
          try {
            neededData.Billable = "";
            if (items.user.isBillableUser) {
              neededData.Billable = "Billable";
            }
          } catch (e) {
            neededData.Billable = "";
          }

          break;
        case "tester":
          try {
            neededData.tester = "";
            if (items.user.isTesterUser) {
              neededData.tester = "tester";
            }
          } catch (e) {
            neededData.tester = "";
          }

          break;
        case "paymentFrequency":
          try {
            neededData[item] = paymentFrequency[items[item]];
          } catch (e) {
            neededData[item] = "";
          }

          break;
        case "started":
          try {
            neededData.started = stampToDate(items.createdAt);
          } catch (e) {
            neededData.started = "";
          }

          break;
        case "approved":
          try {
            neededData.approved = stampToDate(items.approveDate);
          } catch (e) {
            neededData.approved = "";
          }
          break;
        case "businessId":
          try {
            neededData[item] = items[item]; // hidden from the table
          } catch (e) {
            neededData[item] = "";
          }

          break;
        case "videoFileUrl":
          try {
            neededData[item] = items[item]; // hidden from the table
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "videoFileId":
          try {
            neededData[item] = items[item]; // hidden from the table
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "accountStatus":
          try {
            neededData[item] = items[item]; // hidden from the table
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "businessType":
          try {
            neededData.Type = items[item]; // hidden from the table
          } catch (e) {
            neededData.Type = "";
          }
          break;
        case "categoryNames":
          try {
            neededData.category = items[item]; // hidden from the table
          } catch (e) {
            neededData.category = "";
          }

          break;
        default:
          try {
            if (items[item] == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items[item];
            }
          } catch (e) {
            neededData[item] = "";
          }

          break;
      }
    });
    return neededData;
  });

  setData(dataSend);
}
