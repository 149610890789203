import CIcon from "@coreui/icons-react";
import { cilSettings } from "@coreui/icons";
import "../SmartTable.css";

import { CDropdown, CDropdownToggle } from "@coreui/react";
import PitchcardsDropDownListItems from "../../dropdownitems/PitchcardsDropDownListItems.jsx";

function DropDownOptions(
  item,
  id,
  updateQuery,
  searchString,
  hidden,
  displayDropdownItems,
  setDisplayDropdownItems,
  showToast
) {
  DropDownOptions.defaultProps = {
    dropDownItems: { Loading: {} },
    id: "none",
  };

  const handleChildClick = (event) => {
    setDisplayDropdownItems([false, item.businessId]);

    event.stopPropagation();
  };

  function getDropDownData() {
    return (
      <CDropdown
        onHide={() => {
          setDisplayDropdownItems([false, ""]);
        }}
        placement="auto"
        variant="nav-item"
        visible={displayDropdownItems[0]}
        style={{ position: "relative" }}
      >
        <CDropdownToggle className="py-0 clickable" caret={false} size="sm" />
        <div style={{ position: "absolute" }}>
          <PitchcardsDropDownListItems
            data={item}
            updateQuery={updateQuery}
            searchString={searchString}
            accountStatus={hidden}
            showToast={showToast}
          />
        </div>
      </CDropdown>
    );
  }

  function DropDownSettings() {
    return getDropDownData();
  }

  function getPitchcardDropDown() {
    return (
      <td
        className="tdOver"
        style={{
          position: "relative",
        }}
      >
        {displayDropdownItems[0] &&
        displayDropdownItems[1] === item.businessId ? (
          <DropDownSettings />
        ) : (
          []
        )}

        <CIcon
          className="scaleIcon"
          role="button"
          icon={cilSettings}
          size="sm"
          onClick={(event) => {
            handleChildClick(event);
            if (
              displayDropdownItems[0] === true &&
              displayDropdownItems[1] === item.businessId
            ) {
              return;
            }

            setDisplayDropdownItems([true, item.businessId]);
          }}
        />
      </td>
    );
  }

  try {
    if (id.toString() === "pitchcardTable") {
      return getPitchcardDropDown();
    }
    return <td />;
  } catch (error) {
    return <td />;
  }
}

export default DropDownOptions;
