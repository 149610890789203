import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilApplications,
  cilCarAlt,
  cilCheckAlt,
  cilContact,
  cilLibraryBuilding,
  cilMoney,
  cilMoodGood,
  cilPeople,
  cilSmilePlus,
  cilSpeedometer,
  cilStar,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const DropDown1Name = "general";
const NavSidebarItems = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "General",
  },
  {
    component: CNavGroup,
    name: "User Dashboard",
    icon: <CIcon icon={cilContact} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Category",
        to: `/${DropDown1Name}/category`,
        icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Discounts",
        to: `/${DropDown1Name}/discounts`,
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },

      {
        component: CNavItem,
        name: "Pitch Cards",
        to: `/${DropDown1Name}/pitchcards`,
        icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Products",
        to: `/${DropDown1Name}/products`,
        icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Referrals",
        to: `/${DropDown1Name}/referrals`,
        icon: <CIcon icon={cilMoodGood} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Reported Reviews",
        to: `/${DropDown1Name}/reportedreviews`,
        icon: <CIcon icon={cilCheckAlt} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Transactions",
        to: `/${DropDown1Name}/transactions`,
        icon: <CIcon icon={cilCarAlt} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Users",
        to: `/${DropDown1Name}/users`,
        icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Employee Portal",
        to: `/${DropDown1Name}/ep`,
        icon: <CIcon icon={cilSmilePlus} customClassName="nav-icon" />,
      },
    ],
  },
];

export default NavSidebarItems;
