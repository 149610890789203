import CIcon from "@coreui/icons-react";
import { cilCopy } from "@coreui/icons";
import { CDropdownItem } from "@coreui/react";
import React from "react";

function CopyReferralId({ data, showToast, setDisplayDropdownItems }) {
  async function copyIdToClipboard() {
    navigator.clipboard.writeText(data).then(() => {
      showToast("success", "Copied to clipboard");
    });
  }

  return (
    <CDropdownItem
      component="button"
      onClick={() => {
        copyIdToClipboard().then(() => {
          setDisplayDropdownItems([false, ""]);
        });
      }}
    >
      <CIcon icon={cilCopy} className="me-2" />
      Copy Referral ID
    </CDropdownItem>
  );
}

export default CopyReferralId;
