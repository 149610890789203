import CIcon from "@coreui/icons-react";
import { cilSettings } from "@coreui/icons";
import { CDropdown, CDropdownMenu, CDropdownToggle } from "@coreui/react";

import CopyReferralId from "./dropdownIndividual/CopyReferralId.jsx";
import CopyReferrerID from "./dropdownIndividual/CopyReferrerID.jsx";

function ReferralDropdown({
  // updateQuery,
  displayDropdownItems,
  setDisplayDropdownItems,
  showToast,
  id,
  referralId,
}) {
  return (
    <td
      className="tdOver"
      style={{
        position: "relative",
      }}
    >
      {displayDropdownItems[0] === true && displayDropdownItems[1] === id ? (
        <CDropdown
          autoClose="outside"
          onHide={() => {
            setDisplayDropdownItems([false, ""]);
          }}
          placement="auto"
          variant="nav-item"
          visible={displayDropdownItems[0] && displayDropdownItems[1] === id}
        >
          <CDropdownToggle className="py-0 clickable" caret={false} size="sm" />
          <div style={{ position: "absolute" }}>
            <CDropdownMenu
              className="pt-0"
              // eslint-disable-next-line
              modifiers={[{ name: "offset", options: { offset: [0, 5] } }]}
            >
              <CopyReferralId
                data={referralId}
                showToast={showToast}
                setDisplayDropdownItems={setDisplayDropdownItems}
              />
              <CopyReferrerID
                data={id}
                showToast={showToast}
                setDisplayDropdownItems={setDisplayDropdownItems}
              />
            </CDropdownMenu>
          </div>
        </CDropdown>
      ) : (
        []
      )}

      <CIcon
        role="button"
        icon={cilSettings}
        size="sm"
        onClick={() => {
          if (
            displayDropdownItems[0] === true &&
            displayDropdownItems[1] === id
          ) {
            setDisplayDropdownItems([false, ""]);
            return;
          }
          setDisplayDropdownItems([true, id]);
        }}
      />
    </td>
  );
}

export default ReferralDropdown;
