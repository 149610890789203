import React from "react";
import { CSmartTable } from "@coreui/react-pro";
import ChangelogOverflow from "./ChangelogOverflow.jsx";

function ChangelogTable({ columns, data }) {
  return (
    <CSmartTable
      columns={columns}
      items={data}
      pagination
      clickableRows
      columnSorter
      tableProps={{
        hover: true,
        responsive: true,

        small: true,
        width: "fixed",
      }}
      scopedColumns={{
        field: (item) => ChangelogOverflow(item.field),
        oldValue: (item) => ChangelogOverflow(item.oldValue),
        newValue: (item) => ChangelogOverflow(item.newValue),
        createdAt: (item) => ChangelogOverflow(item.createdAt),
        userName: (item) => ChangelogOverflow(item.userName),
      }}
    />
  );
}

export default ChangelogTable;
