import {
  apiURL,
  deleteConfig,
  getConfig,
  postConfig,
  putConfig,
} from "./ConfigService.js";

export function impersonateUserRequest(userId) {
  return fetch(
    `${apiURL}account/impersonate-request?userId=${userId}`,
    postConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function getCityList(userId) {
  // not tested or implemented
  return fetch(`${apiURL}users/${userId}/get-city-list`, getConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function getCity(userId, withState = false) {
  // not tested or implemented
  return fetch(
    `${apiURL}users/${userId}/get-city?withState=${withState}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function deleteCard(userId) {
  // not tested or implemented
  return fetch(`${apiURL}users/${userId}/delete-card`, deleteConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function makeAdmin(userId) {
  return fetch(`${apiURL}users/${userId}/make-admin`, postConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function deleteUser(userId) {
  return fetch(`${apiURL}users/${userId}/delete`, postConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function deleteTestUser(userId) {
  // not tested or implemented
  return fetch(`${apiURL}users/${userId}/deleteTestUser`, postConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function tester(userId, isTester) {
  // not tested or implemented
  return fetch(`${apiURL}users/${userId}/tester?value=${isTester}`, putConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function billable(userId, isBillable) {
  // not tested or implemented
  return fetch(
    `${apiURL}users/${userId}/billable?value=${isBillable}`,
    putConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function userChanges( // not tested or implemented
  userId,
  limit = 50,
  offset = 0,
  field = "",
  sortType = "des"
) {
  return fetch(
    `${apiURL}users/${userId}/changes?limit=${limit}&offset=${offset}&field=${field}&sortType=${sortType}`,
    postConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function profile(userId) {
  // not tested or implemented
  return fetch(`${apiURL}users/${userId}/profile`, getConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function updatePushTokenGetRequest(pushToken) {
  // not tested or implemented
  return fetch(`${apiURL}users/${pushToken}/update-pushtoken`, getConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function updatePushTokenPostRequest(pushToken) {
  // not tested or implemented
  return fetch(`${apiURL}users/${pushToken}/update-pushtoken`, postConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function bankAccount(bankDetailId) {
  // not tested or implemented
  return fetch(`${apiURL}users/${bankDetailId}/bank_account`, putConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function bankAccountDelete(bankDetailId) {
  // not tested or implemented
  return fetch(`${apiURL}users/${bankDetailId}/bank_account`, deleteConfig)
    .then((response) => response.json())
    .then((res) => res.data);
}

export function userListAdmin(
  searchText = "",
  limit = 50,
  offset = 0,
  sortColumn = "createdAt",
  sortType = "des",
  paymentFrequency = ""
) {
  // toCsv = false will be added later as a parameter
  return fetch(
    `${apiURL}users/user-list-admin?limit=${limit}&offset=${offset}&searchText=${searchText}&paymentFrequency=${paymentFrequency}&sortColumn=${sortColumn}&sortType=${sortType}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}

export function userListAdminTesterBillable(
  searchText = "",
  limit = 50,
  offset = 0,
  sortColumn = "createdAt",
  sortType = "des",
  paymentFrequency = "",
  toCsv = false,
  isTesterUser = false,
  isBillableUser = false
) {
  return fetch(
    `${apiURL}users/user-list-admin?limit=${limit}&offset=${offset}&searchText=${searchText}&paymentFrequency=${paymentFrequency}&sortColumn=${sortColumn}&sortType=${sortType}&toCSV=${toCsv}&isTesterUser=${isTesterUser}&isBillableUser=${isBillableUser}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}
