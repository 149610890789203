import { CBadge } from "@coreui/react-pro";
import React from "react";
import { businessPaymentFrequencyColor } from "../services/BillableColor.jsx";

function BusinessPaymentFrequencyBadge({ paymentFrequency }) {
  let text = paymentFrequency;
  try {
    text = text.toUpperCase();
  } catch {
    text = "";
  }
  return (
    <td>
      <CBadge color={businessPaymentFrequencyColor(paymentFrequency)}>
        {text.substring(0, 2)}
      </CBadge>
    </td>
  );
}

export default BusinessPaymentFrequencyBadge;
