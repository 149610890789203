import { CBadge, CTooltip } from "@coreui/react";
import "../../../../Style.scss";

function ReferralCountBadge({ invited, joined, processed }) {
  return (
    <td className="oneLine">
      <CTooltip
        content="Invited"
        placement="top"
        trigger="hover"
        offset={[0, 5]}
      >
        <CBadge color="secondary">{invited}</CBadge>
      </CTooltip>
      <CTooltip
        content="Joined"
        placement="top"
        trigger="hover"
        offset={[0, 5]}
      >
        <CBadge color="primary">{joined}</CBadge>
      </CTooltip>
      <CTooltip
        content="Pitchcards"
        placement="top"
        trigger="hover"
        offset={[0, 5]}
      >
        <CBadge color="success">{processed}</CBadge>
      </CTooltip>
    </td>
  );
}

export default ReferralCountBadge;
