import React from "react";
import { CBadge } from "@coreui/react-pro";
import { testColor } from "../services/BillableColor.jsx";

function TesterBadge({ tester: text }) {
  let tester;
  try {
    tester = text.toUpperCase();
  } catch {
    tester = "";
  }
  return (
    <td>
      <CBadge color={testColor()}>{tester[0]}</CBadge>
    </td>
  );
}

export default TesterBadge;
