import { CSmartTable } from "@coreui/react-pro";

import { CBadge } from "@coreui/react";
import SmartUserTablePagination from "../pagination/SmartUserTablePagination.jsx";
import { tdOverflow } from "./services/CellCustomizations.jsx";
import ReferralDropdown from "./dropdown/ReferralDropdown.jsx";

function SmartReferralTable({
  data,
  updateQuery,
  id,
  resultCount,
  itemsPerPage,
  searchString,
  currentPage,
  setCurrentPage,
  setQueryLimit,
  displayDropdownItems,
  setDisplayDropdownItems,
  showToast,
  compactView,
  columns,
  updateEmail,
  setUpdateEmail,
}) {
  SmartReferralTable.defaultProps = {
    columns: { Loading: {} },
    data: { Loading: {} },
    tableClassName: "sTable",
    itemsPerPage: 25,
  };

  function getSettings(referrerId, referralId, referralEmail) {
    return (
      <ReferralDropdown
        updateQuery={updateQuery}
        searchString={searchString}
        displayDropdownItems={displayDropdownItems}
        setDisplayDropdownItems={setDisplayDropdownItems}
        showToast={showToast}
        id={referrerId}
        referralId={referralId}
        referralEmail={referralEmail}
        updateEmail={updateEmail}
        setUpdateEmail={setUpdateEmail}
      />
    );
  }

  function getInvitationBanner(item) {
    return (
      <td className={compactView ? "oneLine" : "multiLine"}>
        <CBadge
          style={
            // eslint-disable-next-line no-nested-ternary
            item.invitationStatus === "Invited"
              ? { backgroundColor: "#FFC107" }
              : // eslint-disable-next-line no-nested-ternary
              item.invitationStatus === "Joined"
              ? { backgroundColor: "#28A745" }
              : // eslint-disable-next-line no-nested-ternary
              item.invitationStatus === "Processed"
              ? { backgroundColor: "#007BFF" }
              : { backgroundColor: "#DC3545" }
          }
        >
          {item.invitationStatus}
        </CBadge>
      </td>
    );
  }

  function getSmartTable() {
    return (
      <>
        <CSmartTable
          columns={columns}
          footer
          className={id}
          items={data}
          tableFilterPlaceholder="Search"
          onTableFilterChange={(item) => {
            updateQuery(item, true);
          }}
          itemsPerPage={itemsPerPage}
          columnSorter={{ external: true }}
          itemsPerPageSelect
          itemsPerPageOptions={[
            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
            32, 33, 34, 35, 36, 37, 38, 39, 40, 50, 100,
          ]}
          onItemsPerPageChange={(item) => {
            setQueryLimit(item);
            updateQuery(searchString, true);
          }}
          scopedColumns={{
            Actions: (item) =>
              getSettings(item.id, item.referralId, item.referralEmail),
            userName: (item) =>
              tdOverflow(
                item.userName,
                null,
                showToast,
                false,
                compactView,
                true
              ),
            referralEmail: (item) =>
              tdOverflow(
                item.referralEmail,
                true,
                showToast,
                true,
                compactView
              ),
            fullName: (item) =>
              tdOverflow(
                item.fullName,
                null,
                showToast,
                false,
                compactView,
                true
              ),
            emailId: (item) =>
              tdOverflow(item.emailId, true, showToast, true, compactView),
            referralPhone: (item) =>
              tdOverflow(
                item.referralPhone,
                true,
                showToast,
                true,
                compactView
              ),
            contactNumber: (item) =>
              tdOverflow(
                item.contactNumber,
                true,
                showToast,
                true,
                compactView
              ),
            createdAt: (item) =>
              tdOverflow(item.createdAt, null, false, false, compactView, true),
            updatedAt: (item) =>
              tdOverflow(item.updatedAt, null, false, false, compactView, true),
            invitationStatus: (item) => getInvitationBanner(item),
            // create a banner for the status without cellCustomizations
          }}
          tableProps={{
            small: true,
          }}
        />
        <SmartUserTablePagination
          pageCount={Math.ceil(resultCount / itemsPerPage)}
          updateQuery={updateQuery}
          searchString={searchString}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
        />
      </>
    );
  }

  return getSmartTable();
}

export default SmartReferralTable;
