const keysUsed = [
  "name",
  "businessType",
  "paymentFrequency",
  "type",
  "price",
  "referralDiscount",
  "isActive",
  "id",
];
export default keysUsed;
