import React from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { createTag, updateTag } from "../../services/CategoryService.js";

function ModalForm({ modalData, setModalData }) {
  const resetModal = () => {
    setModalData({
      isVisible: false,
      tagName: "",
      status: "Active",
      businesses: [],
      type: "Position",
      descriptors: "",
      id: 0,
    });
  };
  const handleModalChange = (e) => {
    setModalData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    setModalData((prev) => ({ ...prev, isVisible: false }));
  };

  const getStatusValue = (status) => {
    const statusMapping = {
      active: 0,
      draft: 1,
      blocked: 2,
    };

    return statusMapping[status] || 0;
  };

  const getTypeValue = (type) => {
    const typeMapping = {
      categorytag: 0,
      position: 1,
      skill: 2,
      educationalinstituion: 3,
      worktype: 4,
      benefits: 5,
      compensationtype: 6,
      industry: 7,
    };

    return typeMapping[type] || 0;
  };

  const handleTagUpdateCreate = () => {
    const timestamp = Math.floor(new Date().getTime() / 1000.0);
    if (modalData.id !== 0) {
      updateTag(
        modalData.tagName,
        getStatusValue(modalData.status),
        getTypeValue(modalData.type),
        modalData.businesses,
        modalData.descriptors,
        timestamp,
        modalData.id
      ).then(resetModal);
    } else {
      createTag(
        modalData.tagName,
        getStatusValue(modalData.status),
        getTypeValue(modalData.type),
        "",
        modalData.descriptors,
        ""
      ).then(resetModal);
    }
  };

  return (
    <Modal show={modalData.isVisible} onHide={resetModal}>
      <Modal.Body>
        <Card>
          <Card.Header>Add New Tag</Card.Header>
          <Card.Body>
            <Form onSubmit={handleModalSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Tag Name</Form.Label>
                <Form.Control
                  type="text"
                  name="tagName"
                  value={modalData.tagName}
                  onChange={handleModalChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  name="status"
                  value={modalData.status}
                  onChange={handleModalChange}
                >
                  <option value="active">Active</option>
                  <option value="draft">Draft</option>
                  <option value="blocked">Blocked</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  disabled={modalData.id !== 0}
                  style={
                    modalData.id !== 0 ? { color: "gray" } : { color: "black" }
                  }
                  name="type"
                  value={modalData.type}
                  onChange={handleModalChange}
                >
                  <option value="categorytag">Category Tag</option>
                  <option value="position">Position</option>
                  <option value="skill">Skill</option>
                  <option value="educationalinstituion">
                    Educational Institution
                  </option>
                  <option value="worktype">Work Type</option>
                  <option value="benefits">Benefits</option>
                  <option value="compensationtype">Compensation Type</option>
                  <option value="industry">Industry</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Descriptors</Form.Label>
                <Form.Control
                  type="text"
                  name="descriptors"
                  value={modalData.descriptors}
                  onChange={handleModalChange}
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                onClick={handleTagUpdateCreate}
              >
                {modalData.id !== 0 ? "Update Tag" : "Create Tag"}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}

export default ModalForm;
