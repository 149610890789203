import EditCodeBTN from "../buttons/EditCodeBTN.jsx";
import DeleteCodeBTN from "../buttons/DeleteCodeBTN.jsx";

function EditDeleteButtons({
  item,
  setEditing,
  paymentCyclesCount,
  setPaymentCyclesCount,
  isPercent,
  setIsPercent,
  promotionalDiscountValue,
  setPromotionalDiscountValue,
  setShowDiscountEntry,
  showDiscountEntry,
  freeTrialPeriodValue,
  setFreeTrialPeriodValue,
  customCodeName,
  setCustomCodeName,
  initialCode,
  setInitialCode,
  startDate,
  setStartDate,
  idOfCode,
  setIdOfCode,
  updateQuery,
  searchString,
  showToast,
}) {
  return (
    <td>
      <EditCodeBTN
        setEditing={setEditing}
        paymentCyclesCount={paymentCyclesCount}
        setPaymentCyclesCount={setPaymentCyclesCount}
        isPercent={isPercent}
        setIsPercent={setIsPercent}
        promotionalDiscountValue={promotionalDiscountValue}
        setPromotionalDiscountValue={setPromotionalDiscountValue}
        setShowDiscountEntry={setShowDiscountEntry}
        showDiscountEntry={showDiscountEntry}
        freeTrialPeriodValue={freeTrialPeriodValue}
        setFreeTrialPeriodValue={setFreeTrialPeriodValue}
        customCodeName={customCodeName}
        setCustomCodeName={setCustomCodeName}
        initialCode={initialCode}
        setInitialCode={setInitialCode}
        startDate={startDate}
        setStartDate={setStartDate}
        item={item}
        idOfCode={idOfCode}
        setIdOfCode={setIdOfCode}
      />
      <DeleteCodeBTN
        _id={item}
        updateQuery={updateQuery}
        searchString={searchString}
        showToast={showToast}
      />
    </td>
  );
}

export default EditDeleteButtons;
