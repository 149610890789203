import { CButton, CCol, CFormInput, CRow } from "@coreui/react";
import Multiselect from "multiselect-react-dropdown";
import CIcon from "@coreui/icons-react";
import { cilArrowLeft, cilArrowRight } from "@coreui/icons";

function TransactionsFilters({
  Search,
  hiddenColumns,
  searchString,
  sethiddenColumns,
  updateQuery,
  handleAdditionalContentChecked,
  arrowDirection,
  selectedTransactionStatus,
  setSelectedTransactionStatus,
}) {
  const columnState = {
    options: [
      { name: "userName", id: 1 },
      { name: "amount", id: 2 },
      { name: "attempts", id: 3 },
      { name: "prevPaymentDate", id: 4 },
      { name: "nextPaymentDate", id: 5 },
      { name: "status", id: 6 },
      { name: "error", id: 7 },
    ],
  };

  const TransactionStatusState = {
    options: [
      { name: "CREATED", id: 0 },
      { name: "PROCESSING", id: 1 },
      { name: "PROCESSED", id: 2 },
      { name: "CAPTURE", id: 3 },
      { name: "CANCELLED", id: 4 },
      { name: "ERROR", id: 5 },
      { name: "RETURN FUNDS", id: 6 },
    ],
  };

  function onSelectColumnChange(selectedList, selectedItem) {
    const data = selectedItem.name;
    sethiddenColumns((state) => ({
      ...state,
      [data]: " Visible",
    }));
  }

  function onRemoveColumnChange(selectedList, removedItem) {
    const data = removedItem.name;
    sethiddenColumns((state) => ({
      ...state,
      [data]: " Hidden",
    }));
  }

  function getColumns() {
    return Object.keys(hiddenColumns).filter(
      (key) => hiddenColumns[key] === " Visible"
    );
  }
  function onSelectTransactionStatus(selectedList, selectedItem) {
    setSelectedTransactionStatus(selectedItem.id);
    updateQuery(searchString, true);
  }

  return (
    <CRow className="align-items-start">
      <CCol>
        <CFormInput
          delay={Search}
          type="search"
          placeholder="Search"
          onInput={(event) => {
            updateQuery(event.target.value);
          }}
        />
      </CCol>
      <CCol>
        <Multiselect
          showCheckbox
          hideSelectedList
          options={columnState.options}
          selectedValues={columnState.options.filter((value) =>
            getColumns().includes(value.name)
          )}
          onSelect={onSelectColumnChange}
          onRemove={onRemoveColumnChange}
          displayValue="name"
          showArrow
          placeholder="Columns"
          className="MultiSelectColumns"
        />
      </CCol>
      <CCol>
        <Multiselect
          options={TransactionStatusState.options}
          selectedValues={TransactionStatusState.options.filter(
            (option) => option.id === selectedTransactionStatus
          )}
          onSelect={onSelectTransactionStatus}
          displayValue="name"
          singleSelect
          showArrow
          placeholder="Transaction Status"
          className="MultiSelectTransactionStatus"
        />
      </CCol>

      <CCol>
        <CButton
          style={{ float: "right" }}
          onClick={() => {
            handleAdditionalContentChecked();
          }}
        >
          <CIcon icon={arrowDirection ? cilArrowLeft : cilArrowRight} />
        </CButton>
      </CCol>
    </CRow>
  );
}

export default TransactionsFilters;
