import CIcon from "@coreui/icons-react";
import { cilDoor, cilSettings } from "@coreui/icons";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormSwitch,
} from "@coreui/react";

import CopyUserID from "./dropdownIndividual/CopyUserID.jsx";
import {
  billable,
  impersonateUserRequest,
  tester,
} from "../../../../services/UserService.js";
import DeleteUser from "./dropdownIndividual/DeleteUser.jsx";
import MakeAdmin from "./dropdownIndividual/MakeAdmin.jsx";

function SettingsDropdown(
  { id: id1, userRoles },
  id,
  updateQuery,
  searchString,
  hidden,
  displayDropdownItems,
  setDisplayDropdownItems,
  showToast
) {
  let billablePerson = userRoles.split(":")[1] === "true";
  let testerPerson = userRoles.split(":")[2] === "true";

  return (
    // This is the dropdown menu that appears when you click the settings icon
    <td
      className="tdOver"
      style={{
        position: "relative",
      }}
    >
      {displayDropdownItems[0] && displayDropdownItems[1] === id1 ? (
        <CDropdown
          autoClose="outside"
          onHide={() => {
            setDisplayDropdownItems([false, ""]);
          }}
          placement="auto"
          variant="nav-item"
          visible={displayDropdownItems[0] && displayDropdownItems[1] === id1}
        >
          <CDropdownToggle className="py-0 clickable" caret={false} size="sm" />

          <CDropdownMenu
            className="pt-0"
            // eslint-disable-next-line
            modifiers={[{ name: "offset", options: { offset: [0, 5] } }]}
          >
            <CopyUserID
              data={id1}
              showToast={showToast}
              setDisplayDropdownItems={setDisplayDropdownItems}
            />
            <MakeAdmin
              data={id1}
              showToast={showToast}
              setDisplayDropdownItems={setDisplayDropdownItems}
            />
            <CDropdownItem>
              {" "}
              <CFormSwitch // This is the switch that toggles the billable status
                onClick={() => {
                  billablePerson = !billablePerson;
                  billable(id1, billablePerson).then(() => {
                    // refresh the page
                    updateQuery(searchString, true);
                  });
                }}
                defaultChecked={billablePerson}
                label="Billing Ability"
              />
            </CDropdownItem>
            <CDropdownItem>
              {" "}
              <CFormSwitch // This is the switch that toggles the tester status
                onClick={() => {
                  testerPerson = !testerPerson;
                  tester(id1, testerPerson).then(() => {
                    // refresh the page
                    updateQuery(searchString, true);
                  });
                }}
                defaultChecked={testerPerson}
                label="Testing Ability"
              />
            </CDropdownItem>
            <CDropdownItem
              onClick={() => {
                setDisplayDropdownItems([false, ""]);
                // This is the button that allows you to login as the user
                impersonateUserRequest(id1).then((res) => {
                  window.open(
                    `https://staging.pitch59.com/sign-in?token=${res}`,
                    "_blank"
                  );
                });
              }}
            >
              {" "}
              <CIcon icon={cilDoor} className="me-2" />
              Login as User
            </CDropdownItem>
            <DeleteUser
              data={id1}
              showToast={showToast}
              updateQuery={updateQuery}
              searchString={searchString}
              setDisplayDropdownItems={setDisplayDropdownItems}
            />
          </CDropdownMenu>
        </CDropdown>
      ) : (
        []
      )}

      <CIcon
        role="button"
        icon={cilSettings}
        size="sm"
        onClick={() => {
          if (
            displayDropdownItems[0] === true &&
            displayDropdownItems[1] === id1
          ) {
            setDisplayDropdownItems([false, ""]);
            return;
          }

          setDisplayDropdownItems([true, id1]);
        }}
      />
    </td>
  );
}

export default SettingsDropdown;
