import SmartTransactionsTable from "./smartTable/SmartTransactionsTable.jsx";
import TransactionsFilters from "./filterComponents/TransactionsFilters.jsx";

function TransactionsTable({
  setRefresh,
  setRefreshPagination,
  searchString,
  setSearchString,
  setDataRecieved,
  getOffset,
  setOffset,
  displayDropdownItems,
  setDisplayDropdownItems,
  hiddenColumns,
  sethiddenColumns,
  filterData,
  resultCount,
  queryLimit,
  currentPage,
  setCurrentPage,
  setQueryLimit,
  businessStartedDirection,
  setBusinessStartedDirection,
  handleAdditionalContentChecked,
  arrowDirection,
  selectedTransactionStatus,
  setSelectedTransactionStatus,
  setCurrentTransaction,
}) {
  const columnCustomizations = [
    {
      key: "businessName",
      label: "Business Name",
      className: `businessName${hiddenColumns["0"]}`,
    },
    {
      key: "userName",
      label: "User Name",
      className: `userName${hiddenColumns.userName}`,
    },
    {
      key: "amount",
      label: "Amount",
      className: `amount${hiddenColumns.amount}`,
    },
    {
      key: "attempts",
      label: "Attempts",
      className: `attempts${hiddenColumns.attempts}`,
      sorter: false,
    },
    {
      key: "prevPaymentDate",
      label: "Last Payment Date",
      className: `prevPaymentDate${hiddenColumns.prevPaymentDate}`,
      sorter: false,
    },
    {
      key: "nextPaymentDate",
      label: "Next Payment Date",
      className: `nextPaymentDate${hiddenColumns.nextPaymentDate}`,
      sorter: false,
    },
    {
      key: "status",
      label: "Transaction Status",
      className: `status${hiddenColumns.status}`,
      sorter: false,
    },
    {
      key: "error",
      label: "Failed Type",
      className: `error${hiddenColumns.error}`,
      sorter: false,
    },
    {
      key: "transactions",
      label: "",
      className: ` hidden`,
      sorter: false,
    },
  ];

  const updateQuery = (
    query = searchString,
    refresh = false,
    offset = getOffset
  ) => {
    if (displayDropdownItems[0] === "true" || displayDropdownItems[1] !== "") {
      setDisplayDropdownItems(["false", ""]); // this is used to close the dropdown menu
    }

    if (offset >= 0) {
      // If the offset needs to change
      setOffset(offset);
    }
    if (refresh) {
      // If the query needs to be refreshed
      setRefresh(true);
      setRefreshPagination(true);
    }
    if (query !== searchString) {
      setRefreshPagination(false);
    }

    setSearchString(query);
    setDataRecieved(false);
  };

  return (
    <div>
      <TransactionsFilters
        updateQuery={updateQuery}
        setCurrentPage={setCurrentPage}
        searchString={searchString}
        sethiddenColumns={sethiddenColumns}
        hiddenColumns={hiddenColumns}
        handleAdditionalContentChecked={handleAdditionalContentChecked}
        arrowDirection={arrowDirection}
        selectedTransactionStatus={selectedTransactionStatus}
        setSelectedTransactionStatus={setSelectedTransactionStatus}
      />
      <hr />
      <SmartTransactionsTable
        columns={columnCustomizations.filter(
          (value) =>
            value.className.toString().includes("Visible") ||
            value.className.toString().includes("Options")
        )}
        data={filterData}
        updateQuery={updateQuery}
        id="pitchcardTable"
        resultCount={resultCount}
        itemsPerPage={queryLimit}
        searchString={searchString}
        setSearchString={setSearchString}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setQueryLimit={setQueryLimit}
        businessStartedDirection={businessStartedDirection}
        setBusinessStartedDirection={setBusinessStartedDirection}
        displayDropdownItems={displayDropdownItems}
        setDisplayDropdownItems={setDisplayDropdownItems}
        setCurrentTransaction={setCurrentTransaction}
      />
    </div>
  );
}
export default TransactionsTable;
