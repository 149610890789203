import "bootstrap/dist/css/bootstrap.css";
import { CTooltip } from "@coreui/react";
import "../../../../Style.scss";

export const tdOverflow = (
  textContent,
  showChangeLog,
  changeId,
  tooltip = true,
  showToast = false,
  cursor = false,
  compactView = true,
  regularView = false
) => {
  // const [show, setShow] = useState(false);

  async function copyDataToClipBoard() {
    if (
      showChangeLog !== undefined &&
      showChangeLog !== null &&
      textContent !== ""
    ) {
      showChangeLog(changeId[0], changeId[1]);
    } else if (textContent === "" || showToast === undefined) {
      // do nothing
    } else {
      navigator.clipboard.writeText(textContent).then(() => {
        try {
          if (showToast !== undefined) {
            showToast("success", "Copied to clipboard");
          }
        } catch (e) {
          // do nothing
        }
      });
    }
  }

  return (
    <td className={compactView ? "oneLine" : "multiLine"}>
      {!regularView && textContent !== "" ? (
        <CTooltip
          content={tooltip ? "Copy to Clipboard" : "View Changelog"}
          placement="top"
          trigger="hover"
          offset={[0, 5]}
        >
          <button
            type="button"
            className={cursor ? "buttonText" : ""}
            onClick={() => {
              copyDataToClipBoard().then(() => {});
            }}
          >
            {textContent}
          </button>
        </CTooltip>
      ) : (
        <div>{textContent}</div>
      )}
    </td>
  );
};
