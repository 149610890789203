import CIcon from "@coreui/icons-react";
import { cilXCircle } from "@coreui/icons";
import { CDropdownItem } from "@coreui/react";
import React from "react";
import { toast } from "react-toastify";
import { rejectBusinessDetail } from "../../../../services/BusinessService.js";

function RejectBusinessItem({ data, searchString, updateQuery, showToast }) {
  async function rejectBusiness() {
    await rejectBusinessDetail(data)
      .then(updateQuery(searchString, true))
      .then(() => {
        showToast(toast.success, "Business Deleted");
      });
  }

  return (
    <CDropdownItem component="button" onClick={rejectBusiness}>
      <CIcon icon={cilXCircle} className="me-2" />
      Reject Business
    </CDropdownItem>
  );
}

export default RejectBusinessItem;
