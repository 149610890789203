import { toast, ToastContainer } from "react-toastify";
import { CButton, CCol } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";
import SmartEmployeeTable from "./smartTable/SmartEmployeeTable.jsx";

import EmployeeFilters from "./filterComponents/EmployeeFilters.jsx";
import Changelog from "./changelog/changelog.jsx";
import { getOrganizationList } from "../../services/EmployeeportalService.js";

function EmployeeTable({
  hiddenColumns,
  searchString,
  getOffset,
  setOffset,
  setCurrentPage,
  displayDropdownItems,
  setDisplayDropdownItems,
  refreshBillableTester,
  setRefreshBillableTester,
  compactView,
  setCompactView,
  changeLogModalShow,
  setChangeLogModalShow,
  changeLogId,
  setChangeLogId,
  changelogData,
  setChangelogData,
  filterData,
  resultCount,
  queryLimit,
  currentPage,
  businessStartedDirection,
  setBusinessStartedDirection,
  setSearchString,
  setQueryLimit,
  sethiddenColumns,
  setDataRecieved,
  setRefreshPagination,
  setRefresh,
}) {
  const handleDownloadCSV = async () => {
    try {
      const csvData = await getOrganizationList(
        1000000,
        0,
        searchString,
        "",
        "",
        null,
        null,
        true
      );
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor element for downloading the CSV file
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.download = "organization_list.csv";
      tempLink.style.display = "none";

      // Append the anchor element to the DOM, click it, and remove it
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    } catch (error) {
      // console.log(error);
    }
  };

  const columnCustomizations = [
    {
      key: "name",
      className: `name${hiddenColumns.name}`,
      label: "Name",
    },
    {
      key: "count",
      className: `count${hiddenColumns.count}`,
      label: "User Count",
      sorter: false,
    },
    {
      key: "Pitchcards",
      className: `Pitchcards${hiddenColumns.Pitchcards}`,
      label: "Pitchcards",
      sorter: false,
    },
    {
      key: "businessCount",
      className: `businessCount${hiddenColumns.businessCount}`,
      label: "Business Count",
      sorter: false,
    },
    {
      key: "ownerName",
      className: `ownerName${hiddenColumns.ownerName}`,
      label: "Owner Name",
    },
    {
      key: "Email",
      className: `Email${hiddenColumns.Email}`,
      label: "Email",
      sorter: false,
    },
    {
      key: "industries",
      className: `industries${hiddenColumns.industries}`,
      label: "Industries",
      sorter: false,
    },
    {
      key: "createdAt",
      className: `createdAt${hiddenColumns.createdAt}`,
      label: "Created At",
      sorter: false,
    },
    {
      key: "actions",
      className: `actions${hiddenColumns.actions}`,
      sorter: false,
      label: "",
    },
  ];
  const showToast = (
    type = toast.success,
    message = "Success",
    autoClose = 1000,
    hideProgressBar = true,
    position = toast.POSITION.TOP_CENTER
  ) => {
    toast.dismiss(); // Dismiss currently displayed toasts
    toast.clearWaitingQueue();
    toast(message, {
      type,
      autoClose,
      hideProgressBar,
      position,
    });
  };

  const updateQuery = (
    // used from other child components to refresh the query.
    searchStringQuery = searchString,
    refresh = false,
    offset = getOffset
  ) => {
    if (displayDropdownItems[0] === "true" || displayDropdownItems[1] !== "") {
      setDisplayDropdownItems(["false", ""]); // this is used to close the dropdown menu
    }

    if (offset >= 0) {
      // If the offset needs to change
      setOffset(offset);
    }
    if (refresh) {
      // If the query needs to be refreshed
      setRefresh(true);
      setRefreshPagination(true);
    }
    if (searchStringQuery !== searchString) {
      setRefreshPagination(false);
    }
    try {
      setSearchString(searchStringQuery);
      setDataRecieved(false);
    } catch {
      // do nothing
    }
  };

  return (
    <div>
      <ToastContainer limit={2} autoClose={2000} />

      <CCol>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 style={{ marginRight: "10px" }}>Organization List</h3>
          <CButton onClick={handleDownloadCSV}>
            <CIcon icon={cilCloudDownload} />
          </CButton>
        </div>
      </CCol>

      <EmployeeFilters
        refreshBillableTester={refreshBillableTester}
        setRefreshBillableTester={setRefreshBillableTester}
        updateQuery={updateQuery}
        setCurrentPage={setCurrentPage}
        searchString={searchString}
        sethiddenColumns={sethiddenColumns}
        hiddenColumns={hiddenColumns}
        compactView={compactView}
        setCompactView={setCompactView}
      />
      {changeLogModalShow ? (
        <Changelog
          show={changeLogModalShow}
          onHide={() => setChangeLogModalShow(false)}
          businessId={changeLogId[0]}
          changelogData={changelogData}
          setChangelogData={setChangelogData}
          userchangelog
          userName={changeLogId[1]}
        />
      ) : (
        []
      )}
      <hr />
      <SmartEmployeeTable
        columns={columnCustomizations.filter(
          (value) =>
            value.className.toString().includes("Visible") ||
            value.className.toString().includes("Options")
        )}
        data={filterData}
        updateQuery={updateQuery}
        id="pitchcardTable"
        resultCount={resultCount}
        itemsPerPage={queryLimit}
        searchString={searchString}
        setSearchString={setSearchString}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setQueryLimit={setQueryLimit}
        businessStartedDirection={businessStartedDirection}
        setBusinessStartedDirection={setBusinessStartedDirection}
        changeLogId={changeLogId}
        setChangeLogId={setChangeLogId}
        displayDropdownItems={displayDropdownItems}
        setDisplayDropdownItems={setDisplayDropdownItems}
        showToast={showToast}
        compactView={compactView}
        setCompactView={setCompactView}
        changeLogModalShow={changeLogModalShow}
        setChangeLogModalShow={setChangeLogModalShow}
      />
    </div>
  );
}
export default EmployeeTable;
