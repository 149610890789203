import keysUsed from "../exportedVariables/QueriedKeysUsed.js";

import { getAll } from "../../../services/TransactionService.js";
import stampToDate from "../../../services/TimeService.jsx";

export async function queriedTransactionInformation(
  queryString,
  getOffset,
  setResultCount,
  setData,
  businessStartedDirection,
  queryLimit,
  selectedTransactionStatus
) {
  function getTransactionStatus(statusId) {
    const TransactionStatusState = {
      options: [
        { name: "CREATED", id: 0 },
        { name: "PROCESSING", id: 1 },
        { name: "PROCESSED", id: 2 },
        { name: "CAPTURE", id: 3 },
        { name: "CANCELLED", id: 4 },
        { name: "ERROR", id: 5 },
        { name: "RETURN FUNDS", id: 6 },
      ],
    };

    const status = TransactionStatusState.options.find(
      (option) => option.id === statusId
    );
    return status ? status.name : "";
  }
  const res = await getAll(
    queryLimit,
    getOffset,
    businessStartedDirection[0],
    businessStartedDirection[1],
    queryString,
    "",
    "",
    selectedTransactionStatus
  );

  setResultCount(res.count);
  const dataSend = res.records.map((items) => {
    const neededData = {};
    keysUsed.forEach((item) => {
      switch (item) {
        case "businessName":
          try {
            neededData[item] = items.businessName;
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "userName":
          try {
            neededData[item] = items.userName;
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "amount":
          try {
            neededData[item] = items.amount;
          } catch (e) {
            neededData[item] = "";
          }
          break;

        case "attempts":
          try {
            neededData[item] = items.transactions[0].attempts;
          } catch (e) {
            neededData[item] = "";
          }
          break;

        case "prevPaymentDate":
          try {
            neededData[item] = stampToDate(items.prevPaymentDate);
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "nextPaymentDate":
          try {
            neededData[item] = stampToDate(items.nextPaymentDate);
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "status":
          try {
            neededData[item] = getTransactionStatus(selectedTransactionStatus);
          } catch (e) {
            neededData[item] = "";
          }
          break;
        case "error":
          try {
            if (items.transactions[0].error == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items.transactions[0].error;
            }
          } catch (e) {
            neededData[item] = "";
          }

          break;
        case "transactions":
          try {
            neededData[item] = items.transactions;
          } catch (e) {
            neededData[item] = "";
          }
          break;
        default:
          try {
            if (items[item] == null) {
              neededData[item] = "";
            } else {
              neededData[item] = items[item];
            }
          } catch (e) {
            neededData[item] = "";
          }

          break;
      }
    });

    return neededData;
  });

  setData(dataSend);
}
