import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter } from "react-router-dom";
import NavSidebar from "./components/navigation/NavSidebar.jsx";
import AppContent from "./AppContent.jsx";
import "./Style.scss";
import AppHeader from "./components/header/AppHeader.jsx";
import LoginPage from "./views/login/Login.jsx";

// create a logged in state
// if the user is logged in then show the app
// if the user is not logged in then show the login page

function checkLoggedIn() {
  const cookieData = document.cookie;
  // decode the cookie data
  const decodedCookie = decodeURIComponent(cookieData);
  // split the cookie data into an array
  const cookieArray = decodedCookie.split(";");
  // loop through the array
  for (let i = 0; i < cookieArray.length; i += 1) {
    // if the cookie name is bearerToken
    if (
      cookieArray[i].includes("bearerToken") ||
      cookieArray[i].includes("bearerTokenProd")
    ) {
      // return the cookie value
      return true;
    }
  }
  return false;
}
function checkTheme() {
  // this  localStorage.setItem("dark-theme", isDarkThemeEnabled ? "true" : "false"); was added to the dark theme toggle, check if its true or false
  const isDarkThemeEnabled = localStorage.getItem("dark-theme");
  // if the dark theme is enabled then add the dark-theme class to the body
  if (isDarkThemeEnabled === "true") {
    document.body.classList.add("dark-theme");
  }
}

function App() {
  checkTheme();
  if (checkLoggedIn()) {
    return (
      <div className="App">
        <HashRouter>
          <NavSidebar />
          <div className="wrapper">
            <AppHeader title="" />
            <AppContent setTitle="setTitle" />
          </div>
        </HashRouter>
      </div>
    );
  }
  // route the url to login
  document.location.href = "/#/login";
  return (
    <div>
      <LoginPage />
    </div>
  );
}

export default App;
