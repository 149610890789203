import CIcon from "@coreui/icons-react";
import { cilHighligt } from "@coreui/icons";
import { CDropdownItem } from "@coreui/react";
import React from "react";
import { hideShowBusiness } from "../../../../services/BusinessService.js";

function HideItem({ data, hidden, searchString, updateQuery }) {
  async function hide() {
    await hideShowBusiness(data, !hidden).then(updateQuery(searchString, true));
  }

  return (
    <CDropdownItem component="button" onClick={hide}>
      <CIcon icon={cilHighligt} className="me-2" />
      Hide
    </CDropdownItem>
  );
}

export default HideItem;
