import { toast, ToastContainer } from "react-toastify";
import SmartTable from "./smarttable/smartTable.jsx";

import VideoPlayer from "../videoplayer/VideoPlayer.jsx";

import PitchcardFilters from "./filterComponents/PitchcardFilters.jsx";
import Changelog from "./changelog/Changelog.jsx";

function PitchcardsTable({
  FilterString,
  arrowDirection,
  businessStartedDirection,
  changeLogId,
  changeLogModalShow,
  changelogData,
  currentPage,
  displayDropdownItems,
  filterColumns,
  filterData,
  getOffset,
  handleAdditionalContentChecked,
  hiddenColumns,
  paymentFilterString,
  queryLimit,
  refreshBillableTester,
  resultCount,
  searchString,
  setBusinessStartedDirection,
  setChangeLogId,
  setChangeLogModalShow,
  setChangelogData,
  setCurrentPage,
  setDataRecieved,
  setDisplayDropdownItems,
  setFilterColumns,
  setFilterString,
  setHiddenColumns,
  setOffset,
  setPaymentFilterString,
  setQueryLimit,
  setRefresh,
  setRefreshBillableTester,
  setRefreshPagination,
  setVideoModalShow,
  setVideoURL,
  setsearchString,
  updateInfo,
  videoModalShow,
  videoURL,
  compactView,
  setCompactView,
  allUsersSelected,
  setAllUsersSelected,
}) {
  const columnCustomizations = [
    // These are required for column names with css customizations
    {
      key: "businessName",
      className: `businessName${hiddenColumns["0"]}`,
      label: "Organization",
    },
    {
      key: "fullName",
      className: `fullName${hiddenColumns["0"]}`,
      sorter: false,
      label: "Name",
    },

    {
      key: "phone",
      className: `Phone${hiddenColumns.phone}`,
      sorter: false,
      label: "phone",
    },
    {
      key: "email",
      className: `email${hiddenColumns.email}`,
      sorter: false,
      label: "email",
    },
    {
      key: "started",
      className: `started${hiddenColumns.Started}`,
      label: "started",
    },
    {
      key: "approved",
      className: `approved${hiddenColumns.Approved}`,
      sorter: false,
      label: "approved",
    },
    {
      key: "category",
      className: `category${hiddenColumns.Category}`,
      sorter: false,
      label: "category",
    },
    {
      key: "Type",
      className: `Type${hiddenColumns.Type}`,
      sorter: false,
      label: "Type",
    },
    {
      key: "Status",
      className: `Status${hiddenColumns.Status}`,
      filter: false,
      sorter: false,
      label: "Status",
    },

    {
      key: "paymentFrequency",
      className: `paymentFrequency${hiddenColumns["Payment Frequency"]}`,
      filter: false,
      sorter: false,
      label: "Pay Freq",
    },
    {
      key: "Billable",
      className: `Billable${hiddenColumns.Billable}`,
      filter: false,
      sorter: false,
    },
    {
      key: "tester",
      className: `tester${hiddenColumns.Tester}`,
      filter: false,
      sorter: false,
    },
    {
      key: "Options",
      className: "Options",
      filter: false,
      sorter: false,
      label: "",
    },
  ];

  const showVideoModal = (url) => {
    setVideoURL(url);
    setVideoModalShow(true);
  };

  const updateQuery = (
    newSearchString = searchString,
    refresh = false,
    showVideo = false,
    url = "",
    offset = getOffset
  ) => {
    if (displayDropdownItems[0] === "true" || displayDropdownItems[1] !== "") {
      setDisplayDropdownItems(["false", ""]); // this is used to close the dropdown menu
    }

    if (showVideo) {
      // Show video modal
      showVideoModal(url);
    } else {
      if (offset >= 0) {
        // If the offset is valid
        setOffset(offset);
      }
      if (refresh) {
        // If the query needs to be refreshed
        setRefreshPagination(true);
        setRefresh(true);
      }

      setsearchString(newSearchString, true);
      setDataRecieved(false);
    }
  };
  const showToast = (
    type = toast.success,
    message = "Success",
    autoClose = 1000,
    hideProgressBar = true,
    position = toast.POSITION.TOP_CENTER
  ) => {
    toast.dismiss(); // Dismiss currently displayed toasts
    toast.clearWaitingQueue();
    toast(message, {
      type,
      autoClose,
      hideProgressBar,
      position,
    });
  };

  return (
    <div>
      <ToastContainer limit={2} autoClose={2000} />
      <PitchcardFilters
        updateQuery={updateQuery}
        setFilterString={setFilterString}
        filterString={FilterString}
        setPaymentFilterString={setPaymentFilterString}
        paymentFilterString={paymentFilterString}
        searchString={searchString}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={setHiddenColumns}
        setRefreshBillableTester={setRefreshBillableTester}
        refreshBillableTester={refreshBillableTester}
        handleAdditionalContentChecked={handleAdditionalContentChecked}
        arrowDirection={arrowDirection}
        setCurrentPage={setCurrentPage}
        filterColumns={filterColumns}
        setFilterColumns={setFilterColumns}
        compactView={compactView}
        setCompactView={setCompactView}
        allUsersSelected={allUsersSelected}
        setAllUsersSelected={setAllUsersSelected}
      />
      <hr />

      {videoModalShow ? (
        <VideoPlayer
          url={videoURL}
          show={videoModalShow}
          onHide={() => setVideoModalShow(false)}
        />
      ) : (
        []
      )}
      {changeLogModalShow ? (
        <Changelog
          show={changeLogModalShow}
          onHide={() => setChangeLogModalShow(false)}
          businessId={changeLogId[0]}
          changelogData={changelogData}
          setChangelogData={setChangelogData}
          businessName={changeLogId[1]}
        />
      ) : (
        []
      )}
      <SmartTable
        data={filterData}
        searchString={searchString}
        columns={columnCustomizations.filter(
          (value) =>
            value.className.toString().includes("Visible") ||
            value.className.toString().includes("Options")
        )}
        updateQuery={updateQuery}
        id="pitchcardTable"
        resultCount={resultCount}
        itemsPerPage={queryLimit}
        updatePane={updateInfo}
        setQueryLimit={setQueryLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        businessStartedDirection={businessStartedDirection}
        setChangeLogModalShow={setChangeLogModalShow}
        changeLogModalShow={changeLogModalShow}
        changeLogId={changeLogId}
        setChangeLogId={setChangeLogId}
        setBusinessStartedDirection={setBusinessStartedDirection}
        displayDropdownItems={displayDropdownItems}
        setDisplayDropdownItems={setDisplayDropdownItems}
        showToast={showToast}
        compactView={compactView}
        arrowDirection={arrowDirection}
      />
    </div>
  );
}

export default PitchcardsTable;
