const keysUsed = [
  "name",
  "status",
  "type",
  "businesses",
  "descriptors",
  "updatedAt",
  "actions",
  "id",
];
export default keysUsed;
