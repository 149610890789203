import CIcon from "@coreui/icons-react";
import { cilDelete } from "@coreui/icons";
import { CDropdownItem } from "@coreui/react";
import React, { useState } from "react";
import { deleteUser } from "../../../../../services/UserService.js";
import ConfirmationDeleteModal from "../../../../reusable_components/ConfirmationDeleteModal.jsx";

function DeleteUser({
  data,
  showToast,
  updateQuery,
  searchString,
  setDisplayDropdownItems,
}) {
  const [showModal, setShowModal] = useState(false);

  const handleDelete = () => {
    deleteUser(data)
      .then(() => {
        showToast("success", "User deleted");
      })
      .catch(() => {
        showToast("error", "Error deleting the User");
      })
      .finally(() => {
        setShowModal(false);
        updateQuery(searchString, true);
        setDisplayDropdownItems(false);
      });
  };

  return (
    <>
      <CDropdownItem
        style={{ color: "red" }}
        component="button"
        onClick={() => setShowModal(true)}
      >
        <CIcon icon={cilDelete} className="me-2" />
        Delete User
      </CDropdownItem>
      <ConfirmationDeleteModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onConfirm={handleDelete}
        title="Delete User"
        body="Are you sure you want to delete this User?"
      />
    </>
  );
}

export default DeleteUser;
