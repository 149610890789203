import { CSmartTable } from "@coreui/react-pro";
import "../../../Style.scss";

import CIcon from "@coreui/icons-react";
import { cilPencil } from "@coreui/icons";
import { CBadge } from "@coreui/react";
import SmartCategoryTablePagination from "../pagination/SmartCategoryTablePagination.jsx";

function SmartCategoryTable({
  // accountStatus,
  // businessStartedDirection,
  columns,
  currentPage,
  data,
  // id,
  itemsPerPage,
  resultCount,
  searchString,
  setBusinessStartedDirection,
  setCurrentPage,
  setQueryLimit,
  tableClassName,
  tableHover,
  tableResponsive,
  updateQuery,
  // showToast,
  compactView,
  setModalData,
}) {
  SmartCategoryTable.defaultProps = {
    columns: { Loading: {} },
    data: { Loading: {} },
    tableClassName: "sTable",
    tableHover: true,
    tableResponsive: true,
    itemsPerPage: 25,
  };

  function getBusinessBadgeItems(item) {
    return (
      <td
        className={`badge-list ${compactView ? "oneLine" : "multiLine"}`}
        style={{
          maxWidth: `${250}px`,
        }}
      >
        {item.businesses.map((business, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CBadge key={index} color="primary">
            {business.businessName}
          </CBadge>
        ))}
      </td>
    );
  }
  function getActionsButton(item) {
    return (
      <td>
        <CIcon
          icon={cilPencil}
          onClick={() => {
            setModalData(() => ({
              isVisible: true,
              tagName: item.name !== null && item.name !== "" ? item.name : "",
              status:
                item.status !== null && item.status !== ""
                  ? item.status
                  : "Active",
              type:
                item.type !== null && item.type !== "" ? item.type : "Position",
              descriptors:
                item.descriptors !== null && item.descriptors !== ""
                  ? item.descriptors
                  : "",
              id: item.id,
              businesses: item.businesses,
            }));
          }}
        />
      </td>
    );
  }

  function getBusinessBadges() {
    return (
      <>
        <CSmartTable
          style={{
            lineHeight: "1.5",
            paddingTop: "0.25rem",
            paddingBottom: "0.25rem",
          }}
          footer
          className={tableClassName}
          items={data}
          columns={columns}
          itemsPerPage={itemsPerPage}
          columnSorter={{ external: true }}
          itemsPerPageSelect
          itemsPerPageOptions={[
            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
            32, 33, 34, 35, 36, 37, 38, 39, 40, 50, 100,
          ]}
          onItemsPerPageChange={(item) => {
            setQueryLimit(item);
            updateQuery(searchString, true);
          }}
          scopedColumns={{
            actions: (item) => getActionsButton(item),
            businesses: (item) => getBusinessBadgeItems(item),
          }}
          tableProps={{
            hover: tableHover,
            responsive: tableResponsive,
            small: true,
          }}
          onSorterChange={(change) => {
            const columnState = change.state === "asc" ? "asc" : "des";

            switch (change.column) {
              case "name": {
                if (columnState === "asc")
                  setBusinessStartedDirection("nameAZ");
                else {
                  setBusinessStartedDirection("nameZA");
                }
                break;
              }
              case "updatedAt": {
                if (columnState === "asc")
                  setBusinessStartedDirection("timeAZ");
                else {
                  setBusinessStartedDirection("timeZA");
                }
                break;
              }
              default: {
                setBusinessStartedDirection("nameAZ");
              }
            }
          }}
        />
        <SmartCategoryTablePagination
          pageCount={Math.ceil(resultCount / itemsPerPage)}
          updateQuery={updateQuery}
          searchString={searchString}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
        />
      </>
    );
  }

  return getBusinessBadges();
}

export default SmartCategoryTable;
