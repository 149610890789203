import { apiURL, getConfig } from "./ConfigService.js";

//  https://api.pitch59.com/api/user-referrals/user-referral-list?limit=20&offset=0&status=&searchText=&searchTextReferral=&searchTextReferrers=&sortColumn=&sortType=

// create a get request to get the user referral list

export function userReferralList(
  searchText = "",
  searchTextReferral = "",
  searchTextReferrers = "",
  status = "",
  limit = 20,
  offset = 0,
  sortColumn = "",
  sortType = ""
) {
  return fetch(
    `${apiURL}user-referrals/user-referral-list?limit=${limit}&offset=${offset}&status=${status}&searchText=${searchText}&searchTextReferral=${searchTextReferral}&searchTextReferrers=${searchTextReferrers}&sortColumn=${sortColumn}&sortType=${sortType}`,
    getConfig
  )
    .then((response) => response.json())
    .then((res) => res.data);
}
