import { CCol, CFormCheck, CFormInput, CRow } from "@coreui/react";
import Multiselect from "multiselect-react-dropdown";

function UserFilters({
  Search,
  hiddenColumns,
  refreshBillableTester,
  searchString,
  setCurrentPage,
  setRefreshBillableTester,
  sethiddenColumns,
  updateQuery,
  compactView,
  setCompactView,
}) {
  const columnState = {
    options: [
      { name: "email", id: 2 },
      { name: "contactNumber", id: 3 },
      { name: "referralCount", id: 4 },
      { name: "lastOtp", id: 5 },
      { name: "userRoles", id: 6 },
      { name: "businessesCount", id: 7 },
      { name: "platform", id: 8 },
      { name: "teamsCount", id: 9 },
    ],
  };

  const BillableState = {
    options: [
      { name: "All Users", id: 0 },
      { name: "Billable and Tester", id: 1 },
      { name: "Billable Only", id: 2 },
      { name: "Tester Only", id: 3 },
      { name: "None", id: 4 },
    ],
  };

  const selectedV = () => {
    if (refreshBillableTester[0] === false) {
      return 0;
    }
    if (refreshBillableTester[1] && refreshBillableTester[2]) {
      return 1;
    }
    if (refreshBillableTester[1] && refreshBillableTester[2] === false) {
      return 2;
    }
    if (
      refreshBillableTester[1] === false &&
      refreshBillableTester[2] === true
    ) {
      return 3;
    }
    if (
      refreshBillableTester[1] === false &&
      refreshBillableTester[2] === false
    ) {
      return 4;
    }
    return 0;
  };

  function onSelectColumnChange(selectedList, selectedItem) {
    const data = selectedItem.name;
    sethiddenColumns((state) => ({
      ...state,
      [data]: " Visible",
    }));
  }

  function onRemoveColumnChange(selectedList, removedItem) {
    const data = removedItem.name;
    sethiddenColumns((state) => ({
      ...state,
      [data]: " Hidden",
    }));
  }

  function onSelectBillableTesterChange(selectedList, selectedItem) {
    setCurrentPage(1);
    switch (selectedItem.id) {
      case 0:
        setRefreshBillableTester([false, true, true]);
        break;
      case 1:
        setRefreshBillableTester([true, true, true]);
        break;
      case 2:
        setRefreshBillableTester([true, true, false]);
        break;
      case 3:
        setRefreshBillableTester([true, false, true]);
        break;
      case 4:
        setRefreshBillableTester([true, false, false]);
        break;
      default:
        setRefreshBillableTester([false, true, true]);
    }
    updateQuery(searchString, true);
  }

  function getColumns() {
    return Object.keys(hiddenColumns).filter(
      (key) => hiddenColumns[key] === " Visible"
    );
  }

  return (
    <CRow className="align-items-start">
      <CCol>
        <CFormInput
          delay={Search}
          type="search"
          placeholder="Search"
          onInput={(event) => {
            updateQuery(event.target.value);
          }}
        />
      </CCol>
      <CCol>
        <Multiselect
          showCheckbox
          hideSelectedList
          options={columnState.options}
          selectedValues={columnState.options.filter((value) =>
            getColumns().includes(value.name)
          )}
          onSelect={onSelectColumnChange}
          onRemove={onRemoveColumnChange}
          displayValue="name"
          showArrow
          placeholder="Columns"
          className="MultiSelectColumns"
        />
      </CCol>

      <CCol>
        <Multiselect
          singleSelect
          options={BillableState.options}
          selectedValues={[BillableState.options[selectedV()]]}
          onSelect={onSelectBillableTesterChange}
          selectionLimit={1}
          displayValue="name"
          showArrow
          placeholder="Billable Tester Filter"
          className="MultiSelectStatus"
        />
      </CCol>
      <CCol
        style={{
          // center items horizontally
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
        }}
      >
        <CFormCheck
          tooltipFeedback
          type="checkbox"
          defaultChecked={compactView}
          onChange={() => {
            setCompactView(!compactView);
          }}
        />
        <div
          style={{
            marginLeft: "7.5px",
          }}
        >
          Compact View
        </div>
      </CCol>
    </CRow>
  );
}

export default UserFilters;
