import { CButton } from "@coreui/react";

function DiscountClose({ resetForm }) {
  return (
    <CButton
      className="discount-close-btn"
      color="danger"
      onClick={() => {
        resetForm();
      }}
    >
      Close
    </CButton>
  );
}

export default DiscountClose;
