import { CBadge } from "@coreui/react";

const navLink = (name, icon, badge) => (
  <>
    {icon && icon}
    {name && name}
    {badge && (
      <CBadge color={badge.color} className="ms-auto">
        {badge.text}
      </CBadge>
    )}
  </>
);

export default navLink;
